import profile_json from "@/mockups/profile/profile.json";
import payments_json from "@/mockups/profile/payments.json";
import transaction_json from "@/mockups/profile/transactions.json";
import services_json from "@/mockups/profile/services.json";
import support_json from "@/mockups/profile/support.json";
//import axios from '@/services/axios'
import api from "@/services/api";

export const profilestore = {
  namespaced: true,
  state: {
    allAccounts: [],
    personal_info: {},
    email_verified: null,
    isImpersonate: false,
    impersonateUser: null,
    userType: 'admin',
    password_info: {},
    user_payments_methods: {},
    user_subscriptions: null,
    user_transactions: {},
    add_payment: {},
    profile_services: {},
    profile_supports: {},
    globalNotify: {
      message: null,
      type: null,
    },
    loading: {
      id: null,
      value: false,
    },
  },

  mutations: {
    ["addToProfileInfo"]: (state, profile_info) =>
      (state.personal_info = profile_info),
    ["addToAccountsList"]: (state, account) => state.allAccounts.push(account),
    ["updatePersonalInfo"]: (state, account) =>
      (state.personal_info.personal_info = account),
    ["updatePersonalInfoEmail"]: (state, value) =>
      (state.email_verified = value),
    ["updateCompanyInfo"]: (state, account) =>
      (state.personal_info.company_info = account),
    ["updatePasswordInfo"]: (state, pwd) => (state.password_info = pwd),
    ["getUserPaymentMethods"]: (state, payments) =>
      (state.user_payments_methods = payments),
    ["getUserSubscriptions"]: (state, subscriptions) =>
      (state.user_subscriptions = subscriptions),
    ["getUserTransactions"]: (state, transactions) =>
      (state.user_transactions = transactions),
    ["addUserPaymentMethod"]: (state, addpayment) =>
      state.user_payments_methods.push(addpayment),
    ["addProfileServices"]: (state, profileServices) =>
      (state.profile_services = profileServices),
    ["getProfileSupportInfo"]: (state, profileSupport) =>
      (state.profile_supports = profileSupport),
    ["handleGlobalNotify"]: (state, notify) => (state.globalNotify = notify),
    ["setImpersonate"]: (state, value) => (state.isImpersonate = value),
    ["setImpersonateUser"]: (state, value) => (state.impersonateUser = value),
    ["setUserLoginType"]: (state, value) => (state.userType = value),
    ["deletePayment"]: (state, id) => {
      const index = state.user_payments_methods.find(
        (method) => method.id == id
      );
      state.user_payments_methods.splice(index, 1);
    },
    ["makeSecondary"]: (state, id) => {
      state.user_payments_methods.forEach((method, index) => {
        if (method.id != id) {
          state.user_payments_methods[index].default = false;
        }
      });
    },

    ["updatePaymentMethod"]: (state, data) => {
      const index = state.user_payments_methods.findIndex(
        (method) => method.id == data.id
      );
      state.user_payments_methods[index] = data;
    },

    ["set_loading"](state, loading = {}) {
      const id = "id" in loading ? loading.id : "payment";
      const value = "value" in loading ? loading.value : true;
      state.loading.value = value;
      state.loading.id = id;
    },
  },
  actions: {
    getProfileInfo: async ({ commit }, data) => {
      try {
        // commit('addToProfileInfo', profile_json);
        // const response = await axios.get('/profile/profile_info', { params: { accountId:data.accountId } });
        //const response = await api.get("/api/profile/profile_info");
        const response = await api.get("/admin/profile");

        if (response.status === 200) {
          if (response.data.company_info) {
            commit("updateCompanyInfo", response.data.company_info);
          } else {
            const companyInfo = {
              company_name: "",
              vat_number: "",
              company_address: "",
              company_country: "",
              company_manager: "",
              logo_path: "",
            };
            commit("updateCompanyInfo", companyInfo);
          }

          commit("updatePersonalInfo", response.data.user_info);
          commit(
            "updatePersonalInfoEmail",
            response.data.user_info.email_verified_at
          );
        }
      } catch (error) {
        throw error;
      }
    },

    saveAccountProfile: async ({ commit }, data) => {
      try {
        const response = await api.put("/admin/profile", data.params);
        if (response && response.status === 200) {
          if (!data.accountId)
            commit("addToAccountsList", response.data.content);
          else commit("updateAccount", response.data.content);
        }
        commit("updatePersonalInfo", data["params"]);
      } catch (error) {
        commit("handleGlobalNotify", {
          message: error.response.data.message,
          type: "danger",
        });
        throw error;
      }
    },

    storeSaveCompanyInfo: async ({ commit }, data) => {
      try {
        const response = await api.put("/admin/profile/company", data);
        if (response && response.data && response.data.success) {
          if (!data.accountId)
            commit("addToAccountsList", response.data.content);
          else commit("updateAccount", response.data.content);
        }
        commit("updateCompanyInfo", response.data);
      } catch (error) {
        commit("handleGlobalNotify", {
          message: error.response.data.message,
          type: "danger",
        });
        throw error;
      }
    },

    storeSavePasswordInfo: async ({ commit }, data) => {
      try {
        const response = await api.put("/admin/profile/password", data);
        if (response.data.message && response.data.type)
          commit("handleGlobalNotify", response.data);
      } catch (e) {}
    },

    getPaymentsMethods: async ({ commit }) => {
      try {
        const { data } = await api.get("/admin/profile/payment-methods");
        if (data) {
          commit("getUserPaymentMethods", data);
        }
      } catch (e) {}
    },

    getProfileSubscriptions: async ({ commit }) => {
      try {
        const { data } = await api.get("/admin/profile/subscriptions");
        if (data) commit("getUserPaymentMethods", data);
      } catch (e) {}
    },

    unsuscribeSubscriptions: async ({ commit }, id) => {
      try {
        const { data } = await api.delete(`/admin/profile/subscriptions/${id}`);
        // if (data) commit("getUserPaymentMethods", data);
      } catch (e) {}
    },

    getUserTransactions: async ({ commit }, data) => {
      try {
        const response = await api.get("/admin/profile/transactions");
        if (response && response.data && response.data.length) {
          commit("getUserTransactions", response.data);
        }
        // commit('getUserTransactions', transaction_json);
      } catch (e) {}
    },

    addPaymentsMethod: async ({ commit }, data) => {
      try {
        commit("set_loading");
        const response = await api.post(
          "api/profile/payment-methods",
          data.params
        );
        if (response && response.data && response.data.success) {
          commit("getUserTransactions", response.data);
        }
        commit("addUserPaymentMethod", response.data);
        commit("set_loading", { value: false });
      } catch (e) {
        commit("set_loading", { value: false });
      }
    },

    updatePaymentsMethod: async ({ commit }, data) => {
      try {
        commit("set_loading");
        commit("makeSecondary", data.params.id);
        const response = await api.put(
          "/admin/profile/payment-methods/" + data.params.id,
          data.params
        );
        if (response?.data && response.status == 200) {
          commit("getUserTransactions", response.data);
          commit("updatePaymentMethod", response.data);
        }
        commit("set_loading", { value: false });
      } catch (e) {
        commit("set_loading", { value: false });
      }
    },

    deletePaymentsMethod: async ({ commit }, data) => {
      try {
        const response = await api.delete(
          `/admin/profile/payment-methods/${data.id}`,
          {
            params: {
              id: data.id,
            },
          }
        );
        commit("deletePayment", data.id);
        if (response && response.data && response.data.success) {
          commit("getUserTransactions", response.data);
        }
      } catch (e) {}
    },

    getProfileServices: async ({ commit }, data) => {
      try {
        //const response = await Axios.get('/profile/getProfileServices', data);
        //   if (response && response.data && response.data.success) {
        //   commit('getUserTransactions', response.data);
        // }
        commit("addProfileServices", services_json);
      } catch (error) {
        throw error;
      }
    },

    storeServicesData: async ({}, data) => {
      try {
        //const response = await Axios.post('/profile/storeServiceData', data);
        //   if (response && response.data && response.data.success) {
        //   commit('getUserTransactions', response.data);
        // }
      } catch (error) {
        throw error;
      }
    },

    getProfileSupport: async ({ commit }, data) => {
      try {
        // const response = await axios.get('/profile/getProfileSupport', data);
        //   if (response && response.data && response.data.success) {
        //   commit('getUserTransactions', response.data);
        // }
        commit("getProfileSupportInfo", support_json);
      } catch (error) {
        throw error;
      }
    },

    storeProfileSupport: async ({ commit }, data) => {
      try {
        const response = await api.post(
          "/admin/profile/storeProfileSupport",
          data
        );
        if (response && response.data && response.data.success) {
          commit("getUserTransactions", response.data);
        }
      } catch (error) {
        throw error;
      }
    },
  },
};
