import Vuex from "vuex";
import api from "@/services/api";

import { widget } from "../modules/widget/store/widget";
import { xadmin } from "../modules/widget/store/xadmin";
import { stats } from "../modules/widget/store/stats";
import { tariffs } from "../modules/widget/store/tariffs";
import { markers } from "../modules/widget/store/markers";
import { profilestore } from "../modules/profile/store/profilestore";
import { virtualnum } from "../modules/virtualnum/store/virtualnum";

import {
  GET_TIMEZONES,
  SAVE_TIMEZONES,
  GET_COUNTRIES,
  SAVE_COUNTRIES,
  FETCH_SUBSCRIPTION_PLANS,
  SAVE_SUBSCRIPTION_PLANS,
  FETCH_SUBSCRIPTION_CATEGORIES,
  SAVE_SUBSCRIPTION_CATEGORIES,
} from "./types";

export default Vuex.createStore({
  modules: {
    stats,
    markers,
    widget,
    virtualnum,
    profilestore,
    tariffs,
    xadmin,
  },

  state: {
    countries: [],
    timezones: [],
    subscriptionPlans: [],
    subscriptionCategories: [],
  },

  getters: {
    timezones: (state) => state.timezones,
    countries: (state) => state.countries,
    timezoneOptions: (state) =>
      state.timezones.map((item) => ({ value: item, name: item })),
    subscriptionPlans: (state) => state.subscriptionPlans,
    subscriptionCategories: (state) => state.subscriptionCategories,
  },

  mutations: {
    [SAVE_TIMEZONES](state, timezones) {
      const timezonesArray = ["Select timezone"].concat(timezones);
      state.timezones = timezonesArray;
    },
    [SAVE_COUNTRIES](state, countries) {
      state.countries = countries;
    },
    [SAVE_SUBSCRIPTION_PLANS](state, plans) {
      state.subscriptionPlans = plans;
    },
    [SAVE_SUBSCRIPTION_CATEGORIES](state, categories) {
      state.subscriptionCategories = categories;
    },
  },

  actions: {
    async [GET_TIMEZONES]({ state, commit }) {
      try {
        if (state.timezones.length) return;
        const { data } = await api.get("/admin/timezones");
        commit(SAVE_TIMEZONES, data);
      } catch (e) {}
    },

    async [GET_COUNTRIES]({ state, commit }) {
      try {
        if (state.timezones.length) return;
        const { data } = await api.get("/admin/countries");
        commit(SAVE_COUNTRIES, data);
      } catch (e) {}
    },

    async [FETCH_SUBSCRIPTION_PLANS]({ state, commit }) {
      try {
        if (state.subscriptionPlans.length) return;
        const { data } = await api.get("/admin/subscription-plans");
        commit(SAVE_SUBSCRIPTION_PLANS, data);
      } catch (e) {}
    },
  },
});
