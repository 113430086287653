/* eslint-disable */
const routes = [
  {
    path: '/',
    redirect: '/widget'
  },
  {
    path: '/widget',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */'@/modules/widget/pages/Home'),
    meta: {
      requiresAuth: true,
      verified: true
    }
  },
  {
    path: '/widget/settings',
    name: 'contacts',
    component: () => import(/* webpackChunkName: "Contacts" */'@/modules/widget/pages/Contacts/Index'),
    meta: {
      requiresAuth: true,
      verified: true
    }
  },
  {
    path: '/widget/settings/contacts/office/:id',
    name: 'contact-departments',
    component: () => import(/* webpackChunkName: "Departments" */'@/modules/widget/pages/Contacts/Departments'),
    props: true,
    meta: {
      requiresAuth: true,
      // verified: true
    }
  },
  {
    path: '/widget/sidebar',
    name: 'sidebar',
    component: () => import(/* webpackChunkName: "Sidebar" */'@/modules/widget/pages/Sidebar'),
    meta: {
      requiresAuth: true,
      verified: true
    }
  },
  {
    path: '/widget/appearance',
    name: 'appearance',
    component: () => import(/* webpackChunkName: "appearance" */'@/modules/widget/pages/Appearance'),
    meta: {
      requiresAuth: true,
      verified: true
    }
  },
  {
    path: '/widget/lang',
    name: 'lang',
    component: () => import(/* webpackChunkName: "lang" */'@/modules/widget/pages/Lang'),
    meta: {
      requiresAuth: true,
      verified: true
    }
  },
  {
    path: '/widget/installation',
    name: 'installation',
    component: () => import(/* webpackChunkName: "installation" */'@/modules/widget/pages/Installation'),
    meta: {
      requiresAuth: true,
      verified: true
    }
  },
  {
    path: '/widget/security',
    name: 'security',
    component: () => import(/* webpackChunkName: "Security" */'@/modules/widget/pages/Security/Security'),
    meta: {
      requiresAuth: true,
      verified: true
    }
  },
  {
    path: '/widget/security-country',
    name: 'security-country',
    component: () => import(/* webpackChunkName: "Security" */'@/modules/widget/pages/Security/SecurityCountryNew'),
    meta: {
      requiresAuth: true,
      verified: true
    }
  },
  // {
  //   path: '/widget/security-country-old',
  //   name: 'security-country-old',
  //   component: () => import(/* webpackChunkName: "Security" */'@/modules/widget/pages/Security/SecurityCountry'),
  //   meta: {
  //     requiresAuth: true,
  //     verified: true
  //   }
  // },
  // {
  //   path: '/widget/security-blacklist-old',
  //   name: 'security-blacklist-old',
  //   component: () => import(/* webpackChunkName: "Security" */'@/modules/widget/pages/Security/SecurityBlacklist'),
  //   meta: {
  //     requiresAuth: true,
  //     verified: true
  //   }
  // },
  {
    path: '/widget/security-blacklist',
    name: 'security-blacklist',
    component: () => import(/* webpackChunkName: "Security" */'@/modules/widget/pages/Security/SecurityBlacklistNew'),
    meta: {
      requiresAuth: true,
      verified: true
    }
  },
  {
    path: '/widget/tariffs',
    name: 'tariffs',
    component: () => import(/* webpackChunkName: "PricesAndTariffs" */'@/modules/widget/pages/PricesAndTariffs'),
    meta: {
      requiresAuth: true,
      verified: true
    },
  },
  {
    path: '/widget/tariffs/:id',
    name: 'tariffs-type',
    props: true,
    component: () => import(/* webpackChunkName: "TariffSingle" */'@/modules/widget/pages/TariffsTable'),
    meta: {
      requiresAuth: true,
      verified: true
    },
  },
  {
    path: '/widget/reports',
    name: 'reports',
    component: () => import (/* webpackChunkName: "reports" */ '@/modules/widget/pages/Reports/Index'),
    meta: {
      requiresAuth: true,
      verified: true
    }
  },
  {
    path: '/widget/reports/stats',
    name: 'report-stats',
    component: () => import (/* webpackChunkName: "stats" */ '@/modules/widget/pages/Reports/Stats'),
    meta: {
      requiresAuth: true,
      verified: true
    },
  },
  {
    path: '/widget/reports/stats',
    name: 'report-stats2',
  component: () => import (/* webpackChunkName: "stats" */ '@/modules/widget/pages/Reports/StatsRoutes'),
    children: [
      {
        path: 'calls',
        name: 'calls',
        component: () => import (/* webpackChunkName: "stats" */ '@/modules/widget/pages/Reports/stat-reports/Calls.vue'),
      },
      {
        path: 'call-request',
        name: 'callRequests',
        component: () => import (/* webpackChunkName: "stats" */ '@/modules/widget/pages/Reports/stat-reports/CallRequest.vue'),
      },
      {
        path: 'video-calls',
        name: 'videoCalls',
        component: () => import (/* webpackChunkName: "stats" */ '@/modules/widget/pages/Reports/stat-reports/VideoCalls.vue'),
      },
      {
        path: 'emails',
        name: 'emails',
        component: () => import (/* webpackChunkName: "stats" */ '@/modules/widget/pages/Reports/stat-reports/Email.vue'),
      },
      {
        path: 'messages',
        name: 'messages',
        component: () => import (/* webpackChunkName: "stats" */ '@/modules/widget/pages/Reports/stat-reports/Messages.vue'),
      },
    ],
    meta: {
      requiresAuth: true,
      verified: true
    },
  },
  {
    path: '/widget/reports/location',
    name: 'report-location',
    component: () => import (/* webpackChunkName: "location" */ '@/modules/widget/pages/Reports/Location'),
    meta: {
      requiresAuth: true,
      verified: true
    },
  }
]

export default routes
