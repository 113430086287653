<template>
    <span 
        v-if="!show"
    >
        {{ slice(content, 15) }} 
        <a 
            href="#" 
            @click.prevent="show=true"
        >
            {{ $t('link.app.common.readmore') }}
        </a>
    </span>
    <span v-else>
        {{ content }} 
        <a 
            href="#" 
            @click.prevent="show=false"
        >
            {{ $t('link.app.common.readless') }}
        </a>
    </span>
</template>

<script setup>

import { ref } from 'vue'
import useHelpers from '@/composables/useHelpers'

defineProps({
    content: {
        required: true,
        type: String
    }
})

const show = ref(false)

const { slice } = useHelpers()

</script>

<style scoped>
a {
    color: default;
    font-size: 10px;
}
</style>
