import store from "@/store";
import {
  GET_TIMEZONES,
  GET_COUNTRIES,
  FETCH_SUBSCRIPTION_PLANS,
  FETCH_SUBSCRIPTION_CATEGORIES,
} from "@/store/types";
import { v4 as uuidv4 } from "uuid";

export default function useHelpers() {
  const generateId = (length) => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    let result = "";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const slice = (string = "", length = 28, limit = 4) => {
    if (string == "" || string == null || string == undefined) return string;
    return string.length > length ? string.slice(0, length) + "..." : string;
  };

  const generateUUID = () => uuidv4();

  const generateSlug = (string) => {
    if (string == "" || string == null || string == undefined) return;
    return string.toString().toLowerCase().trim().replace(/ /g, "-");
  };

  const getTimezones = async () => {
    await store.dispatch(GET_TIMEZONES);
  };

  const getCountries = async () => {
    await store.dispatch(GET_COUNTRIES);
  };

  const getSubscriptionPlans = async () => {
    await store.dispatch(FETCH_SUBSCRIPTION_PLANS);
    return Promise.resolve();
  };

  const formattedPrice = (value) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "EUR",
    });
    return formatter.format(value);
  };

  return {
    slice,
    generateUUID,
    generateId,
    generateSlug,
    getCountries,
    getTimezones,
    formattedPrice,
    getSubscriptionPlans,
  };
}
