<template>

    <modal @toggle="toggleModal('manageService')" :open="isOpenModal('manageService')"> 
        
        <template #header>Manage Service</template>
        <template #content>
            <div class="w-full"><img class="py-5 pl-2 mx-auto" :src="service_info.icon" alt=""></div>
            <div class="form-group text-sm my-3">
                <label for="name">
                    <span class="font-bold text-lg">Status:</span>
                </label><br>
                <label for="name" class="text-sm text-gray-600">Subscription expires on {{ service_payment_info.service_status_date }}</label>
            </div>

            <div class="form-group text-md my-3">
                <label for="paymentplan">
                    <span class="text-lg font-bold">Select Payment Plan</span>
                </label>
                <div class="form-control">
                    <select name="paymentplan" placeholder="Payment Plan" class="block border-1 border-b-half border-light-green w-full focus:outline-none placeholder-gray-500 dark:bg-gray-800 dark:text-off-white py-1 dark:placeholder-gray-100" v-model="service_paymentplan">
                         <option v-for="(option, index) in service_payment_info.payment_plan" :value="option" :key="index">
                            {{ option }}
                        </option>
                    </select>    
                </div>
            </div>

            <div class="form-group text-md my-3">
                <label for="paymentmethod">
                   <span class="text-lg font-bold"> Apply Payment Method </span>
                </label>
                <div class="form-control">
                    <select name="paymentmethod" placeholder="Payment Method" class="block border-1 border-b-half border-light-green w-full focus:outline-none placeholder-gray-500 dark:bg-gray-800 dark:text-off-white py-1 dark:placeholder-gray-100" v-model="service_paymentmethod">
                        <option v-for="(option, index) in payment_methods.payment_methods" :value="option" :key="index">
                            {{ option.type }}{{ option.card_number }}
                        </option>
                    </select>    
                </div>
            </div>
            <div class="form-group text-md my-3">
                <input type="checkbox" id="autoRenew" name="autoRenew" value="autoRenew" class="bg-green-400" style="background:green;">
                <label for="autoRenew" class="text-md"> Auto Renew</label><br>
            </div>

        </template>
        <template #footer> 
            <button type="button" 
            class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-800 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm dark:bg-gray-800 dark:text-off-white dark:hover:bg-gray-900" 
            @click.prevent="toggleModal">
                Cancel
            </button>

            <button type="button" @click="applyChanges()"
            class="w-full inline-flex justify-center border border-transparent shadow-sm px-4 py-2 bg-dark-green text-base font-medium text-white dark:bg-gray-800 dark:text-off-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm dark:hover:bg-gray-900 dark:border-gray-800">
               &#10003; Apply Changes
            </button>
        </template>

    </modal>

</template>

<script>
/* eslint-disable */

import modal from '@/shared/modal'
import useModal from '@/composables/useModal'
import { reactive } from "vue";
import useProfileConfig from '../../composables/useProfileConfig'
import {useStore} from "vuex";

export default {
    props: {
        service_info: {
            required: true,
            type: Object
        },
        service_payment_info:{
            required: true,
            type: Object
        }
    },
    components: {
        modal
    },

    setup() {
        const store = useStore();
        const { toggleModal, isOpenModal } = useModal()
        const { config: { paymentplan } } = useProfileConfig()
        const { config: { paymentmethod } } = useProfileConfig()
        const storeServicesData = (params) => {
            store.dispatch('profilestore/storeServicesData', { params});
        };

        const getPaymentsMethods = () => {
            store.dispatch('profilestore/getPaymentsMethods', { accountId: 1 });
        };
        getPaymentsMethods();
        const payment_methods = reactive(store.state.profilestore.user_payments_methods);

        return {
            toggleModal,
            isOpenModal,
            paymentplan,
            paymentmethod,
            payment_methods,
            storeServicesData
        }
    },
    data() {
        return {
          service_paymentplan:'',
          service_paymentmethod:''
        };
  },
    methods:{
    applyChanges() {
          const params = {
          service_type: this.service_payment_info.service_type,
          service_status_date: this.service_payment_info.service_status_date,
          set_payment_plan : this.service_paymentplan,
          set_payment_method: this.service_paymentmethod,
        };
        this.storeServicesData(params);
      }   
    } 

}
</script>

<style scoped>

</style>