<template>
  <div class="container relative">
    <button class="export-button" ref="excludeRef" @click="isOpen = !isOpen">
      {{ $t("xpbx.button.export") }}
      <span>
        <i class="ml-2 fa-solid fa-chevron-up" v-if="isOpen"></i>
        <i class="ml-2 fa-solid fa-chevron-down" v-else></i>
      </span>
    </button>

    <div
      class="flex flex-col gap-2 absolute right-0 bg-white -bottom-20 z-20 p-3 rounded-lg shadow-lg"
      v-if="isOpen"
      ref="componentRef"
    >
      <p class="text-gray-500 hover:text-gray-600">
        <vue-excel-xlsx
          :data="tableData"
          :columns="columns"
          :file-name="'filename'"
          :file-type="'xlsx'"
          :sheet-name="'sheetname'"
        >
          {{ $t("xpbx.button.export_xlsx") }}
        </vue-excel-xlsx>
      </p>
      <p
        @click="exportCSV($event)"
        class="cursor-pointer text-gray-500 hover:text-gray-600"
      >
        {{ $t("xpbx.button.export_csv") }}
      </p>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import Button from "primevue/button";
import useClickOutside from "@/composables/useClickOutside";

export default {
  name: "Export",

  components: {
    Button,
  },

  props: {
    dt: {
      required: true,
    },
    tableData: {
      type: Array,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
  },

  setup(props) {
    const componentRef = ref();
    const excludeRef = ref();
    const isOpen = ref(false);

    const exportCSV = (event) => {
      props.dt.exportCSV();
    };

    useClickOutside(
      componentRef,
      () => {
        if (isOpen.value === true) {
          isOpen.value = !isOpen.value;
        }
      },
      excludeRef
    );

    return {
      isOpen,
      excludeRef,
      componentRef,
      exportCSV,
    };
  },
};
</script>

<style lang="scss" scoped>
.export-button {
  background: rgba(#f7955b, 0.8);
  border: 1px solid #f7955b;
  padding: 0.5rem 1rem;
  color: #fff;
  border-radius: 6px;

  &:hover {
    background: #f7955b;
    border: 1px solid #f7955b;
  }
}
</style>