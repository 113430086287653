<template>
  <div class="app-container font-nunito h-screen relative">
    <template v-if="show">
      <GlobalLoader v-if="loading" />
      <routerView />
      <Toaster />
    </template>

    <template v-else>
      <div class="app-loader bg-white p-3 shadow-xl">
        <img src="@/assets/loader.svg" alt="Loading..." />
      </div>
    </template>
  </div>
</template>

<script setup>
import { onMounted, ref, nextTick, computed, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import useThemeToggler from "@/composables/useThemeToggler";
import Toaster from "@/shared/Toaster";
import GlobalLoader from "@/shared/GlobalLoader";
import "@/services/axiosInstance";
import useHelpers from "@/composables/useHelpers";

const show = ref(false);
const route = useRoute();
const store = useStore();
const { getTimezones, getCountries } = useHelpers();


onMounted(() => {
  nextTick(() => {
    show.value = true;
  });
});

onMounted(async () => {
  await getCountries();
  await getTimezones();
});

const loading = computed(() => {
  const { value } = store.getters["stats/loading"];
  return value;
});

watch(route, () => {
  const widgets = document.querySelectorAll(".widget-button.ripple");
  if (widgets && widgets.length && widgets.length > 1) {
    widgets[1].style.display = "none";
  }
});
</script>

<style lang="scss">
.app-loader {
  width: 110px;
  height: 100px;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.checkbox {
  &__container {
    position: relative;
    padding-left: 30px;
    margin-bottom: 15px;
    cursor: pointer;
    font-size: inherit;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &--block {
      display: block;
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      width: 0;
      height: 0;
    }

    .checkmark {
      position: absolute;
      top: 3px;
      left: 0;
      height: 18px;
      width: 18px;
      border: 1px solid #ccc;
      margin-top: 0.4px;
      /* The checkmark/indicator (hidden when not checked) */
      &::after {
        content: "";
        position: absolute;
        opacity: 0;
        left: 6px;
        top: 2px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        transition: opacity 0.5s;
      }
    }
  }
}
//background change on checkbox hover effect
.checkbox__container:hover input ~ .checkmark {
  background: #f0f0f0;
}

.checkbox__container:hover input ~ .checkmark-dark {
  background: rgba(31, 41, 55, 1);
}

.checkbox__container.checkbox__inactive {
  opacity: 0.4;
}

//add a green background when the checkbox is checked

.checkbox__container input:checked ~ .checkmark {
  background: #1ab394;
  border-color: #1ab394;
}

.checkbox__container input:checked ~ .checkmark--green {
  background: rgb(7, 119, 67);
  border-color: rgb(7, 119, 67);
}

//show checkmark when checkbox is checked
.checkbox__container input:checked ~ .checkmark::after {
  opacity: 1;
}

// input radio button

input[type="radio"] {
  display: none;
}

input[type="radio"] + label {
  position: relative;
  padding-left: 2rem;
  font-size: 1rem;
  padding-top: 0.2rem;
  line-height: 1.5;
  cursor: pointer;
  color: #333;
}

input[type="radio"].dark + label {
  color: rgba(255, 255, 255, 0.87) !important;
}

.radio-button {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 50%;
  display: inline-block;
  background: #fff;
  border: 1px solid #1ab394;
}

.radio-button-dark {
  background: #1e1e1e;
}

.radio-button::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
  background: #1ab394;
  opacity: 0;
  transition: opacity 0.5s;
}

.radio-button--green::after {
  background: rgb(7, 119, 67);
}

input[type="radio"]:checked + label .radio-button::after {
  opacity: 1;
}

input[type="radio"]:checked + label .radio-button {
  border: 2px solid #1ab394;
}

input[type="radio"]:checked + label .radio-button--green {
  border: 2px solid rgb(7, 119, 67);
}
</style>
