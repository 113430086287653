
import useVirtualConfig from "../../virtualnum/composables/useVirtualConfig";
import i18nSetup from '@/services/i18nSetup'
const { i18n } = i18nSetup()
const translate = i18n.global.t

const { triggerAlert } = useVirtualConfig()
import {
    SET_COUNTRIES,
    SET_COUTNRIES_DATA
} from "./types";

export const virtualnum = {
    namespaced: true,
    state: {
        coverage: {
            countries: []
        }
    },
    getters: {
        getCountries: state => state.countries
    },
    mutations:{
        [SET_COUNTRIES](state, data) {
            state.countries = data
        },
    },
    actions:{
        async [SET_COUTNRIES_DATA]({ commit }, data) {
            try {
                commit(SET_COUNTRIES, data)
            }
            catch(e) {
                triggerAlert(e, translate('virtual_num.main.coverage.countries'))
            }
        },
    }
}
