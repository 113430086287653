<template>
  <div class="p-8 bg-white">
    <span class="loader"></span>
  </div>
</template>

<script>
export default {
  name: "XPBXLoader",
};
</script>

<style scoped>
.loader {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 8px solid;
  border-color: rgba(0, 0, 0, 0.75) rgba(0, 0, 0, 0.55) rgba(0, 0, 0, 0.35)
    rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>