<template>
    <dialog 
        v-if="open" 
        :class="{ 'hidden': !open }" 
        @click.self="closeDialog" 
        :id="id" 
        v-click-away="closeDialog"
    >
        <slot>
            {{ translate('widgetInfo', 'Widget info') }}
        </slot>
    </dialog>
</template>

<script setup>

    import { inject } from 'vue'

    const props = defineProps({
        open: {
            type: Boolean,
            default: false
        },
        id: {
            type: [String, Number],
            required: true
        }
    })

    const emit = defineEmits(['close'])

    const translate = inject('translatedValue')

    const closeDialog = () => {
        emit('close', props.id)
    }

</script>

<style scoped lang="scss">

    dialog {
        width: 100%;
        height: 200px;
        border: 0;
        background: #fff;
        position: absolute;
        left: 0;
        top: 40%;
        z-index: 1;
        box-shadow: 0 1px 1px rgba(0, 0, 0, .4);
        transform: translateY(-50%);
        padding: 1rem 2.5rem;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        transition: all .6s, opacity .1s, width .1s, height .2s;

    }

    dialog.hidden {
        width: 0;
        height: 0;
        opacity: 0;
        visibility: hidden;
    }

</style>