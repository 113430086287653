import { ref, inject } from "vue";
import rootApi from "@/services/rootApi";
import userApi from "@/services/userApi";

export default function useExtensions() {
  const t = inject("t");
  const extension = ref({
    id: "",
    extension: "",
    is_active: 1,
    password: "",
    domain: "",
  });
  const extensions = ref([]);
  const freeExtensions = ref([]);
  const freeAgentExtensions = ref([]);
  const notify = inject("notify");
  const n = (label, type = "danger") => {
    notify({
      message: t(`${label}`),
      type,
    });
  };

  const getAPI = () => {
    const token = localStorage.getItem("token");
    return token ? rootApi : userApi;
  };

  //   FIND EXTENSIONS
  const findExtensions = async () => {
    const api = getAPI();
    try {
      const response = await api.get("/admin/xpbx/extension");

      if (response?.data) extensions.value = response.data;
    } catch (error) {}
  };

  //   FIND EXTENSION
  const findExtension = async (id) => {
    const api = getAPI();
    try {
      const response = await api.get(`/admin/xpbx/extension/${id}`);

      if (response?.data?.length) {
        extension.value = response.data[0];
      }
    } catch (error) {}
  };

  //   FIND FREE EXTENSION
  const findFreeExtension = async () => {
    const api = getAPI();
    try {
      const response = await api.get(`/admin/xpbx/free/extension`);

      if (response?.data?.length) {
        freeExtensions.value = response.data;
      }
    } catch (error) {}
  };

  //   FIND AGENT FREE EXTENSION
  const findAgentFreeExtensions = async (id) => {
    const api = getAPI();
    try {
      const response = await api.get(`/admin/xpbx/free/extension/${id}`);

      if (response?.data?.length) {
        freeAgentExtensions.value = response.data;
      }
    } catch (error) {}
  };

  //   CREATE EXTENSION
  const createExtension = async (data) => {
    const api = getAPI();
    try {
      const response = await api.post(`/admin/xpbx/extension`, data);

      if (response?.data?.id) {
        extensions.value.push(response.data);
        n("notify.xpbx.create_extension", "success");
      } else {
        n("notify.xpbx.error_create_extension");
      }
    } catch (error) {
      n("notify.xpbx.error_create_extension");
      return error;
    }
  };

  //   UPDATE EXTENSION
  const updateExtension = async (data, id) => {
    const api = getAPI();
    const formData = {
      extension: data.extension || null,
      is_active: data.is_active || "1",
      password: data.password || "",
      description: data.description || "",
      status: data.status || "1",
    };

    try {
      const response = await api.put(`/admin/xpbx/extension/${id}`, formData);

      if (response?.data?.id) {
        const index = extensions.value.findIndex((i) => i.id === id);
        extensions.value[index] = response.data;
        n("notify.xpbx.edit_extension", "success");
      } else {
        n("notify.xpbx.error_edit_extension");
      }
    } catch (error) {
      n("notify.xpbx.error_edit_extension");
    }
  };

  //   DELETE EXTENSION
  const deleteExtension = async (id) => {
    const api = getAPI();
    try {
      const response = await api.delete(`/admin/xpbx/extension/${id}`);

      if (response.status === 200) {
        n("notify.xpbx.delete_extension", "success");

        extensions.value = extensions.value.filter((i) => i.id !== id);
      } else {
        n("notify.xpbx.error_delete_extension");
      }
      return response;
    } catch (error) {
      n("notify.xpbx.error_delete_extension");
      return error;
    }
  };

  return {
    extension,
    extensions,
    freeExtensions,
    freeAgentExtensions,
    findExtension,
    findExtensions,
    updateExtension,
    createExtension,
    deleteExtension,
    findFreeExtension,
    findAgentFreeExtensions,
  };
}
