<template>
  <div class="bg-white w-full">
    <section class="flex flex-row flex-wrap w-full">
      <div v-if="isTransaction == 0" class="w-full">
        <div class="flex flex-col justify-center items-center mb-10 mx-auto">
          <div class="mb-1 font-bold text-3xl">
            <p>
              {{ $t("label.profile.profile-payment.top.payment-method.title") }}
            </p>
          </div>
          <div class="mb-1 text-lg">
            <p>
              {{ $t("label.profile.profile-payment.top.payment-method.desc") }}
            </p>
          </div>
        </div>
      </div>
      <div v-else class="w-full">
        <div class="flex flex-col justify-center items-center mb-10 mx-auto">
          <div class="mb-1 font-bold text-3xl">
            <p>
              {{ $t("label.profile.profile-payment.top.transactions.title") }}
            </p>
          </div>
          <div class="mb-1 text-lg">
            <p>
              {{ $t("label.profile.profile-payment.top.transactions.desc") }}
            </p>
          </div>
        </div>
      </div>
      <div v-if="isTransaction == 0" class="w-full">
        <div
          class="flex flex-row-reverse flex-wrap items-center mr-5 cursor-pointer"
          @click="isTransaction = 1"
        >
          <img
            class="py-2 pl-2"
            src="@/assets/images/profile_section/profile-payment-transactions.svg"
            alt=""
          />
          <div>
            <p class="text-lg">
              {{
                $t(
                  "link.profile.profile-payment.body.payment-method.link-title"
                )
              }}
              is link
            </p>
          </div>
        </div>

        <div
          v-if="payment_methods.length != 0"
          class="grid grid-cols-1 gap-4 justify-items-center payment-card"
        >
          <div class="" v-for="(method, id) in payment_methods" :key="id">
            <div
              class="my-3 mx-8 pb-3 rounded-lg border-b-1 border-r-3 border-gray-500 shadow-lg"
              style="width: 475.63px; height: 272px"
              v-bind:style="panelSetBG(method.default)"
            >
              <div
                class="flex flex-row flex-wrap px-3 py-2 items-center justify-between"
              >
                <div class="">
                  <img
                    class="w-64p rounded-lg bg-white mt-3 ml-6"
                    src="@/assets/images/profile_section/profile-payment-pnl-badget.svg"
                    alt=""
                  />
                </div>
                <div class="">
                  <img
                    class="py-4 mt-6 ml-4 mr-4"
                    style="width: 70px; height: 90px"
                    :src="all_payment_methods[method.card_type?.toLowerCase()]"
                    :alt="method.card_type"
                  />
                </div>
              </div>
              <div class="flex flex-row flex-wrap w-full py-1">
                <p
                  class="w-full px-10 text-xl text-center"
                  style="text-align-last: justify"
                >
                  **** **** **** {{ method.last_four }}
                </p>
              </div>
              <div class="flex flex-row flex-wrap w-full py-1">
                <p
                  v-if="method.default !== true"
                  class="w-full px-10 text-2x text-justify"
                >
                  {{ method.card_owner }}
                </p>
                <p v-else class="w-full px-10 text-2x text-justify text-white">
                  {{ method.card_owner }}
                </p>
              </div>

              <p
                class="w-full text-sm text-left pl-10"
                v-if="method.default !== true"
              >
                expires &nbsp; {{ method.card_month }}/{{ method.card_year }}
              </p>

              <p class="w-full text-sm text-left pl-10 text-white" v-else>
                expires &nbsp; {{ method.card_month }}/{{ method.card_year }}
              </p>

              <div
                class="flex flex-row flex-wrap"
                v-if="method.default !== true"
              >
                <div class="w-1/2 flex flex-row items-start p-3 cursor-pointer">
                  <p
                    class="w-full px-6 text-red-600 font-bold text-lg text-justify hover:text-purple-300"
                    @click.prevent="primaryPaymentMethod(method)"
                  >
                    {{
                      $t(
                        "button.profile.profile-payment.body.payment-card.make-primary"
                      )
                    }}
                  </p>
                </div>
                <div
                  class="flex flex-row-reverse items-end flex-wrap w-1/2 p-3"
                >
                  <div>
                    <p
                      class="w-full px-6 text-red-600 font-bold text-lg text-justify hover:text-purple-300 cursor-pointer"
                      @click.prevent="deletePaymentMethod(method)"
                    >
                      {{ $t("button.label.delete") }}
                    </p>
                  </div>
                  <div>
                    <p
                      class="w-full px-6 text-lg font-bold text-black text-justify hover:text-purple-300 cursor-pointer"
                      @click.prevent="editPaymentMethod(method)"
                    >
                      {{ $t("button.label.edit") }}
                    </p>
                  </div>
                </div>
              </div>

              <div
                v-else
                class="flex flex-row-reverse items-end flex-wrap w-full p-3"
              >
                <div>
                  <p
                    class="w-full px-6 text-lg font-bold text-black text-justify hover:text-purple-300 cursor-pointer"
                    @click.prevent="editPaymentMethod(method)"
                  >
                    {{ $t("button.label.edit") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="flex flex-row-reverse flex-wrap items-center mr-5 w-full cursor-pointer"
          @click.prevent="addPaymentMethod()"
        >
          <img
            class="py-2 pl-2"
            src="@/assets/images/profile_section/profile-payment-add-payment.svg"
            alt=""
          />
          <div>
            <p class="text-lg">
              {{
                $t(
                  "link.profile.profile-payment.body.add-payment-method.transactions"
                )
              }}
            </p>
          </div>
        </div>
      </div>

      <div v-else class="w-full">
        <div class="flex flex-row-reverse flex-wrap items-center mr-5">
          <!-- <div
            @click="isTransaction = 0"
            class="flex flex-row-reverse cursor-pointer items-center"
          >
            <img
              class="py-2 pl-2 w-37p mb-3"
              src="@/assets/images/profile_section/profile_back.svg"
              alt=""
            />
            <div>
              <p class="text-lg mb-3 ml-2">
                {{
                  $t(
                    "link.profile.profile-payment.body.transactions.link-title"
                  )
                }}
              </p>
            </div>
          </div> -->
          <div class="w-full mx-auto">
            <table
              class="w-full border-2 collapse border-solid m-0 b-0 table-fixed"
            >
              <thead class="">
                <tr
                  class="text-app-purple text-lg border-b-2 border-purple-400 border-solid"
                >
                  <th scope="col" class="py-3">
                    {{
                      $t(
                        "label.profile.profile-payment.body.transactions.title-date"
                      )
                    }}
                  </th>
                  <th scope="col font-bold">
                    {{
                      $t(
                        "label.profile.profile-payment.body.transactions.title-payment-method"
                      )
                    }}
                  </th>
                  <th scope="col">
                    {{
                      $t(
                        "label.profile.profile-payment.body.transactions.title-amount"
                      )
                    }}
                  </th>
                  <th scope="col font-bold">
                    {{
                      $t(
                        "label.profile.profile-payment.body.transactions.title-receipt"
                      )
                    }}
                  </th>
                  <th scope="col">
                    {{
                      $t(
                        "label.profile.profile-payment.body.transactions.title-invoice"
                      )
                    }}
                  </th>
                  <th scope="col">
                    {{
                      $t(
                        "label.profile.profile-payment.body.transactions.title-result"
                      )
                    }}
                  </th>
                </tr>
              </thead>
              <tbody v-if="profile_transaction && profile_transaction.length">
                <tr
                  class="border-b-2 border-dotted"
                  v-for="(item, index) in profile_transaction"
                  :key="index"
                >
                  <td data-label="Date">
                    {{ item.created_at }}
                  </td>
                  <td data-label="Payment Method">
                    {{ item.card_type }}
                  </td>
                  <td data-label="Amount">
                    {{ item.amount }}
                  </td>
                  <td data-label="Receipt">
                    <a
                      :href="item.receipt_url"
                      v-if="item.receipt_url"
                      class="flex justify-center"
                      target="_blank"
                    >
                      <img
                        src="@/assets/images/profile_section/profile-payment-transation-invoice.svg"
                        alt="Xlinx Logo group"
                        class="mr-2 image3 align-baseline mt-2 w-32p cursor-pointer"
                      />
                    </a>
                  </td>
                  <td data-label="Invoice" class="flex justify-center">
                    <a
                      :href="item.invoice_link"
                      v-if="item.invoice_link"
                      target="_blank"
                    >
                      <img
                        src="@/assets/images/profile_section/profile-payment-transation-invoice.svg"
                        alt="Xlinx Logo group"
                        class="mr-2 image3 align-baseline mt-2 w-32p cursor-pointer"
                      />
                    </a>
                  </td>
                  <td data-label="Result">
                    {{ item.message }}
                  </td>
                </tr>
              </tbody>
              <tbody v-else class="w-full">
                <tr>
                  <p class="text-center py-4 w-full">There is no any data</p>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
    <add-payment-modal
      :isEditRequest="is_edit_request"
      :methodDetail="method_detail"
    />
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { useStore } from "vuex";
import { computed, ref, watch } from "vue";
import useActiveToggler from "@/composables/useActiveToggler";
import useModal from "@/composables/useModal";
import useAuth from "@/composables/auth/authenticate";
import useToaster from "@/composables/useToaster";
import addPayment from "../components/modals/add-payment";

export default {
  name: "ProfilePayment",

  components: {
    addPaymentModal: addPayment,
  },

  setup() {
    const store = useStore();

    const { toggleConfig, isActiveConfig } = useActiveToggler();
    const { toggleModal } = useModal();
    const isTransaction = ref(1);

    const { getAuth: auth } = useAuth();
    const { trigger } = useToaster();

    const assetPath = computed(() => {
      if (process.env.ASSET_URL != undefined + "/") {
        return process.env.ASSET_URL;
      } else {
        return "/";
      }
    });

    const getDate = (date) => {
      const newDate = date ? new Date(date) : new Date();
      const transformedDate = `${newDate.getFullYear()}-${newDate.getMonth()}-${newDate.getDate()} ${newDate.getHours()}:${newDate.getMinutes()}`;

      return transformedDate;
    };

    const panelSetBG = () => {};
    const getPaymentsMethods = () => {
      store.dispatch("profilestore/getPaymentsMethods", { accountId: 1 });
    };
    const getUserTransactions = () => {
      store.dispatch("profilestore/getUserTransactions", { accountId: 1 });
    };

    getPaymentsMethods();
    getUserTransactions();

    const payment_methods = computed(() => {
      return store.state.profilestore.user_payments_methods;
    });

    const profile_transaction = computed(() => {
      return store.state.profilestore.user_transactions;
    });

    const deletePaymentsMethod = (id) => {
      return store.dispatch("profilestore/deletePaymentsMethod", id);
    };

    const updatePaymentsMethod = (params) => {
      return store.dispatch("profilestore/updatePaymentsMethod", { params });
    };

    return {
      auth,
      trigger,
      assetPath,
      toggleConfig,
      isActiveConfig,
      toggleModal,
      getDate,
      panelSetBG,
      isTransaction,
      payment_methods,
      profile_transaction,
      getPaymentsMethods,
      deletePaymentsMethod,
      updatePaymentsMethod,
    };
  },

  data() {
    return {
      all_payment_methods: {
        paypal: require("@/assets/images/profile_section/Method=PayPal.svg"),
        payoneer: require("@/assets/images/profile_section/Method=Payoneer.svg"),
        mastercard: require("@/assets/images/profile_section/Method=Mastercard.svg"),
        visa: require("@/assets/images/profile_section/Method=Visa.svg"),
        discover: require("@/assets/images/profile_section/Method=Discover.svg"),
        american_express: require("@/assets/images/profile_section/Method=american_express.png"),
      },
      profile_photo_url:
        "https://corpviews-s3-prod.s3.us-east-2.amazonaws.com/profile-photos/prjsuMPFS2qJRPQ3q2kwxxKRSYTw3LEgBt7T5HuN.jpg",
      is_edit_request: false,
      method_detail: {},
    };
  },
  methods: {
    panelSetBG(value) {
      if (value === true) {
        return {
          backgroundImage: `url(${require("@/assets/images/profile_section/profile-payment-pnlone.svg")})`,
          backgroundSize: `cover`,
          color: `white`,
        };
      } else {
        return {
          backgroundImage: `url(${require("@/assets/images/profile_section/profile-payment-pnltwo.svg")})`,
          backgroundSize: `cover`,
          color: `black`,
        };
      }
    },
    editPaymentMethod(method) {
      this.toggleModal("addPayment", true);
      this.is_edit_request = true;
      this.method_detail = method;
      this.method_detail["card_number"] = "************" + method["last_four"];
      this.method_detail["card_cvc_code"] = "***";
    },
    addPaymentMethod() {
      this.toggleModal("addPayment", true);
      this.is_edit_request = false;
      this.method_detail = {};
    },

    async deletePaymentMethod(id) {
      const result = await Swal.fire({
        title: this.$t("notify.modules.components.common.prompt_delete"),
        text:
          this.$t(
            "notify.modules.components.payment.prompt_delete_confirmation"
          ) + "!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1AB394",
        cancelButtonColor: "#d33",
        confirmButtonText:
          this.$t("notify.modules.components.common.delete_confirmation") + "!",
      });

      if (result.isConfirmed) {
        await this.deletePaymentsMethod(id);
        this.trigger({
          message: this.$t("notify.profile_information.payment_deleted"),
          type: "success",
        });
      }
    },
    primaryPaymentMethod(method) {
      method["default"] = true;
      method["card_number"] = "************" + method["last_four"];
      this.updatePaymentsMethod(method);
    },
  },
};
</script>

<style scoped></style>
