// import api from "@/services/api";

import {
  SET_XADMIN_HEADER,
  SET_XADMIN_QUEUE,
  ADD_XADMIN_QUEUE,
  ADD_XADMIN_QUEUE_OPTION,
  UPDATE_XADMIN_QUEUE_OPTION,
  DELETE_XADMIN_QUEUE_OPTION,
} from "./types";

export const xadmin = {
  namespaced: true,

  state: {
    header: [],
    queue: {},
    loading: false,
  },

  getters: {
    header: (state) => state.header,
  },

  mutations: {
    [SET_XADMIN_HEADER](state, data) {
      state.header = data;
    },

    [SET_XADMIN_QUEUE](state, data) {
      console.log("SET_XADMIN_QUEUE", data);
      state.queue = data;
    },

    [UPDATE_XADMIN_QUEUE_OPTION](state, data) {
      const option = state.queue[data.key];

      if (data.key === "queue-general") {
        state.queue[data.key] = data.value;
      } else {
        const index = option.findIndex((item) => item.id === data.value.id);

        if (index !== -1) {
          option[index] = data.value;

          state.queue[data.key] = option;
        }
      }
    },

    [ADD_XADMIN_QUEUE](state, data) {
      console.log("ADD_XADMIN_QUEUE", data);
      state.queue = { ...state.queue, ...data };
    },

    [ADD_XADMIN_QUEUE_OPTION](state, data) {
      console.log("ADD_XADMIN_QUEUE_OPTION", data);
      console.log("state.queue", state.queue[data.key].length);
      state.queue[data.key] = [...state.queue[data.key], data.value];

      console.log("state.queue", state.queue[data.key].length);
    },

    [DELETE_XADMIN_QUEUE_OPTION](state, data) {
      const option = state.queue[data.key];
      console.log("DELETE_XADMIN_QUEUE_OPTION option", option?.length);

      const newOptions = option.filter((item) => item.id !== data.value);

      console.log("newOptions", newOptions.length);

      state.queue[data.key] = newOptions;
    },
  },

  actions: {},
};
