<template>

    <transition-group tag="div" 
    class="alert-wrap"
    name="list"
    appear
    :style="alert.open ? 'margin-bottom: 1rem' : 'margin-bottom: 0'"
    >
        <div class="alert" :class="alert.type" v-if="alert.open">
            <font-awesome-icon :icon="icon" class="fa-fw" />
            <span class="mx-3">{{ alert.message }}</span>
            <span class="mx-3" v-if="alert.action">
                <a href="#" @click.prevent="resendEmail">click here</a>
            </span>
            <font-awesome-icon 
                icon="times" 
                class="absolute right-1 top-1/2 hover:opacity-70 cursor-pointer"
                style="transform: translate(-50%, -50%)"
                @click="closeAlert"
            />
        </div>
    </transition-group>

</template>

<script>
    /* eslint-disable */

    import { computed } from 'vue'
    import useToaster from '@/composables/useToaster'
    import usePasswords from '@/composables/auth/usePasswords'

    export default {
        
        name: "Alert",

        setup() {
            
            const { alert, closeAlert, triggerAlert } = useToaster()
            const { resendEmailVerification } = usePasswords()

            const icon = computed(() => {
                switch (alert.type) {
                    case 'success':
                        return 'check'
                    case 'danger':
                        return 'exclamation-triangle'
                    case 'light':
                        return 'exclamation-circle'
                    default:
                        return 'exclamation-circle';
                }
            })

            const resendEmail = () => {
                resendEmailVerification()
                triggerAlert({
                    message: 'Email has been resent to your address! Please check'
                })
            }

            return {
                alert, 
                icon,
                closeAlert,
                resendEmail,
            }

        }

    }

</script>

<style scoped lang="scss">
    .alert-wrap {
        position: relative;
        height: auto;
        max-height: 2.75rem;
        width: auto;
        min-width: 22rem;
        z-index: 10003;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 1rem;
    }
    .alert {
        width: 100%;
        padding: .825rem 1.25rem;
        color: white;
        position: relative;
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
        text-align: left;
    }
    .alert.danger {
        @apply bg-red-800;
    }
    .alert.success {
        @apply bg-dark-green;
    }
    .alert.light {
        background: #fff;
        @apply text-light-gray;
    }

    .list-enter-from {
        opacity: 0;
        top: -70%;
    }
    .list-enter-to {
        opacity: 1;
        top: 0;
    }
    .list-enter-active {
        transition: all 0.2s ease-in-out;
    }

    .list-leave-from {
        opacity: 1; 
        top: 0;
    }
    .list-leave-to {
        opacity: 0;
        top: -100%;
    }
    .list-leave-active {
        transition: position 0.2s ease-in-out;
        position: absolute;
        width: 100%;
    }
    .list-move {
        transition: all 0.2s ease-in-out;
    }
</style>