/* eslint-disable */ 

import { reactive } from 'vue'

const config = reactive({

    paymentplan:['1 year $300 usd', '2 year $600 usd'],
    paymentmethod:['VISA ******1234', 'MasterCard ******1235'],


})

export default function useProfileConfig() {
    
    return {
        config
    }

}