<template>
  <modal @toggle="toggleModal('addPayment')" :open="isOpenModal('addPayment')">
    <template v-if="isEditRequest" #header>{{
      $t('label.profile.profile-payment.top.edit-payment-method.title')
    }}</template>
    <template v-else #header>{{
      $t('label.profile.profile-payment.top.add-payment-method.title')
    }}</template>
    <template #content>
      <div class="w-full flex flex-row flex-wrap mb-10">
        <img
          class="payment_card shadow-sm"
          v-for="array in array_payments_methods"
          :key="array.type"
          :src="array.icon"
          alt="Payment methods"
        />
      </div>

      <form
        @input="removeError($event.target.name)"
        @change="removeError($event.target.name)"
      >
        <div class="flex flex-row flex-wrap mb-5">
          <div class="flex flex-col flex-wrap w-2/5 p-1">
            <label for="name" class="text-lg text-gray-900 font-bold">
              {{
                $t(
                  'label.profile.profile-payment.top.add-payment-method.cardnumber'
                )
              }}
            </label>
            <input
              v-model="card_number"
              type="text"
              name="card_number"
              placeholder="*****1236"
              class="border-2 w-full focus:outline-none text-lg"
              :class="[
                errors.card_number
                  ? ['border-red-900 placeholder-red-900']
                  : [
                      'border-light-green placeholder-gray-300 focus:border-green-700'
                    ],
                { 'cursor-not-allowed bg-gray-100': isEditRequest }
              ]"
              maxlength="16"
              :disabled="isEditRequest"
            />

            <label
              for="card_number"
              class="text-xs"
              :class="!!errors.card_number ? 'text-red-900' : 'text-gray-500'"
            >
              {{ !!errors.card_number ? errors.card_number[0] : '' }}
            </label>
          </div>
          <div class="flex flex-col flex-wrap w-1/5 p-1">
            <label for="name" class="text-lg text-gray-900 font-bold">
              Expiry Month
            </label>
            <input
              v-model="card_month"
              type="number"
              name="card_month"
              placeholder="12"
              class="border-2 w-full focus:outline-none text-lg"
              :class="[
                errors.card_month
                  ? ['border-red-900 placeholder-red-900']
                  : [
                      'border-light-green placeholder-gray-300 focus:border-green-700'
                    ]
              ]"
              min="1"
              max="12"
            />
            <label
              for="card_month"
              class="text-xs"
              :class="!!errors.card_month ? 'text-red-900' : 'text-gray-500'"
            >
              {{ !!errors.card_month ? errors.card_month[0] : '' }}
            </label>
          </div>
          <div class="flex flex-col flex-wrap w-1/5 p-1">
            <label for="name" class="text-lg text-gray-900 font-bold"
              >Expiry Year</label
            >
            <input
              v-model="card_year"
              type="number"
              name="card_year"
              placeholder="2022"
              class="border-2 w-full focus:outline-none text-lg"
              :class="[
                errors.card_year
                  ? ['border-red-900 placeholder-red-900']
                  : [
                      'border-light-green placeholder-gray-300 focus:border-green-700'
                    ]
              ]"
              :min="new Date().getFullYear()"
              :max="+new Date().getFullYear() + 15"
            />
            <label
              for="card_year"
              class="text-xs"
              :class="!!errors.card_year ? 'text-red-900' : 'text-gray-500'"
            >
              {{ !!errors.card_year ? errors.card_year[0] : '' }}
            </label>
          </div>
          <div class="flex flex-col flex-wrap w-1/5 p-1">
            <label for="card_cvc_code" class="text-lg text-gray-900 font-bold"
              >CVC</label
            >
            <input
              v-model="card_cvc_code"
              type="text"
              name="card_cvc_code"
              placeholder="****"
              class="border-2 w-full focus:outline-none text-lg"
              :class="[
                errors.card_cvc_code
                  ? ['border-red-900 placeholder-red-900']
                  : [
                      'border-light-green placeholder-gray-300 focus:border-green-700'
                    ],
                { 'cursor-not-allowed bg-gray-100': isEditRequest }
              ]"
              :disabled="isEditRequest"
            />

            <label
              for="card_cvc_code"
              class="text-xs"
              :class="!!errors.card_cvc_code ? 'text-red-900' : 'text-gray-500'"
            >
              {{ !!errors.card_cvc_code ? errors.card_cvc_code[0] : '' }}
            </label>
          </div>
        </div>

        <div class="flex flex-row flex-wrap w-full mb-20">
          <label for="card_owner" class="text-lg text-gray-900 font-bold">{{
            $t('label.profile.profile-payment.top.add-payment-method.cardowner')
          }}</label>
          <input
            v-model="card_owner"
            type="text"
            name="card_owner"
            placeholder="Petar Petrov"
            class="border-2 w-full focus:outline-none text-lg"
            :class="[
              errors.card_owner
                ? ['border-red-900 placeholder-red-900']
                : [
                    'border-light-green placeholder-gray-300 focus:border-green-700'
                  ],
              { 'cursor-not-allowed bg-gray-100': isEditRequest }
            ]"
            :disabled="isEditRequest"
          />
          <label
            for="card_owner"
            class="text-xs"
            :class="!!errors.card_owner ? 'text-red-900' : 'text-gray-500'"
          >
            {{ !!errors.card_owner ? errors.card_owner[0] : '' }}
          </label>
        </div>
      </form>
    </template>
    <template #footer>
      <button
        type="button"
        class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-800 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm dark:bg-gray-800 dark:text-off-white dark:hover:bg-gray-900"
        @click.prevent="close()"
      >
        {{ $t('button.label.cancel') }}
      </button>

      <button
        v-if="isEditRequest"
        type="button"
        @click="updatePaymentMethod()"
        class="w-full inline-flex justify-center items-center gap-1 border border-transparent shadow-sm px-4 py-2 bg-dark-green text-base font-medium text-white dark:bg-gray-800 dark:text-off-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm dark:hover:bg-gray-900 dark:border-gray-800"
      >
        &#10003; {{ $t('button.label.update-card') }}
        <font-awesome-icon
          icon="circle-notch"
          class="fa-spin"
          v-show="loading"
        />
      </button>
      <button
        v-else
        type="button"
        @click="addPaymentMethod()"
        class="w-full inline-flex justify-center items-center gap-1 border border-transparent shadow-sm px-4 py-2 bg-dark-green text-base font-medium text-white dark:bg-gray-800 dark:text-off-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm dark:hover:bg-gray-900 dark:border-gray-800"
      >
        <span>&#10003; {{ $t('button.label.apply-card') }}</span>
        <font-awesome-icon
          icon="circle-notch"
          class="fa-spin"
          v-show="loading"
        />
      </button>
    </template>
  </modal>
</template>

<script>
import { computed, inject } from 'vue'
import modal from '@/shared/modal'
import useModal from '@/composables/useModal'
import { useStore } from 'vuex'
import validator from '@/composables/auth/validator'
import {
  paymentValidations,
  paymentUpdateValidations
} from '@/composables/auth/paymentValidations'
import useWidgetConfig from '@/modules/widget/composables/useWidgetConfig'
import useToaster from '@/composables/useToaster'
import { isEmpty } from 'lodash'

export default {
  components: {
    modal
  },
  props: {
    isEditRequest: {
      required: true,
      type: Boolean
    },
    methodDetail: {
      required: true,
      type: Object
    }
  },
  setup () {
    const store = useStore()
    const { toggleModal, isOpenModal } = useModal()
    const { errors, removeError, clearErrors } = validator()
    const { fixErrorsAlert } = useWidgetConfig()
    const { trigger } = useToaster()
    const t = inject('t')

    const loading = computed(() => {
      const { id, value } = store.state.profilestore.loading
      return id == 'payment' && value
    })

    const addPaymentsMethod = async params => {
      if (!paymentValidations(params)) {
        fixErrorsAlert()
        return
      }
      await store.dispatch('profilestore/addPaymentsMethod', { params })
      if (isEmpty(errors.value)) {
        toggleModal(false)
        trigger({
          message: t('notify.profile_information.payment_saved'),
          type: 'success'
        })
      } else fixErrorsAlert()
    }

    const updatePaymentsMethod = async params => {
      if (!paymentUpdateValidations(params)) {
        fixErrorsAlert()
        return
      }
      await store.dispatch('profilestore/updatePaymentsMethod', { params })
      if (isEmpty(errors.value)) {
        toggleModal(false)
        trigger({
          message: t('notify.profile_information.payment_saved'),
          type: 'success'
        })
      } else fixErrorsAlert()
    }

    return {
      errors,
      loading,
      removeError,
      clearErrors,
      toggleModal,
      isOpenModal,
      addPaymentsMethod,
      updatePaymentsMethod
    }
  },

  data () {
    return {
      array_payments_methods: [
        {
          type: 'paypal',
          label: 'PayPal',
          icon: require('@/assets/images/profile_section/Method=PayPal.svg')
        },
        {
          type: 'payoneer',
          label: 'Payoneer',
          icon: require('@/assets/images/profile_section/Method=Payoneer.svg')
        },
        {
          type: 'mastercard',
          label: 'MasterCard',
          icon: require('@/assets/images/profile_section/Method=Mastercard.svg')
        },
        {
          type: 'visa',
          label: 'Visa',
          icon: require('@/assets/images/profile_section/Method=Visa.svg')
        },
        {
          type: 'discover',
          label: 'Discover',
          icon: require('@/assets/images/profile_section/Method=Discover.svg')
        }
      ],
      card_id: '',
      card_type: '',
      card_cvc_code: '',
      card_month: '',
      card_year: '',
      card_owner: '',
      card_number: ''
    }
  },

  watch: {
    methodDetail (updatedVal) {
      this.reset()
      if (this.isEditRequest) {
        this.card_id = updatedVal.id
        this.card_number = updatedVal.card_number
        this.card_month = updatedVal.card_month
        this.card_year = updatedVal.card_year
        this.card_cvc_code = updatedVal.card_cvc_code
        this.card_owner = updatedVal.card_owner
        this.card_type = updatedVal.card_type
      }
    }
  },

  methods: {
    addPaymentMethod () {
      const params = {
        id: this.card_id,
        card_number: this.card_number,
        card_month: this.card_month,
        card_year: this.card_year,
        card_cvc_code: this.card_cvc_code,
        card_owner: this.card_owner
      }
      this.addPaymentsMethod(params)
    },
    updatePaymentMethod () {
      const params = {
        id: this.card_id,
        card_number: this.card_number,
        card_month: this.card_month,
        card_year: this.card_year,
        card_cvc_code: this.card_cvc_code,
        card_owner: this.card_owner
      }
      this.updatePaymentsMethod(params)
    },
    setPaymentType (type) {
      this.card_type = type
    },

    close () {
      this.toggleModal(false)
      this.reset()
    },
    reset () {
      this.card_number = ''
      this.card_month = +new Date().getMonth() + 1
      this.card_year = new Date().getFullYear()
      this.card_cvc_code = ''
      this.card_owner = ''
      this.clearErrors()
    }
  },

  mounted () {
    this.card_year = new Date().getFullYear()
    this.card_month = +new Date().getMonth() + 1
  }
}
</script>

<style scoped>
.payment_card.active {
  border: 1px solid black;
  border-radius: 8px;
}
.payment_card.deactive {
  border: 0px;
}
</style>
