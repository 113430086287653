import { reactive } from "vue";

const toaster = reactive({
  message: "",
  type: "",
  open: false,
});

const alert = reactive({
  message: "",
  type: "",
  open: false,
  action: false,
});

export default function useToaster() {
  const trigger = ({ message, type = "light", open = true }) => {
    toaster.message = message;
    toaster.type = type;
    toaster.open = open;
  };

  const triggerAlert = ({
    message,
    type = "light",
    open = true,
    action = false,
  }) => {
    alert.message = message;
    alert.type = type;
    alert.open = open;
    alert.action = action;
  };

  const close = () => {
    toaster.open = false;
    toaster.type = "";
    toaster.message = "";
  };

  const closeAlert = () => {
    alert.open = false;
    alert.type = "";
    alert.message = "";
  };

  return {
    close,
    closeAlert,
    toaster,
    trigger,
    alert,
    triggerAlert,
  };
}
