<template>
  <div class="form-group text-xl my-3 font-inter">
    <label for="timezone" class="font-bold" v-show="showTitle">
      {{ $t('label.widget.modal.common.timezone') }}
      <span class="text-red-700 font-bold">*</span>
    </label>
    <div class="form-control">
      <SelectTimezonesWithSearch
        :options="timezones"
        :modelValue="modelValue"
        @update:modelValue="$emit('update:modelValue', $event)"
        :inputClass="[
          !!errors.timezone
            ? ['border-red-900 placeholder-red-900']
            : ['border-light-green placeholder-gray-500 focus:border-green-700']
        ]"
      />
    </div>
    <label
      for="timezone"
      class="text-xs"
      :class="!!errors.timezone ? 'text-red-900' : 'text-gray-500'"
    >
      {{ !!errors.timezone ? errors.timezone[0] : $t(label) }}
    </label>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { onMounted, computed } from 'vue'
import useHelpers from '@/composables/useHelpers'
import validator from '@/composables/auth/validator'
import SelectTimezonesWithSearch from '@/shared/SelectTimezonesWithSearch'

const { errors } = validator()

const store = useStore()
const { getTimezones } = useHelpers()
const timezones = computed(() => store.getters.timezones)

defineProps({
  modelValue: {
    type: String,
    required: true
  },
  showTitle: {
    required: false,
    default: true,
    type: Boolean
  },
  label: {
    required: false,
    default: 'label.widget.modal.common.office_zone',
    type: String
  }
})

const emit = defineEmits(['update:modelValue'])

onMounted(() => getTimezones())
</script>
