import { FontAwesomeIcon } from "./index";
import { ColorPicker } from "vue-color-kit";

import dropdown from "@/shared/dropdown/index";
import dropdownItem from "@/shared/dropdown/item";

import baseInput from "@/shared/utils/base-input";
import Icon from "@/shared/utils/Icon";
import BaseDialog from "@/modules/widget/components/widget/Layout/BaseDialog";
import Alert from "@/shared/Alert";
import BaseButton from "@/shared/utils/BaseButton";
import FormInput from "@/shared/utils/FormInput";
import FormSelect from "@/shared/utils/FormSelect";
import FormCheck from "@/shared/utils/FormCheck";
import FormCheckbox from "@/shared/utils/FormCheckbox";
import Readmore from "@/shared/utils/Readmore";
import SelectWithSearch from "@/shared/SelectWithSearch";
import SelectKeyValueWithSearch from "@/shared/SelectKeyValueWithSearch";

export default function globalComponents(app) {
  app.component("font-awesome-icon", FontAwesomeIcon);
  app.component("color-kit", ColorPicker);
  app.component("dropdown", dropdown);
  app.component("dropdown-item", dropdownItem);
  app.component("base-input", baseInput);
  app.component("Icon", Icon);
  app.component("BaseDialog", BaseDialog);
  app.component("Alert", Alert);
  app.component("BaseButton", BaseButton);
  app.component("FormInput", FormInput);
  app.component("FormSelect", FormSelect);
  app.component("FormCheck", FormCheck);
  app.component("FormCheckbox", FormCheckbox);
  app.component("Readmore", Readmore);
  app.component("SelectWithSearch", SelectWithSearch);
  app.component("SelectKeyValueWithSearch", SelectKeyValueWithSearch);
}
