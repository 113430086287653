<template>
  <div class="text-sm">
    <p>{{ transformDate(value) }}</p>
    <p>{{ transformDateHour(value) }}</p>
  </div>
</template>

<script>
export default {
  name: "DateItem",

  props: {
    value: {
      default: "",
      required: true,
    },
  },

  setup() {
    const transformDate = (value) => {
      const newDate = new Date(value);
      const jsonDate = newDate.toJSON();
      return new Date(jsonDate).toUTCString().toString().slice(0, 16);

      const dateValue = newDate.toISOString().split("T")[0];
      return `${dateValue}`;
    };

    const transformDateHour = (value) => {
      const newDate = new Date(value);

      let hours = newDate.getHours();
      if (hours < 10) hours = `0${hours}`;

      let minutes = newDate.getMinutes();
      if (minutes < 10) minutes = `0${minutes}`;

      let seconds = newDate.getSeconds();
      if (seconds < 10) seconds = `0${seconds}`;

      return `${hours}:${minutes}:${seconds}`;
    };

    return {
      transformDate,
      transformDateHour,
    };
  },
};
</script>