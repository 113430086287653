<template>
  <div class="bg-white w-full">
    <section class="flex flex-col flex-wrap w-full">
      <div class="flex flex-col justify-center items-center mx-auto">
        <div class="mb-1 font-bold text-3xl">
          <p>{{ $t("label.profile.profile-support.top.support.title") }}</p>
        </div>
        <div class="mb-1 text-lg">
          <p>{{ $t("label.profile.profile-support.top.support.desc") }}</p>
        </div>
      </div>
      <div class="border border-grey-400 my-20">
        <div class="flex lg:flex-row flex-col flex-wrap w-full">
          <div class="flex flex-col flex-wrap w-full lg:w-1/2 p-8">
            <p class="text-md text-left p-3 font-bold">
              {{ $t("label.profile.profile-support.body.support.message") }}
            </p>
            <div class="mx-3">
              <textarea
                v-model="support_message"
                class="w-full border border-green-400 overflow-hidden placeholder-gray-500 placeholder-opacity-75 text-left"
                style="height: 12rem"
              >
              </textarea>
            </div>
          </div>
          <div class="flex flex-col flex-wrap w-full lg:w-1/2 p-8">
            <div class="py-10">
              <div class="form-group pb-5">
                <SelectWithSearch
                  v-model="support_service"
                  :options="support_data.service_type"
                  id="supportService"
                  name="supportService"
                  form="supportService"
                />
                <div class="flex flex-row items-start w-full">
                  <label for="timezone" class="text-xs text-gray-500">{{
                    $t(
                      "label.profile.profile-support.body.support.select-service"
                    )
                  }}</label>
                </div>
              </div>
              <div class="form-group pb-5">
                <SelectWithSearch
                  v-model="support_department"
                  :options="support_data.service_department"
                  id="supportDep"
                  name="supportDep"
                  form="supportDep"
                />
                <div class="flex flex-row items-start w-full">
                  <label for="language" class="text-xs text-gray-500">{{
                    $t(
                      "label.profile.profile-support.body.support.select-department"
                    )
                  }}</label>
                </div>
              </div>
            </div>
            <div class="flex flex-row-reverse items-end flew-wrap mt-32">
              <button
                @click="saveSupportData()"
                class="bg-dark-green text-gray-200 p-1 px-10 text-center transition ease-in duration-100 hover:opacity-90 h-10 rounded-md"
              >
                &nbsp; {{ $t("button.label.send") }} &nbsp;
              </button>
              <button
                class="bg-white text-dark-green border-2 border-dark-green mr-3 p-1 px-10 text-center transition ease-in duration-100 hover:opacity-90 h-10 rounded-md"
              >
                {{ $t("button.label.cancel") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { computed, reactive, inject } from "vue";
import support_json from "@/mockups/profile/support.json";
import SelectWithSearch from "@/shared/SelectWithSearch";
import { useStore } from "vuex";

export default {
  name: "ProfileServices",
  setup() {
    const store = useStore();
    const notify = inject("notify");
    const t = inject("t");
    const assetPath = computed(() => {
      if (process.env.ASSET_URL != undefined + "/") {
        return process.env.ASSET_URL;
      } else {
        return "/";
      }
    });
    const bgImage = computed(() => {
      return {
        backgroundImage: `url(${require("@/assets/images/profile_section/bg-profile.svg")})`,
        backgroundSize: "cover",
      };
    });
    var support_data = support_json;

    const getProfileSupport = (params) => {
      store.dispatch("profilestore/getProfileSupport", { params });
    };
    getProfileSupport();
    const profile_support = reactive(store.state.profilestore.profile_supports);

    const storeSupportData = (params) => {
      store
        .dispatch("profilestore/storeProfileSupport", { params })
        .then(() => {
          notify({
            message: t("notify.profile_support.email_successful"),
            type: "success",
          });
        })
        .catch((err) => {
          notify({
            message: t("notify.profile_support.email_failed"),
            type: "danger",
          });
        });
    };

    return {
      assetPath,
      bgImage,
      support_data,
      profile_support,
      storeSupportData,
    };
  },
  data() {
    return {
      support_service: "",
      support_department: "",
      support_message: "",
    };
  },
  methods: {
    saveSupportData() {
      const params = {
        support_service: this.support_service,
        support_department: this.support_department,
        support_message: this.support_message,
      };
      this.storeSupportData(params);
    },
  },
};
</script>

<style scoped lang="scss"></style>
