<template>
  <div>
    <div class="flex flex-col justify-center items-center mb-10 mx-auto">
      <div class="mb-1 font-bold text-3xl">
        <p>
          {{ $t("label.profile.recharge-account.top.heading") }}
        </p>
      </div>
    </div>
    <div
      class="grid grid-cols-1 3xl:grid-cols-3 gap-4 justify-items-center service-card"
    >
      <div
        class="service-container cursor-pointer"
        v-for="item in payments"
        :key="item.id"
        @click="handlePayment(item.amount)"
      >
        <div style="height: 200px;"
          class="m-5 pb-3 rounded-sm border-b-1 border-r-3 border-gray-500 bg-white shadow-md"
        >
          <div class="w-full">
            <p class="py-3 mb-3 font-bold text-3xl mx-auto">
              <br /><br />
              <p style="color: #F89948; font-size: 60px;">&euro;{{ item.display_amount }}</p>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject, computed } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    const t = inject("t");
    const store = useStore();
    const user_info = computed(() => store.state.profilestore.personal_info);

    const payments = ref([
      {
        id: 1,
        amount: 5000,
        display_amount: 50.00,
        title: t("label.profile.recharge-account.paynment.payment-title"),
        
      },
      {
        id: 2,
        title: t("label.profile.recharge-account.paynment.payment-title"),
        amount: 10000,
        display_amount: 100.00,

      },
      {
        id: 3,
        title: t("label.profile.recharge-account.paynment.payment-title"),
        amount: 25000,
        display_amount: 250.00,

      },
      {
        id: 4,
        title: t("label.profile.recharge-account.paynment.payment-title"),
        amount: 50000,
        display_amount: 500.00,

      },
      {
        id: 5,
        title: t("label.profile.recharge-account.paynment.payment-title"),
        amount: 100000,
        display_amount: 1000.00,

      },
      {
        id: 6,
        title: t("label.profile.recharge-account.paynment.payment-title"),
        amount: 250000,
        display_amount: 2500.00,

      },
    ]);

    const handlePayment = (amount) => {
      setTimeout(() => {
        //window.open(`${process.env.PAYMENT_URL}/${user_info?.value?.personal_info?.id}/${user_info?.value?.personal_info?.email}/${amount}`)
        window.location.href = `${process.env.PAYMENT_URL}/${user_info?.value?.personal_info?.account_id}/${user_info?.value?.personal_info?.email}/${amount}`;
      }, 350);
    };

    return {
      payments,
      handlePayment,
    };
  },
};
</script>

<style></style>
