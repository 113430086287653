import { library } from '@fortawesome/fontawesome-svg-core'
import { 
    faUserSecret, 
    faCog, 
    faEllipsisV,
    faEllipsisH, 
    faBell, 
    faHome, 
    faChartBar, 
    faUser,
    faPhone,
    faPhoneAlt,
    faLanguage,
    faBriefcase,
    faShoppingCart,
    faSearch,
    faPlus,
    faCheck,
    faTimes,
    faClock,
    faAngleRight,
    faAngleDown,
    faAngleUp,
    faArrowAltCircleRight,
    faDollarSign,
    faLongArrowAltRight,
    faPen,
    faUserCircle,
    faCircleNotch,
    faTrash,
    faTrashAlt,
    faInfoCircle,
    faAt,
    faCommentDots,
    faVideo,
    faPaperPlane,
    faExclamationTriangle,
    faExclamationCircle,
    faCartPlus,
    faEnvelope,
    faEnvelopeOpen,
    faQuestionCircle,
    faCaretDown,
    faCaretUp,
    faBox,
    faInbox,
    faAngleDoubleLeft,
    faAngleDoubleRight

} from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faUserSecret, 
    faCog, 
    faEllipsisV,
    faEllipsisH, 
    faBell, 
    faHome, 
    faChartBar, 
    faUser,
    faPhone,
    faPhoneAlt,
    faLanguage,
    faBriefcase,
    faShoppingCart,
    faSearch,
    faPlus,
    faCheck,
    faTimes,
    faClock,
    faAngleRight,
    faAngleDown,
    faAngleUp,
    faArrowAltCircleRight,
    faDollarSign,
    faLongArrowAltRight,
    faPen,
    faUserCircle,
    faCircleNotch,
    faTrash,
    faTrashAlt,
    faInfoCircle,
    faAt,
    faCommentDots,
    faVideo,
    faPaperPlane,
    faExclamationTriangle,
    faExclamationCircle,
    faCartPlus,
    faEnvelope,
    faEnvelopeOpen,
    faQuestionCircle,
    faCaretDown,
    faCaretUp,
    faBox,
    faInbox,
    faAngleDoubleLeft,
    faAngleDoubleRight
)

export default FontAwesomeIcon
