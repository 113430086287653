import axios from "axios";
import useToaster from '@/composables/useToaster'

const { trigger } = useToaster()

axios.interceptors.request.use((config) => {
  config.timeout = 30000; // Wait for 10 seconds before timing out
  return config;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.code === "ECONNABORTED" && error.message.includes("timeout")) {
      trigger({ message:"Request timed out", type: 'danger' })
    }
    return Promise.reject(error);
  }
);
