import BaseProfile from "../pages/BaseProfile";
import ProfileInformation from "../pages/ProfileInformation";
import ProfilePayment from "../pages/ProfilePayment";
import ProfileServices from "../pages/ProfileServices";
import ProfileSubscriptions from "../pages/ProfileSubscriptions";
import ProfileSupport from "../pages/ProfileSupport";
import ProfileDashboard from "../pages/ProfileDashboard";
import RechargeAccount from "../pages/RechargeAccount";
import ProfileUsers from "../pages/ProfileUsers";

import i18nSetup from "@/services/i18nSetup";
const { i18n } = i18nSetup();

const routes = [
  {
    name: "BaseProfile",
    path: "/profile",
    component: BaseProfile,
    meta: {
      requiresAuth: true,
      verified: false,
      lang: i18n.global.locale,
    },
    children: [
      {
        name: "ProfileInformation",
        path: "/profile/profile-info",
        component: ProfileInformation,
        meta: {
          requiresAuth: true,
          verified: false,
          //lang: i18n.global.locale,
        },
      },
      {
        name: "ProfileUsers",
        path: "/profile/users",
        component: ProfileUsers,
        meta: {
          requiresAuth: true,
          verified: false,
          //lang: i18n.global.locale,
        },
      },
      {
        name: "ProfilePayment",
        path: "/profile/profile-payment",
        component: ProfilePayment,
        meta: {
          requiresAuth: true,
          verified: false,
          lang: i18n.global.locale,
        },
      },
      {
        name: "ProfileServices",
        path: "/profile/profile-services",
        component: ProfileServices,
        meta: {
          requiresAuth: true,
          verified: false,
          lang: i18n.global.locale,
        },
      },
      {
        name: "ProfileSubscriptions",
        path: "/profile/profile-subscriptions",
        component: ProfileSubscriptions,
        meta: {
          requiresAuth: true,
          verified: false,
          lang: i18n.global.locale,
        },
      },
      {
        name: "ProfileSupport",
        path: "/profile/profile-support",
        component: ProfileSupport,
        meta: {
          requiresAuth: true,
          verified: false,
          lang: i18n.global.locale,
        },
      },
      {
        name: "RechargeAccount",
        path: "/profile/recharge-account",
        component: RechargeAccount,
        meta: {
          requiresAuth: true,
          verified: false,
          lang: i18n.global.locale,
        },
      },
      {
        name: "ProfileDashboard",
        path: "",
        component: ProfileDashboard,
        meta: {
          requiresAuth: true,
          verified: false,
          lang: i18n.global.locale,
        },
      },
    ],
  },
];

export default routes;
