<template>
  <header
    class="bg-white w-full leading-normal p-3 dark:bg-gray-900 dark:text-off-white main-header"
  >
    <ul class="m-0 p-0 grid grid-cols-2 items-center content-center h-full">
      <div class="justify-self-start flex items-center gap-5">
        <li
          class="text-3xl leading-6"
          style="width: 32px; height: 32px"
          :title="$t('link.app.common.home')"
          v-if="
            !!auth.email_verified_at == true && isprofile && isprofile == true
          "
        >
          <RouterLink :to="{ name: 'Home' }">
            <img
              v-if="
                user_info.personal_info && user_info.personal_info.user_photo
              "
              :src="user_info.personal_info.user_photo"
              alt="User Photo"
              class="object-cover rounded-full"
            />
          </RouterLink>
        </li>
        <li class="text-3xl leading-6 font-inter font-light">
          <ActiveTab />
        </li>

        <li v-if="showXPBXLogo" class="absolute left-1">
          <img
            src="@/assets/images/config-x.svg"
            :alt="$t('link.app.header.alt-text.pbx')"
            class="w-14"
          />
        </li>
      </div>

      <div class="justify-self-end relative text-right w-full">
        <div>
          <li
            class="inline-block align-middle cursor-pointer"
            @click.prevent="slideOverMenu = true"
            :title="$t('text.app.common.menu')"
          >
            <a href="#" class="text-app-purple font-semibold">
              <Icon name="menu" />
            </a>
          </li>
        </div>

        <div
          class="sliding-popover"
          :class="[slideOverMenu ? 'right-0 visible' : '-right-full invisible']"
          v-if="slideOverMenu"
        >
          <li
            class="inline-block align-middle w-6 cursor-pointer relative text-left"
            v-if="!showMenu || isImpersonate"
          >
            <img
              src="@/assets/images/three-dots.svg"
              alt="Config"
              class="w-full"
              @click.prevent="toggleDropdown('configMenu')"
            />

            <dropdown
              classes="w-80"
              v-if="isActiveDropdown('configMenu')"
              id="configMenu"
            >
              <div class="grid grid-cols-3 dropdown-body">
                <dropdown-item
                  style="cursor: pointer"
                  @click.prevent="$router.push({ name: 'Home' })"
                >
                  <img
                    src="@/assets/images/config-d.svg"
                    :alt="$t('link.app.header.alt-text.website-dialer')"
                    class="w-14"
                  />
                </dropdown-item>
                <dropdown-item
                  @click.prevent="$router.push({ name: 'virtual-num' })"
                >
                  <img
                    src="@/assets/images/config-n.svg"
                    :alt="$t('link.app.header.alt-text.virtual-number')"
                    class="w-14"
                  />
                </dropdown-item>
                <dropdown-item @click.prevent="$router.push({ name: 'xpbx' })">
                  <img
                    src="@/assets/images/config-x.svg"
                    :alt="$t('link.app.header.alt-text.pbx')"
                    class="w-14"
                  />
                </dropdown-item>
                <dropdown-item
                  @click.prevent="$router.push({ name: 'xadmin-dashboard' })"
                >
                  <img
                    src="@/assets/images/config-c.svg"
                    :alt="$t('link.app.header.alt-text.contact-center')"
                    class="w-14"
                  />
                </dropdown-item>
                <dropdown-item
                  @click.prevent="$router.push({ name: 'virtual-num' })"
                >
                  <img
                    src="@/assets/images/config-t.svg"
                    :alt="$t('link.app.header.alt-text.call-tracker')"
                    class="w-14"
                  />
                </dropdown-item>
                <dropdown-item
                  @click.prevent="$router.push({ name: 'virtual-num' })"
                >
                  <img
                    src="@/assets/images/config-s.svg"
                    :alt="$t('link.app.header.alt-text.sms')"
                    class="w-14"
                  />
                </dropdown-item>
              </div>
            </dropdown>
          </li>

          <li class="inline-block cursor-pointer relative text-left">
            <div
              class="text-dark-green font-semibold"
              @click.prevent="toggleDropdown('userMenu')"
            >
              <Icon name="user-circle" />
            </div>

            <dropdown
              classes="w-80 flex justify-start"
              id="userMenu"
              v-if="isActiveDropdown('userMenu')"
            >
              <dropdown-item @click.prevent="$router.replace('/profile')">
                <div class="flex justify-between mb-2">
                  <div
                    class="flex flex-col items-start"
                    v-if="userType !== 'user'"
                  >
                    <img
                      v-if="
                        user_info.personal_info &&
                        user_info.personal_info.user_photo
                      "
                      :src="user_info.personal_info.user_photo"
                      alt="User Image"
                      class="w-12 h-14 object-cover rounded-lg justify-self-center"
                    />
                    <img
                      v-else
                      src="@/assets/images/profile_section/user.jpg"
                      alt="User Image"
                      class="w-12 h-14 object-cover rounded-lg justify-self-center"
                    />
                    <div class="font-bold text-sm text-center">
                      {{ auth.name }}
                    </div>
                  </div>
                  <div>
                    <p>
                      {{ $t("link.app.header.account_amount") }}:
                      <span
                        v-if="
                          user_info.personal_info &&
                          user_info.personal_info.balance
                        "
                        >{{ user_info.personal_info.balance }}</span
                      >
                      <span v-else>0</span>
                    </p>
                  </div>
                </div>
                <div>{{ auth.profile_company?.company_name }}</div>
              </dropdown-item>
              <dropdown-item
                v-if="userType !== 'user'"
                :link="'/profile/recharge-account'"
              >
                <span>{{ $t("link.app.header.recharge_account") }}</span>
              </dropdown-item>
              <dropdown-item>
                <span @click.prevent="logUserOut">{{
                  $t("link.app.header.logout")
                }}</span>
              </dropdown-item>
            </dropdown>
          </li>

          <li
            class="inline-block align-middle cursor-pointer"
            @click.prevent="slideOverMenu = false"
            :title="$t('text.app.common.menu')"
          >
            <a href="#" class="text-dark-green font-semibold">
              <Icon name="menu" />
            </a>
          </li>
        </div>
      </div>
    </ul>
  </header>
  <!-- <div style="width: 100%; background-color: black; height: 1px"></div> -->
</template>

<script setup>
import ActiveTab from "./ActiveTab";
import { useRouter } from "vue-router";
import { ref, inject, onMounted, reactive, watch, computed } from "vue";
import useActiveToggler from "@/composables/useActiveToggler";
import useThemeToggler from "@/composables/useThemeToggler";
import useWidgetConfig from "@/modules/widget/composables/useWidgetConfig";
import authenticate from "@/composables/auth/authenticate";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

const props = defineProps({
  isprofile: {
    type: Boolean,
    default: false,
  },

  showXPBXLogo: {
    type: Boolean,
    default: false,
  },
});
const t = inject("t");
const store = useStore();
const route = useRoute();
const router = useRouter();

const slideOverMenu = ref(false);
const showMenu = ref(true);
const triggerAlert = inject("triggerAlert");
const { activateMode } = useThemeToggler();
const { isActiveDropdown, toggleDropdown } = useActiveToggler();
const { logout, getAuth: auth } = authenticate();
const { resetWidgets } = useWidgetConfig();

const user_info = reactive(store.state.profilestore.personal_info);
const userType = computed(() => store.state.profilestore.userType);
const isImpersonate = computed(() => store.state.profilestore.isImpersonate);

const logUserOut = async () => {
  await logout();
  await resetWidgets();
  router.push({ name: "login" });
};

const activateModeAndToggle = (title) => {
  activateMode(title.toLowerCase());
  toggleDropdown("themeMenu");
};

const resendEmailPrompt = () => {
  if (!auth.value.email_verified_at || auth.value.email_verified_at == null) {
    triggerAlert({
      message: t("notify.main.header.logout.notify"),
      action: true,
    });
  }
};

const chekLocalStorage = () => {
  const rootUser = localStorage.getItem("token");
  const adminUser = localStorage.getItem("adminUser");
  const userToken = localStorage.getItem("userToken");
  store.commit("profilestore/setImpersonate", userToken ? true : false);

  if (rootUser) {
    store.commit("profilestore/setUserLoginType", "root");
  } else if (userToken) {
    store.commit("profilestore/setUserLoginType", "user");
  } else {
    store.commit("profilestore/setUserLoginType", null);
  }

  if (adminUser && adminUser === "true") {
    showMenu.value = false;
  } else {
    showMenu.value = true;
  }
};

watch(
  route,
  (to) => {
    chekLocalStorage();
  },
  { flush: "pre", immediate: true, deep: true }
);

watch(isImpersonate, (val) => {
  console.log("Impersonate value changed", val);
});

onMounted(() => {
  chekLocalStorage();
  resendEmailPrompt();
});
</script>

<style lang="scss" scoped>
.main-header {
  border-bottom: 1px solid black;
  box-sizing: border-box;
  max-height: 70px;
  height: 70px;
}
</style>
