<template>
  <div class="w-full">
    <div class="flex ml-5 items-center mx-auto">
      <div
        class="mb-1 font-bold text-3xl"
        :class="{ 'heading-button': showButton }"
      >
        <p>
          {{ heading }}
        </p>

        <div
          class="subscribe-btn"
          v-if="showButton"
          @click="$emit('buttonClick')"
        >
          {{ buttonTitle }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageHeader",
  props: {
    heading: {
      type: String,
      default: "",
      required: true,
    },
    showButton: {
      type: Boolean,
      default: false,
      required: false,
    },
    buttonTitle: {
      type: String,
      default: "",
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.subscribe-btn {
  font-weight: 600;
  font-size: 1.1rem;
  padding: 5px 12px;
  background-color: rgb(26, 179, 148);
  display: inline-block;
  color: #fff;
  border-radius: 5px;
  margin: 5px 0;
  min-width: 120px;
  text-transform: lowercase;
  cursor: pointer;
}

.heading-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
</style>