import store from "@/store";
import { throttle } from "lodash";
import api from "@/services/api";
import i18nSetup from "@/services/i18nSetup";
import useToaster from "@/composables/useToaster";
import useHelpers from "@/composables/useHelpers";
import { reactive, ref, unref, computed } from "vue";

import {
  GET_WIDGETS,
  SAVE_CONTACT,
  SAVE_CONTACTS,
  EDIT_CONTACT,
  DELETE_CONTACT,
  SAVE_DEPARTMENT,
  SET_ACTIVE_RESOURCE,
  RESET_RESOURCE,
  EDIT_DEPARTMENT,
  DELETE_DEPARTMENT,
  SET_ACTIVE_WIDGET,
  SET_LANG,
  SAVE_USER_LANGUAGE,
  SAVE_USER_EDITED_LANGS,
  DELETE_LANG,
  SAVE_DEFAULT_LANG,
  SAVE_SIDEBAR,
  SAVE_APPEARANCE,
  TOGGLE_WIDGET,
  RESET_COLOR_SCHEME,
  RESET_APPEARANCE_COLOR_SCHEME,
  SET_APPEARANCE_RESOURCE,
  SET_WIDGETS,
} from "../store/types";

const defaultOpeningHours = {
  monday: [],
  tuesday: [],
  wednesday: [],
  thursday: [],
  friday: [],
  saturday: [],
  sunday: [],
};

const department = reactive({
  id: "",
  slug: "",
  mainSettings: {
    title: "",
    email: "",
    phoneNumber: "",
    contactOptions: {
      phoneCalls: false,
      requestCallback: false,
      chat: false,
      email: false,
      videoCalls: false,
    },
  },
  openingHours: { ...defaultOpeningHours },
});

export default function useWidgetConfig() {
  const { trigger } = useToaster();
  const { generateSlug, generateUUID } = useHelpers();

  const { i18n } = i18nSetup();
  const translate = i18n.global.t;

  const resetWidgets = () => {
    store.commit(`widget/${SET_WIDGETS}`, []);
    return Promise.resolve();
  };

  const triggerAlert = (e, message) => {
    if (e?.response?.status == 403) {
      if (!message) {
        message =
          e.response.data.message ||
          translate("validation.main.pages.home.account_verification");
      }
    }
    trigger({ message, type: "danger" });
  };

  const fixErrorsAlert = () => {
    trigger({
      message: translate("notify.modules.components.common.fix_errors"),
      type: "danger",
    });
  };

  const triggerSuccess = (message) => {
    trigger({ message, type: "success" });
  };

  const getContacts = async () => {
    try {
      if (!store.getters["widget/widgets"].length) {
        await store.dispatch(`widget/${GET_WIDGETS}`);
      }
    } catch (e) {
      triggerAlert(e, "Unable to fetch widget data");
    }
  };

  const addContact = async (contact) => {
    await store.dispatch(
      `widget/${SAVE_CONTACT}`,
      formatContact(unref(contact))
    );
    return Promise.resolve();
  };

  const editContact = (editedContact) => {
    editedContact = unref(editedContact);
    store.dispatch(`widget/${EDIT_CONTACT}`, {
      ...editedContact,
      slug: generateSlug(editedContact.name),
    });
    return Promise.resolve();
  };

  const deleteContact = async (id) => {
    await store.dispatch(`widget/${DELETE_CONTACT}`, id);
    return Promise.resolve();
  };

  const addDepartment = async (email, title, phoneNumber) => {

    await store.dispatch(`widget/${SAVE_DEPARTMENT}`, {
      ...unref(department),
      slug: generateSlug(department.mainSettings.title),
      id: generateUUID(),
      mainSettings: {
        ...unref(department.mainSettings),
        email,
        title,
        phoneNumber,
      },
    });
    await resetDepartment();
    return Promise.resolve();
  };

  const editDepartment = async (department) => {
    department = unref(department);
    await store.dispatch(`widget/${EDIT_DEPARTMENT}`, {
      ...department,
      slug: generateSlug(department.mainSettings.title),
    });
    return Promise.resolve();
  };

  const deleteDepartment = async (id, contactId) => {
    await setActiveResource({ contactId: contactId, departmentId: id });
    await store.dispatch(`widget/${DELETE_DEPARTMENT}`);
    return Promise.resolve();
  };

  const resetDepartment = () => {
    Object.entries(department.mainSettings).forEach(([prop]) => {
      if (prop != "contactOptions") department.mainSettings[prop] = "";
    });
    Object.entries(department.mainSettings.contactOptions).forEach(([prop]) => {
      department.mainSettings.contactOptions[prop] = false;
    });
    department.openingHours = { ...defaultOpeningHours };
    return Promise.resolve();
  };

  const formatContact = (contact) => {
    return {
      id: generateUUID(),
      slug: generateSlug(contact.name),
      ...contact,
      departments: [],
    };
  };

  const setActiveResource = (resource) => {
    store.commit(`widget/${SET_ACTIVE_RESOURCE}`, resource);
    return Promise.resolve();
  };

  const reset = () => {
    store.commit(`widget/${RESET_RESOURCE}`);
    return Promise.resolve();
  };

  const updateContacts = async ({ contacts, widgetId, widgetIndex }) => {
    return await api.post("/admin/widget/contacts/" + widgetId, {
      contacts,
    });
  };

  const saveUserLanguages = async (lang) => {
    await store.dispatch(`widget/${SAVE_USER_LANGUAGE}`, lang);
    return Promise.resolve();
  };

  const saveUserEditLangs = async (data) => {
    await store.dispatch(`widget/${SAVE_USER_EDITED_LANGS}`, data);
    return Promise.resolve();
  };

  const setLanguage = (lang) => {
    store.dispatch(`widget/${SET_LANG}`, lang);
  };

  const deletedLangFromUserLangs = (index) => {
    store.dispatch(`widget/${DELETE_LANG}`, index);
  };

  const setLanguageAndSave = async (lang) => {
    const { value, id } = store.getters["widget/loading"];
    if (value && id == "multiLang") return;
    await store.dispatch(`widget/${SAVE_DEFAULT_LANG}`, lang);
    return Promise.resolve();
  };

  const saveThrottledSidebar = throttle(() => {
    store.dispatch(`widget/${SAVE_SIDEBAR}`);
  }, 2000);

  const saveThrottledAppearance = throttle(() => {
    store.dispatch(`widget/${SAVE_APPEARANCE}`);
  }, 2000);

  const resetColorScheme = async ({
    value,
    resource = "sidebar",
    prop = null,
  }) => {
    const widgetIndex = store.getters["widget/activeWidgetIndex"];
    await store.commit(`widget/${RESET_COLOR_SCHEME}`, {
      value,
      resource,
      widgetIndex,
      prop,
    });
    resource == "sidebar"
      ? await saveThrottledSidebar()
      : await saveThrottledAppearance();
    return Promise.resolve();
  };

  const resetAppearance = async (data) => {
    const widgetIndex = store.getters["widget/activeWidgetIndex"];
    await store.commit(`widget/${RESET_APPEARANCE_COLOR_SCHEME}`, {
      data,
      widgetIndex,
    });
    await saveThrottledAppearance();
    return Promise.resolve();
  };

  const toggleWidget = (value) => {
    store.commit(`widget/${TOGGLE_WIDGET}`, value);
  };

  const setAppearanceResource = (resource) => {
    store.commit(`widget/${SET_APPEARANCE_RESOURCE}`, resource);
  };

  const loading = computed(() => {
    const { id, value } = store.getters["widget/loading"];
    return id == "widget" && value;
  });

  return {
    reset,
    loading,
    department,
    addContact,
    getContacts,
    editContact,
    resetWidgets,
    toggleWidget,
    addDepartment,
    updateContacts,
    resetDepartment,
    editDepartment,
    deleteContact,
    fixErrorsAlert,
    deleteDepartment,
    resetColorScheme,
    setActiveResource,
    triggerAlert,
    triggerSuccess,
    saveUserLanguages,
    saveUserEditLangs,
    setLanguage,
    resetAppearance,
    deletedLangFromUserLangs,
    setLanguageAndSave,
    saveThrottledSidebar,
    saveThrottledAppearance,
    setAppearanceResource,
  };
}
