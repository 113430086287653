/* eslint-disable */
import api from '@/services/api'

export default function usePasswords() {
    
    const forgotPassword = async (form) => {
        try {
            const { data } = await api.get('/admin/profile/forgot-password/'+ form.email)
            return data        
        }
        catch(e) {
            throw e
        }
    }

    const resetPassword = async (form) => {
        try {
          const { data } = await api.put('/admin/profile/reset-password',
            { password: form.password, password_confirmation: form.password_confirmation })
            return data
        }
        catch(e) {
            throw e
        }
    }

    const resendEmailVerification = async () => {
        try {
            await api.get('/admin/profile/resend-activate')
        }
        catch(e) {
            throw e
        }
    }

    return {
        forgotPassword,
        resetPassword,
        resendEmailVerification
    }

}