/* eslint-disable */
const routes = [
  {
    path: "/xadmin/dashboard",
    name: "xadmin-dashboard",
    component: () => import("@/modules/xadmin/pages/dashboard"),
    meta: {
      requiresAuth: true,
      verified: true,
    },
  },

  {
    path: "/xadmin/tarrifs",
    name: "xadmin-tarrifs",
    component: () => import("@/modules/xadmin/pages/tarrifs"),
    meta: {
      requiresAuth: true,
      verified: true,
    },
  },
];
export default routes;
