<template>
  <div>
    <Alert />

    <headerSection :isprofile="true" />

    <section class="flex flex-col xl:flex-row flex-wrap">
      <div class="xl:w-1/4 w-full p-5 overflow-y-hidden">
        <div
          class="text-black w-full flex flex-row xl:flex-col justify-center xl:justify-between text-sm py-1 px-2 pt-6 xl:pt-2 dashboard-links"
        >
          <router-link to="/profile">
            <div
              class="p-3 mb-8 flex flex-row flex-wrap rounded-full xl:rounded-none hover:shadow-lg hover:bg-gray-300 cursor-pointer"
            >
              <div class="has-tooltip flex flex-row">
                <span
                  class="tooltip xl:hidden rounded shadow-lg p-1 bg-white-100 text-gray-700 -mt-12 text-sm w-150"
                  >&nbsp; Profile Dashboard &nbsp;</span
                >
                <img
                  class="cursor-pointer px-2"
                  src="@/assets/images/profile_section/profile-dashboard.svg"
                  alt=""
                />
                <div class="hidden xl:block px-2 text-lg">
                  {{ $t('label.profile.side-menu-bar.body.dashboard') }}
                </div>
              </div>
            </div>
          </router-link>
          <router-link to="/profile/users">
            <div
              class="p-3 mb-8 flex flex-row flex-wrap rounded-full xl:rounded-none hover:shadow-lg hover:bg-gray-300 cursor-pointer"
            >
              <div class="has-tooltip flex flex-row">
                <span
                  class="tooltip xl:hidden rounded shadow-lg p-1 bg-white-100 text-gray-700 -mt-12 text-sm w-150"
                >
                  &nbsp; Information & Passwords &nbsp;</span
                >
                <img
                  class="cursor-pointer px-2"
                  src="@/assets/images/profile_section/profile-info-pass.svg"
                  alt=""
                />
                <div class="hidden xl:block px-2 text-lg">
                  {{
                    $t('label.profile.side-menu-bar.body.profile-users')
                  }}
                </div>
              </div>
            </div>
          </router-link>
          <router-link to="/profile/profile-info">
            <div
              class="p-3 mb-8 flex flex-row flex-wrap rounded-full xl:rounded-none hover:shadow-lg hover:bg-gray-300 cursor-pointer"
            >
              <div class="has-tooltip flex flex-row">
                <span
                  class="tooltip xl:hidden rounded shadow-lg p-1 bg-white-100 text-gray-700 -mt-12 text-sm w-150"
                >
                  &nbsp; Information & Passwords &nbsp;</span
                >
                <img
                  class="cursor-pointer px-2"
                  src="@/assets/images/profile_section/profile-info-pass.svg"
                  alt=""
                />
                <div class="hidden xl:block px-2 text-lg">
                  {{
                    $t('label.profile.side-menu-bar.body.profile-info-password')
                  }}
                </div>
              </div>
            </div>
          </router-link>
          <router-link to="/profile/profile-payment">
            <div
              class="p-3 mb-8 flex flex-row flex-wrap rounded-full xl:rounded-none hover:shadow-lg hover:bg-gray-300 cursor-pointer"
            >
              <div class="has-tooltip flex flex-row">
                <span
                  class="tooltip xl:hidden rounded shadow-lg p-1 bg-white-100 text-gray-700 -mt-12 text-sm w-150"
                  >&nbsp; Payments &nbsp;
                </span>
                <img
                  class="cursor-pointer px-2"
                  src="@/assets/images/profile_section/profile-payment.svg"
                  alt=""
                />
                <div class="hidden xl:block px-2 text-lg">
                  {{ $t('label.profile.side-menu-bar.body.payments') }}
                </div>
              </div>
            </div>
          </router-link>
          <router-link to="/profile/recharge-account">
            <div
              class="p-3 mb-8 flex flex-row flex-wrap rounded-full xl:rounded-none hover:shadow-lg hover:bg-gray-300 cursor-pointer"
            >
              <div class="has-tooltip flex flex-row">
                <span
                  class="tooltip xl:hidden rounded shadow-lg p-1 bg-white-100 text-gray-700 -mt-12 text-sm w-150"
                  >&nbsp; Recharge &nbsp;
                </span>
                <img
                  class="cursor-pointer px-2"
                  src="@/assets/images/profile_section/profile-recharge.svg"
                  alt=""
                />
                <div class="hidden xl:block px-2 text-lg">
                  {{ $t('label.profile.side-menu-bar.body.recharge-account') }}
                </div>
              </div>
            </div>
          </router-link>
          <router-link to="/profile/profile-subscriptions">
            <div
              class="p-3 mb-8 flex flex-row flex-wrap rounded-full xl:rounded-none hover:shadow-lg hover:bg-gray-300 cursor-pointer"
            >
              <div class="has-tooltip flex flex-row">
                <span
                  class="tooltip xl:hidden rounded shadow-lg p-1 bg-white-100 text-gray-700 -mt-12 text-sm w-150"
                  >&nbsp; Subscriptions &nbsp;</span
                >
                <img
                  class="cursor-pointer px-2"
                  src="@/assets/images/profile_section/profile-subscriptions.svg"
                  alt=""
                />
                <div class="hidden xl:block px-2 text-lg">
                  {{ $t('label.profile.side-menu-bar.body.subscriptions') }}
                </div>
              </div>
            </div>
          </router-link>
          <!-- <router-link to="/profile/profile-services">
            <div class="p-3 mb-8 flex flex-row flex-wrap rounded-full xl:rounded-none hover:shadow-lg  hover:bg-gray-300 cursor-pointer">
              <div class='has-tooltip flex flex-row'>
                <span class='tooltip xl:hidden rounded shadow-lg p-1 bg-white-100 text-gray-700 -mt-12 text-sm w-150'>&nbsp; Profile Services &nbsp;</span>
                <img class="cursor-pointer px-2" src="@/assets/images/profile_section/profile-services.svg" alt="">
                <div class=" hidden xl:block px-2 text-lg">{{ $t('label.profile.side-menu-bar.body.services') }}</div>
              </div>
            </div>
          </router-link> -->
          <!-- <router-link to="/profile/profile-support">
            <div class="p-3 mb-8 flex flex-row flex-wrap  rounded-full xl:rounded-none hover:shadow-lg  hover:bg-gray-300 cursor-pointer">
              <div class='has-tooltip flex flex-row'>
                <span class='tooltip xl:hidden rounded shadow-lg p-1 bg-white-100 text-gray-700 -mt-12 text-sm w-150'> &nbsp; Profile Support &nbsp;</span>
                <img class="cursor-pointer px-2" src="@/assets/images/profile_section/profile-support.svg" alt="">
                <div class=" hidden xl:block px-2 text-lg">{{ $t('label.profile.side-menu-bar.body.support') }}</div>
              </div>
            </div>
          </router-link> -->
        </div>
      </div>

      <div class="xl:w-3/4 w-full p-5 overflow-y-hidden">
        <router-view />
      </div>
    </section>
  </div>
</template>

<script>
import useActiveToggler from '@/composables/useActiveToggler'
import {
  computed,
  ref,
  reactive,
  onMounted,
  inject,
  unref,
  onBeforeUnmount,
  nextTick,
  watch
} from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import authenticate from '@/composables/auth/authenticate'
import headerSection from '@/modules/common/components/header-section/index'

export default {
  name: 'BaseProfile',

  components: {
    headerSection
  },

  mounted () {
    // let widget = document.createElement("script");
    // widget.setAttribute("src", "https://widget.xlinx.eu/widget.js?client=511d9819-4537-45cd-8237-003e584c1534");
    // widget.setAttribute("data-name", "client-widget");
    // document.head.appendChild(widget);
  },

  unmounted () {
    const script = document.querySelector('[data-name="client-widget"]')
    const widget = document.querySelector('.widget-button.ripple')
    if (script) document.head.removeChild(script)
    if (widget) widget.remove()
  },

  setup () {
    const store = useStore()
    const { toggleConfig, isActiveConfig } = useActiveToggler()
    const { getAuth: auth, logout } = authenticate()
    const sidebar = computed(() => store.getters['widget/sidebar'])
    const router = useRouter()

    const animation = computed(() => {
      return sidebar.value ? sidebar.value?.animation : ''
    })

    const logUserOut = () => {
      router.push({ name: 'login' })
      logout()
    }

    const triggerAlert = inject('triggerAlert')
    const t = inject('t')

    const assetPath = computed(() => {
      if (process.env.ASSET_URL != undefined + '/') {
        return process.env.ASSET_URL
      } else {
        return '/'
      }
    })

    const user_id = ref(1)
    const fetchUserInfo = () => {
      store.dispatch('profilestore/getProfileInfo', { accountId: user_id })
    }
    fetchUserInfo()
    const user_info = reactive(store.state.profilestore.personal_info)
    const email_verified = computed(
      () => store.state.profilestore.email_verified
    )

    const profile_photo = computed(() => {
      return unref(user_info)?.personal_info?.user_photo
    })

    const resendEmailPrompt = () => {
      if (!!auth.value.email_verified_at == false) {
        triggerAlert({
          message: t('notify.main.header.logout.notify'),
          action: true
        })
      }
    }

    const getWIdget = async () => {
      try {
        if (
          !store.getters['widget/widgets'].length &&
          store.state.profilestore.email_verified
        ) {
          await store.dispatch(`widget/getWidgets`)
        }
      } catch (e) {}
    }

    const checkLoadScript = () => {
      const existedScript = document.querySelector(
        '[data-name="client-widget"]'
      )

      if (existedScript) {
        existedScript.remove()
      } else {
        let widget = document.createElement('script')
        widget.setAttribute(
          'src',
          'https://widget.xlinx.eu/widget.js?client=511d9819-4537-45cd-8237-003e584c1534'
        )
        widget.setAttribute('data-name', 'client-widget')
        document.head.appendChild(widget)
      }
    }

    const deleteWidget = () => {
      const script = document.querySelector('[data-name="client-widget"]')
      const widget = document.querySelector('.widget-button.ripple')
      if (script) script.remove()
      if (widget) widget.remove()
    }

    onMounted(() => {
      resendEmailPrompt()
      getWIdget()
      nextTick(() => {
        checkLoadScript()
        getWIdget()
      })
    })

    onBeforeUnmount(() => {
      resendEmailPrompt()
      getWIdget()
      deleteWidget()
    })

    watch(email_verified, () => {
      if (email_verified.value) getWIdget()
    })

    return {
      resendEmailPrompt,
      assetPath,
      toggleConfig,
      isActiveConfig,
      user_info,
      email_verified,
      profile_photo,
      router,
      auth,
      animation,
      logUserOut
    }
  },
  created () {
    this.$i18n.locale = localStorage.getItem('user_lang')
  },
  data () {
    return {}
  },
  methods: {}
}
</script>

<style scoped>
.tooltip {
  visibility: hidden;
  position: absolute;
  margin-left: -42px;
  border-radius: 4px;
}

.has-tooltip:hover .tooltip {
  visibility: visible;
  z-index: 100;
}

.dashboard-links a img {
  opacity: 0.5;
}

.dashboard-links a.router-link-active svg,
.dashboard-links a.router-link-exact-active img {
  opacity: 0.9;
}
</style>

<style lang="scss">
.widget-button {
  position: fixed;
  bottom: 4%;
  cursor: pointer;
  z-index: 100;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.ripple {
  transition: all 0.3s ease-in-out 0s;
  .animation--inner {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border-color: #1ab394;
    border-style: solid;
    border-width: 3px;
    position: absolute;
    z-index: -1;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: ring 2.5s infinite;
    box-shadow: 0 0 8px #1ab394;
  }

  &:hover,
  &:focus {
    color: #313133;
    transform: translateY(-6px);
  }
}

.ripple:hover::after,
.ripple:focus::after {
  animation: none;
  display: none;
}

.bounce {
  animation: bounce 0.8s infinite;
}

.shake {
  animation: shake 2s infinite cubic-bezier(0.36, 0.07, 0.19, 0.97);
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.wiggle {
  animation-name: wiggle;
  animation-duration: 1s;
  transform-origin: 50% 50%;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes wiggle {
  0% {
    transform: translate(2px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(0px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(2px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(2px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

@keyframes ring {
  0% {
    width: 30px;
    height: 30px;
    opacity: 1;
  }
  100% {
    width: 200px;
    height: 200px;
    opacity: 0;
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}
</style>
