import en from "@/resources/translations/en.json";
import { createI18n } from "vue-i18n";

export default function i18nSetup() {
  const i18n = createI18n({
    //legacy: false,
    locale: "en",
    messages: {
      en: en,
    },
    fallbackLocale: "en",
    useScope: `global`,
  });

  const loadedLanguages = ["en"];

  function setI18nLanguage(lang) {
    i18n.locale = lang;
    document.querySelector("html").setAttribute("lang", lang);
    return lang;
  }
  async function loadLanguageAsync(lang) {
    // If the same language
    if (i18n.locale === lang) {
      return Promise.resolve(setI18nLanguage(lang));
    }

    // If the language was already loaded
    if (loadedLanguages.includes(lang)) {
      return Promise.resolve(setI18nLanguage(lang));
    }

    // If the language hasn't been loaded yet
    return import(
      /* webpackChunkName: "lang-[request]" */ `../resources/translations/${lang}.json`
    ).then((messages) => {
      i18n.setLocaleMessage(lang, messages.default);
      loadedLanguages.push(lang);
      return setI18nLanguage(lang);
    });
  }

  return {
    i18n,
    setI18nLanguage,
    loadLanguageAsync,
  };
}

//const loadedLanguages = ['en']; // our default language that is preloaded
