<template>
    <button 
        type="button" 
        class="w-full inline-flex justify-center items-center gap-2 border shadow-sm px-4 py-2 text-base font-medium  dark:bg-gray-800 dark:text-off-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm dark:hover:bg-gray-900 dark:border-gray-800"
        :class="classSet"
    >
        {{ text }}
        <slot></slot>
    </button>
</template>

<script setup>

    import { computed } from 'vue'

    const props = defineProps({
        text: {
            required: true,
            type: String
        },
        kind: {
            required: false,
            type: String,
            default: 'primary'
        },
        classes: {
            required: false,
            type: [String, Array],
            default: '',
        }
    })

    const classSet = computed(() => {
        const classMappings = {
            default: 'border-gray-300 bg-white hover:bg-gray-50 text-gray-700 focus:ring-indigo-500',
            primary: 'border-transparent bg-dark-green hover:bg-green-700 text-white focus:ring-green-500'
        }
        const classes = typeof props.classes == 'string' ? props.classes.split(',') : props.classes
        return [classMappings[props.kind], ...classes]
    })

</script>

<style lang="scss" scoped>

</style>