/* eslint-disable */

import { ref, computed } from 'vue'
import { isEmpty, startCase } from 'lodash'
import i18nSetup from '@/services/i18nSetup'
const { i18n } = i18nSetup()
const translate = i18n.global.t

const errors = ref({})

export default function validator() {
    
    const hasErrors = ref(false)

    const validate = ({ data, rules }) => {
        
        rules = Object.entries(rules)

        rules.forEach(([ key, values ]) => {
            
            values.forEach(value => {
                
                value = value.toLowerCase().toString().split(':')
                const rule = value[0]    
                
                switch (rule) {
                    case 'required':
                        checkRequired(key, data)
                        break
                    case 'unique':
                        checkUnique(key, data)
                        break
                    case 'nospace':
                        checkNospace(key, data)
                        break
                    case 'between':
                        checkRange({ key, data, range: value[1] })
                        break
                    case 'match': 
                        checkMatch({ key, data, match: value[1] })
                        break    
                    case 'email': 
                        checkEmail(key, data)
                        break
                    case 'valuein': 
                        checkValueIn({ key, data, valueIn: value[1] })
                        break            
                    case 'exists': 
                        checkExists(key, data)
                        break 
                    case 'url':    
                        isValidUrl(key, data)  
                        break
                }

            })

        })

        return hasErrors.value

    }

    const checkRequired = (key, data) => {
        if(!data[key] || data[key] === null || data[key] === '') {
            return setErrors({ key, message: `${startCase(key)} ${translate('validation.modules.widget.validate.required')}.` })
        }
    }

    const checkUnique = (key, data, resource = []) => {
        resource = resource.length ? resource : JSON.parse(localStorage.getItem('users'))
        if(resource && resource.length) {
            const res = resource.find(resource => resource[key] === data[key])
            if(resource) {
                return setErrors({ key, message: `${startCase(key)} ${translate('validation.modules.widget.validate.exists')}.` })
            }
        }
    }

    const checkNospace = (key, data) => {
        if(/\s/g.test(data[key])) {
            return setErrors({ key, message: `${translate('validation.modules.widget.validate.enter_valid')} ${startCase(key)}. ${translate('validation.modules.widget.validate.spaces_not_allowed')}.` })
        }
    }

    const checkRange = ({ key, data, range}) => {
        range = range.toString().split('-')
        const startRange = range[0]
        const endRange = range[1]

        if(!(data[key].length >= startRange && data[key].length <= endRange)) {
            return setErrors({ key, message: `${startCase(key)} ${translate('validation.modules.widget.validate.should_be_between')} ${startRange} - ${endRange}` })
        }
    }

    const checkMatch = ({ key, data, match }) => {
        if(data[key] !== data[match]) {
            return setErrors({ key, message: `${translate('validation.modules.widget.validate.passwords_mismatch')}` })
        }
    }

    const checkEmail = (key, data) => {
        const re = /\S+@\S+\.\S+/
        if(!re.test(data[key])) {
            return setErrors({ key, message: `${translate('validation.modules.widget.validate.invalid_email')}` })
        }
    }

    const checkValueIn = ({ key, data, valueIn }) => {
        if(data[key].toString() != valueIn.toString()) {
            return setErrors({ key, message: `${startCase(key)} ${translate('validation.modules.widget.validate.should_be_true')}.` })
        }
    }

    const checkValueNotIn = (key, data) => {
        if(data[key] == 0 || data[key] == '' || data[key] == null) {
            return setErrors({ key, message: `${translate('validation.modules.widget.validate.choose_valid')} ${startCase(key)}` })
        }
    }

    const checkExists = (key, data) => {
        const users = JSON.parse(localStorage.getItem('users'))
        if(!users.find(user => user.username === data[key])) {
            return setErrors({ key, message: `${startCase(key)} ${translate('validation.modules.widget.validate.not_exist')}` })
        }
    }

    const checkIsNumber = (key, data) => {
        if(isNaN(data[key])) {
            return setErrors({ key, message: `${translate('validation.modules.widget.validate.enter_valid')} ${startCase(key)}` })
        }
    }

    const setErrors = ({ key, message }) => {
        const newErrors = ({ ...errors.value, [key]: [ message ] })
        errors.value = newErrors
        hasErrors.value = true
    }

    const removeError = (name) => {
        let filteredErrors = errors.value
        delete filteredErrors[name]
        Object.assign(errors.value, filteredErrors)
        hasErrors.value = false
    }

    const fillErrors = (serverErrors) => {
        errors.value = serverErrors
    }

    const clearErrors = () => {
        errors.value = {}
    }

    const restrictNumbersOnly = (event, number, restrict = false) => {

        if(typeof event == 'undefined' || number == false) return true

        var ASCIICode = (event.which) ? event.which : event.keyCode
        
        if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) { 

            if(ASCIICode == 43 && restrict == false) return true

            event.preventDefault()
            return false
            
        }

        return true

    }

    const isValidUrl = (key, data) => {

        const urlString = data[key]

        var urlPattern = new RegExp(
            '^(https?:\\/\\/)?'+ // validate protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
            '(\\#[-a-z\\d_]*)?$','i' // validate fragment locator
        ) 
        
        if(!!urlPattern.test(urlString) == false) {
            return setErrors({ key, message: `${translate('validation.modules.widget.validate.enter_valid')} ${startCase(key)}` })
        }

    }

    return {
        isValidUrl,
        fillErrors,
        setErrors,
        validate,
        errors,
        removeError,
        clearErrors,
        hasErrors,
        checkRequired,
        checkEmail,
        checkIsNumber,
        checkValueNotIn,
        restrictNumbersOnly
    }

}