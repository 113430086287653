<template>
  <section class="fixed w-full h-full global-loader cursor-not-allowed flex items-center justify-center z-10">
    <Loader />
  </section>
</template>

<script>
import Loader from "@/shared/utils/MapLoader";
export default {
  components: {
    Loader,
  },
};
</script>

<style lang="scss" scoped>
.global-loader {
  background-color: rgba(#000000, 0.35);
}
</style>