<template>
  <div class="flex column-gap-1 justify-end items-center table-settings">
    <div
      class="cursor-pointer mr-2 color-red table-settings-edit"
      v-if="showEditButton"
    >
      <router-link :to="{ name: name, params: { id: id } }">
        <i class="fa-regular fa-pen-to-square edit-icon"></i>
      </router-link>
    </div>
    <div class="cursor-pointer table-settings-delete" @click="deleteRecord">
      <i class="fa-regular fa-trash delete-icon"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "TableSetting",

  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    showEditButton: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  setup(props, { emit }) {
    const deleteRecord = () => {
      emit("delete", props.id);
    };

    return {
      deleteRecord,
    };
  },
};
</script>

<style lang="scss">
.table-settings-edit svg {
  fill: rgb(26, 179, 148);
  &:hover {
    fill: rgb(20, 219, 179);
  }
}

.table-settings-edit .edit-icon {
  color: rgb(26, 179, 148);
  &:hover {
    color: rgb(20, 219, 179);
  }
}

.table-settings-delete .delete-icon {
  color: #000;
  &:hover {
    color: #817676;
  }
}

.table-settings-delete svg {
  fill: #000;
  &:hover {
    fill: #605e5e;
  }
}
</style>