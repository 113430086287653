
import { computed, ref } from 'vue'
const themeMode = ref('light')

export default function useThemeToggler() {


    const activateMode = (mode = 'dark') => {
        localStorage.setItem('theme', mode.toString())
        themeMode.value = mode.toString()   
    }

    const removeMode = () => {
        localStorage.removeItem('theme')
    }

    const isDarkthemeMode = computed(() => {
        return themeMode.value === 'dark'
    })  

    const setThemeMode = () => {
        const theme = localStorage.getItem('theme')
        if(theme) themeMode.value = theme
    }

    return {
        activateMode,
        removeMode,
        themeMode,
        setThemeMode,
        isDarkthemeMode,
    }

}