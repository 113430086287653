<template>
  <section class="flex flex-row flex-wrap w-full">
    <div
      class="flex flex-col justify-center items-center mx-auto"
      v-if="user_info"
    >
      <div class="my-1">
        <img
          v-if="user_info.personal_info && user_info.personal_info.user_photo"
          class="w-24 h-24 object-cover rounded-full"
          :src="user_info.personal_info.user_photo"
          alt=""
        />
        <img
          v-else
          class="w-24 h-24 object-cover rounded-full"
          src="@/assets/images/profile_section/user.jpg"
          alt=""
        />
      </div>
      <div
        class="mb-1 font-bold text-3xl"
        v-if="user_info.personal_info && user_info.personal_info.name"
      >
        <p>{{ user_info.personal_info.name }}</p>
      </div>
      <div class="mb-1 text-lg" v-if="user_info.company_info">
        <p>{{ user_info.company_info.company_name }}</p>
      </div>
      <div class="mb-1 text-lg" v-if="user_info.company_info">
        <p>{{ user_info.company_info.company_email }}</p>
      </div>
    </div>
    <div class="flex flex-col lg:flex-row flex-wrap w-full">
      <div class="flex-1">
        <router-link to="/profile/profile-info">
          <div
            class="m-5 pb-8 rounded-sm border-b-1 border-r-3 border-gray-500 bg-white shadow-sm hover:shadow-lg cursor-pointer"
          >
            <div class="flex flex-row flex-wrap">
              <div class="flex flex-col flex-wrap p-5 2xl:p-8 w-3/5">
                <p class="font-bold text-lg text-left">
                  {{ $t('label.profile.main-bar.body.profile-info.title') }}
                </p>
                <div class="mt-12">
                  <p class="text-md text-left">
                    {{ $t('label.profile.main-bar.body.profile-info.desc1') }}
                  </p>
                  <p class="text-md text-left">
                    {{ $t('label.profile.main-bar.body.profile-info.desc2') }}
                  </p>
                  <p class="text-md text-left">
                    {{ $t('label.profile.main-bar.body.profile-info.desc3') }}
                  </p>
                </div>
              </div>
              <div class="p-5 w-2/5">
                <img
                  class="px-2"
                  src="@/assets/images/profile_section/profile-info-main.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <div class="flex-1">
        <router-link to="/profile/profile-payment">
          <div
            class="m-5 pb-8 rounded-sm border-b-1 border-r-3 border-gray-500 bg-white shadow-sm hover:shadow-lg cursor-pointer"
          >
            <div class="flex flex-row flex-wrap">
              <div class="flex flex-col flex-wrap p-5 2xl:p-8 w-3/5">
                <p class="font-bold text-lg text-left">
                  {{
                    $t(
                      'label.profile.main-bar.body.payment-methods-transaction.title'
                    )
                  }}
                </p>
                <div class="mt-8">
                  <!-- <p class="text-md text-left">{{ $t('label.profile.main-bar.body.payment-methods-transaction.desc1') }}</p> -->
                  <p class="text-md text-left">
                    {{
                      $t(
                        'label.profile.main-bar.body.payment-methods-transaction.desc2'
                      )
                    }}
                  </p>
                  <p class="text-md text-left">
                    {{
                      $t(
                        'label.profile.main-bar.body.payment-methods-transaction.desc3'
                      )
                    }}
                  </p>
                </div>
              </div>
              <div class="p-2 w-2/5">
                <img
                  class="px-2 xl:h-48 2xl:h-auto"
                  src="@/assets/images/profile_section/profile-payment-main.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <div class="flex flex-col lg:flex-row flex-wrap w-full">
      <div class="flex-1">
        <router-link to="/profile/profile-subscriptions">
          <div
            class="m-5 pb-8 rounded-sm border-b-1 border-r-3 border-gray-500 bg-white shadow-sm hover:shadow-lg cursor-pointer"
          >
            <div class="flex flex-row flex-wrap">
              <div class="flex flex-col flex-wrap p-8 w-3/5">
                <p class="font-bold text-lg text-left uppercase">
                  {{ $t('label.profile.side-menu-bar.body.subscriptions') }}
                </p>
                <div class="mt-12">
                  <p class="text-md text-left">
                    {{ $t('label.profile.main-bar.body.services.desc1') }}
                  </p>
                  <p class="text-md text-left">
                    {{ $t('label.profile.main-bar.body.services.desc2') }}
                  </p>
                </div>
              </div>
              <div class="p-5 w-2/5">
                <img
                  class="px-2"
                  src="@/assets/images/profile_section/profile-services-main.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <div class="flex-1">
        <router-link to="/profile/recharge-account">
          <div
            class="m-5 pb-8 rounded-sm border-b-1 border-r-3 border-gray-500 bg-white shadow-sm hover:shadow-lg cursor-pointer"
          >
            <div class="flex flex-row flex-wrap">
              <div class="flex flex-col flex-wrap p-8 w-3/5">
                <p class="font-bold text-lg text-left uppercase">
                  {{ $t('link.app.header.recharge_account') }}
                </p>
                <div class="mt-12">
                  <p class="text-md text-left">
                    {{ $t('label.profile.main-bar.body.support.desc1') }}
                  </p>
                  <p class="text-md text-left">
                    {{ $t('label.profile.main-bar.body.support.desc2') }}
                  </p>
                </div>
              </div>
              <div class="p-5 w-2/5">
                <img
                  class="px-2"
                  src="@/assets/images/profile_section/profile-support-main.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import { computed, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'ProfileDashboard',
  setup () {
    const store = useStore()
    const assetPath = computed(() => {
      if (process.env.ASSET_URL != undefined + '/') {
        return process.env.ASSET_URL
      } else {
        return '/'
      }
    })
    const bgImage = computed(() => {
      return {
        backgroundImage: `url(${require('@/assets/images/profile_section/bg-profile.svg')})`,
        backgroundSize: 'cover'
      }
    })

    const user_info = reactive(store.state.profilestore.personal_info)
    const showProfileData = ref(false)

    watch(user_info, () => {
      if (user_info && user_info?.personal_info?.length)
        showProfileData.value = true
    })

    return { assetPath, bgImage, user_info, showProfileData }
  },
  data () {
    return {}
  },
  mounted () {
    //this.fetchUserInfo();
  }
}
</script>

<style scoped lang="scss"></style>
