<template>
  <div class="bg-white w-full">
    <section class="flex flex-col flex-wrap w-full">
        <div class="flex flex-col justify-center items-center mx-auto">
          <div class="mb-1 font-bold text-3xl"><p>{{ $t('label.profile.profile-services.top.services.title') }}</p></div>
          <div class="mb-1 text-lg"><p>{{ $t('label.profile.profile-services.top.services.desc') }}</p></div>
        </div>
        <div class="grid grid-cols-1 3xl:grid-cols-3 gap-4 justify-items-center service-card">
              <div class="service-container" v-for="(service, index) in profile_service.profile_services.length" :key="index">
                  <div class=" m-5 pb-3 rounded-sm border-b-1 border-r-3 border-gray-500 bg-white shadow-md">
                    <div class="flex flex-row-reverse items-end flex-wrap px-3 items-center cursor-pointer" @click.prevent="toggleModal('serviceInstruction', true)">
                      <div class=""><img class="py-2 pl-2" src="@/assets/images/profile_section/profile-services-imark.svg" alt=""></div>
                    </div>
                    <div class="w-full" v-for="(array, index) in array_services" :key="index">
                      <img class="py-2 pl-2 mx-auto" v-if="profile_service.profile_services[service-1].service_type == array.type" :src="array.icon" alt="">
                      <p class="py-3 mb-3 font-bold text-3xl mx-auto" v-if="profile_service.profile_services[service-1].service_type == array.type">{{array.label}}</p>
                    </div>
                    <hr>
                    <div class="w-full cursor-pointer" @click.prevent="sendServiceInfo(profile_service.profile_services[service-1])"><p class="mt-3 py-3 font-bold text-lg mx-auto">{{ $t('label.profile.profile-services.panel-body.services.manage-subscription') }}</p></div>
                  </div>
              </div>
        </div>
    </section>
    <manage-service-modal 
      :service_info="modal_service_info"
      :service_payment_info="service_payment_info"
    />
    <service-instruction-modal />
  </div>
</template>

<script>
/* eslint-disable */
import { computed, reactive } from "vue";
import manageService from '../components/modals/manage-service'
import serviceInstruction from '../components/modals/service-instruction'
import useModal from '@/composables/useModal'
import useActiveToggler from '@/composables/useActiveToggler'

import {useStore} from "vuex";

export default {
  name: "ProfileServices",
  components: {
      manageServiceModal: manageService,
      serviceInstructionModal: serviceInstruction,
  },
  setup() {

    const store = useStore();
    const assetPath = computed(() => {
      if (process.env.ASSET_URL != undefined + "/") {
        return process.env.ASSET_URL;
      } else {
        return "/";
      }
    });
    const { toggleConfig, isActiveConfig } = useActiveToggler()
    const { toggleModal } = useModal()

    const getProfileServices = (params) => {
      store.dispatch('profilestore/getProfileServices', { params});
    };
    getProfileServices();
    const profile_service =  reactive(store.state.profilestore.profile_services);

    return { 
            assetPath, 
            toggleModal,
            toggleConfig,
            isActiveConfig,
            profile_service
          };
  },
  data() {
        return {
            array_services: [
              { type: 'virtual-number', label:'Virtual Number', icon: require('@/assets/images/profile_section/profile-services-vn.svg') },
              { type: 'contract-center', label:'Contact Center', icon: require('@/assets/images/profile_section/profile-services-cc.svg') },
              { type: 'website-dialer', label:'Website Dialer', icon: require('@/assets/images/profile_section/profile-services-wd.svg') },
              { type: 'pbx-ivr', label:'PBX-IVR',  icon: require('@/assets/images/profile_section/profile-services-pbx-ivr.svg') },
              { type: 'call-tracker', label:'Call-Tracker', icon: require('@/assets/images/profile_section/profile-services-ct.svg') },
            ],
            modal_service_info : {},
            service_payment_info: {},
        };
  },
  methods:{
    sendServiceInfo(service){
      this.toggleModal('manageService', true)
      this.array_services.forEach( (element) =>{
        if(element.type == service.service_type){
          const proxyElement = new Proxy(element, {});
          this.modal_service_info = {...proxyElement};
          this.service_payment_info = service;
        }
    })
    }
  }

};
</script>

<style scoped lang="scss">
</style>


