<template>
  <div class="delete-modal-overlay">
    <div class="delete-modal p-5">
      <p>{{ $t("xpbx.texts.delete_modal") }}</p>
      <div class="flex items-center justify-between mt-4">
        <button
          class="bg-dark-green text-gray-200 p-1 px-10 text-center transition ease-in duration-100 hover:opacity-90 h-10 rounded-md mr-5"
          @click.prevent="$emit('close')"
        >
          {{ $t("xpbx.button.close") }}
        </button>
        <button
          :disabled="savingRecord"
          class="bg-red-800 text-gray-200 p-1 px-10 text-center transition ease-in duration-100 hover:opacity-90 h-10 rounded-md"
          @click.prevent="$emit('confirm')"
          :class="{ 'cursor-not-allowed': savingRecord }"
        >
          {{ $t("xpbx.button.confirm") }}
          <font-awesome-icon
            icon="circle-notch"
            class="fa-spin"
            v-show="savingRecord"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DeleteModal",

  props: {
    savingRecord: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.delete-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.delete-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 24px;
  border-radius: 5px;
}
</style>