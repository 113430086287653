<template>
  <input
    type="text"
    name="username"
    placeholder="Name"
    class="block border-0 border-b-half w-full focus:outline-none"
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
  />
</template>

<script>
export default {
  props: ["modelValue"],
  emits: ["update:modelValue"],
};
</script>
