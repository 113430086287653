<template>
  <transition
    enter-active-class="transition ease-out duration-200"
    enter-from-class="transform opacity-0 scale-95"
    enter-to-class="transform opacity-100 scale-100"
    leave-active-class="transition ease-in duration-75"
    leave-from-class="transform opacity-100 scale-100"
    leave-to-class="transform opacity-0 scale-95"
  >
    <div
      class="origin-top-right absolute right-1 mt-5 shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 focus:outline-none text-off-white z-20 border-0 border-t-2 border-dark-green dropdown"
      :class="classes"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabindex="1"
      v-click-away="close"
    >
      <div class="py-1 w-full" role="none">
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
import useActiveToggler from "@/composables/useActiveToggler";
export default {
  props: {
    id: {
      type: [String, Number],
      required: false,
    },
    classes: {
      required: false,
      type: String,
    },
  },

  setup(props) {
    const { activeDropdown, isActiveDropdown, toggleDropdown } =
      useActiveToggler();
    const close = () => {
      if (props.id) {
        if (isActiveDropdown(props.id)) {
          toggleDropdown(props.id);
        }
      }
    };
    return {
      close,
      activeDropdown,
    };
  },
};
</script>

<style scoped>
.dropdown::-webkit-scrollbar {
  width: 5px;
}

.dropdown::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.dropdown::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
</style>
