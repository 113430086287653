<template>
  <div :class="classes" class="form-group text-xl my-3 font-inter relative">
    <label :for="name" class="font-bold" v-show="!!headerLabel">
      {{ headerLabel }}
      <span class="text-red-700 font-bold" v-show="requiredField">*</span>
    </label>
    <div class="form-control">
      <input
        :type="$attrs.type || 'text'"
        :name="name"
        :placeholder="placeholder"
        class="block border-0 border-b-half w-full focus:outline-none dark:bg-gray-800 dark:text-off-white py-1 dark:placeholder-gray-100"
        :class="[
          !!errors
            ? ['border-red-900 placeholder-red-900']
            : [
                'border-light-green placeholder-gray-500 focus:border-green-700',
              ],
        ]"
        @input="$emit('update:modelValue', $event.target.value)"
        :value="modelValue"
        @keypress="restrictNumbersOnly($event, number, restrict)"
        :maxlength="$attrs.maxlength || 200"
      />
    </div>
    <label
      v-show="!!footerLabel"
      :for="name"
      class="text-xs"
      :class="!!errors ? 'text-red-900' : 'text-gray-500'"
    >
      {{ !!errors ? errors[0] : footerLabel }}
    </label>
    <div v-if="showEyeIcon" class="password-icon">
      <i
        class="fa-regular fa-eye password-image"
        @click="$emit('showPassword')"
        v-if="showPassword"
      ></i>
      <i
        class="fa-regular fa-eye-slash password-image"
        v-else
        @click="$emit('showPassword')"
      ></i>
    </div>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import validator from "@/composables/auth/validator";

export default {
  name: "FormInput",
  inheritAttrs: false,
  directives: {
    mask,
  },
};
</script>

<script setup>
import { computed } from "vue";

const props = defineProps({
  modelValue: {
    required: true,
    type: [String, Object, Number],
  },
  errors: {
    required: false,
    type: Object,
    default: () => {},
  },
  headerLabel: {
    required: false,
    type: String,
    default: "",
  },
  footerLabel: {
    required: false,
    type: String,
    default: "",
  },
  placeholder: {
    required: false,
    type: String,
    default: "",
  },
  requiredField: {
    required: false,
    type: Boolean,
    default: true,
  },
  name: {
    required: true,
    type: String,
  },
  classes: {
    required: false,
    type: String,
    default: "",
  },
  inputmask: {
    required: false,
    type: String,
    default: "X",
  },
  number: {
    required: false,
    type: Boolean,
    default: false,
  },
  restrict: {
    required: false,
    type: Boolean,
    default: false,
  },
  showEyeIcon: {
    required: false,
    type: Boolean,
    default: false,
  },
  showPassword: {
    required: false,
    type: Boolean,
    default: false,
  },
});

const { restrictNumbersOnly } = validator();

const customInputMask = computed(() => {
  return {
    mask: props.inputmask === "X" ? props.inputmask : `+${props.inputmask}`,
    tokens: {
      "#": {
        pattern: /[0-9]*/,
      },
      X: {
        pattern: /[0-9A-Za-z]*/,
      },
    },
  };
});
</script>

<style lang="scss" scoped>
.password-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;

  svg {
    fill: #1ab394;
  }
}

.password-image {
  color: #1ab394;
}
</style>