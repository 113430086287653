import { computed, unref } from "vue";
import validator from "@/composables/auth/validator";

const hasErrors = computed(() => Object.entries(errors.value).length);
const { checkRequired, errors, clearErrors } = validator();

export function validateUser(user) {
  clearErrors();

  user = unref(user);

  checkRequired("description", user);
  checkRequired("password", user);
  // checkRequired("extension", user);
  checkRequired("username", user);

  return hasErrors.value ? false : true;
}

export function validatUpdateeUser(user) {
  clearErrors();

  user = unref(user);

  checkRequired("domain", user);
  checkRequired("password", user);
  checkRequired("username", user);
  // checkRequired("extension", user);
  checkRequired("description", user);

  return hasErrors.value ? false : true;
}
