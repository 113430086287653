<template>
    <a :href="`${link ? link : '#'}`" 
    class="text-gray-700 text-left block px-4 py-2 text-large leading-6 font-inter font-light hover:text-black dark:text-off-white dark:bg-gray-800 dark:hover:text-white dark:hover:bg-gray-900"
    :class="[{'text-black bg-gray-100 dark:text-off-white dark:bg-gray-900': isDarkthemeMode}, classes]"
    role="menuitem"
    tabindex="-1"
    >
        <slot></slot>
    </a>
</template>

<script>

import useThemeToggler from '@/composables/useThemeToggler'

export default {
    
    props: {
        classes: {
            required: false,
            type: String
        },
         link: {
            required: false,
            type: String
        }
    },

    setup() {
        
        const { isDarkthemeMode } = useThemeToggler()
        return {
            isDarkthemeMode
        }

    }

}
</script>