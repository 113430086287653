<template>
    <div class="form-check">

        <label class="checkbox__container checkbox__container--block" :class="{checkbox__inactive: disabled}" :for="name">

            {{ label }}
            <input 
                :id="name" 
                type="checkbox" 
                :checked="!disabled ? modelValue : false"
                :disabled="disabled"
                @change="$emit('update:modelValue', !modelValue)"
            />
            <span class="checkmark" :class="{ 'checkmark-dark': isDarkthemeMode }"></span>

        </label>

    </div>

</template>

<script setup>
    
    import useThemeToggler from '@/composables/useThemeToggler'

    defineProps({
        label: {
            required: true,
            type: String
        },
        modelValue: {
            required: true,
            type: [Boolean, String]
        },
        disabled: {
            required: false,
            default: false,
            type: [Boolean]
        },
        name: {
            required: true,
            type: String
        }
    })

    defineEmits(['update:modelValue'])

    const { isDarkthemeMode } = useThemeToggler()


</script>

<style lang="scss" scoped>

</style>