<template>
  <UsersTable :showLogin="true" />
</template>

<script>
import UsersTable from "@/modules/xpbx/components/users/users-table/index.vue";

export default {
  name: "ProfileUsers",

  components: {
    UsersTable,
  },
};
</script>

<style>
</style>