import api from "@/services/api";

import {
  SET_LOADING,
  SET_MOUNTED,
  GET_STATS,
  SET_STATS,
  GET_STATS_FILTERS,
  SAVE_STATS_FILTERS,
} from "./types";

export const stats = {
  namespaced: true,

  state: {
    stats: [],
    loading: {
      value: false,
      id: "stats",
    },
    isMounted: false,
    statsFilters: {},
  },

  getters: {
    stats: (state) => state.stats,
    loading: (state) => state.loading,
    isMounted: (state) => state.isMounted,
    statsFilters: (state) => state.statsFilters,
  },

  mutations: {
    [SET_LOADING](state, loading = {}) {
      const { id = "stats", value = true } = loading;
      state.loading.value = value;
      state.loading.id = id;
    },
    [SET_STATS](state, data) {
      state.stats = data;
    },
    [SET_MOUNTED](state, data) {
      state.isMounted = data;
    },
    [SAVE_STATS_FILTERS](state, data) {
      state.statsFilters = data;
    },
  },

  actions: {
    async [GET_STATS]({ commit }, data) {
      // const { filters = {}, resource = "call", page = 1 } = data;
      const { url } = data;
      if(!url) return;
      const formData = { ...data.filters };

      try {
        commit(SET_STATS, []);
        commit(SET_LOADING, { id: "stats", value: true });
        commit(SET_MOUNTED, false);
        const { data } = await api.post(
          `/admin/reports/${url || "calls"}`,
          formData
        );
        // const { data } = await api.post(`/admin/reports/${data?.url || 'audio-call'}`, {
        //   ...filters,
        //   resource,
        //   page,
        // });
        commit(SET_STATS, data ? data : []);
        commit(SET_LOADING, { value: false });
        commit(SET_MOUNTED, true);
      } catch (e) {
        commit(SET_LOADING, { value: false });
        throw e;
      }
    },

    async [GET_STATS_FILTERS]({ commit, state }) {
      try {
        if (Object.entries(state.statsFilters).length) return;

        const { data } = await api.get("/admin/reports/filter");
        commit(SAVE_STATS_FILTERS, data);
      } catch (e) {}
    },
  },
};
