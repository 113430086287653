import api from "@/services/api";
import {
  SET_LOADING,
  GET_MARKERS,
  SET_MARKERS,
} from "./types";
import { set } from "lodash";
export const markers = {
  namespaced: true,
  state: {
    markers: [],
    mapFilters: {},
    loading: {
      value: false,
      id: "markers",
    },
  },
  getters: {
    markers: (state) => state.markers,
    loading: (state) => state.loading,
  },
  mutations: {
    [SET_LOADING](state, loading = {}) {
      const { id = "markers", value = true } = loading;
      state.loading.value = value;
      state.loading.id = id;
    },
    [SET_MARKERS](state, data) {
      state.markers = data;
    },
  },
  actions: {
    async [GET_MARKERS]({ commit, state }, data) {
      commit(SET_MARKERS, []);
      const { filters = {} } = data;
      const positions = data.positions.value;
      const service =
        filters.activeService.id !== 0 ? filters.activeService.key : null;
      const widget_id =
        filters.activeWidget.id !== 0 ? filters.activeWidget.key : null;
      const office_id =
        filters.activeContact.id !== 0 ? filters.activeContact.key : null;
      const department_id =
        filters.activeDepartment.id !== 0 ? filters.activeDepartment.key : null;

      if (
        !filters.startTime &&
        !filters.endTime &&
        !service &&
        !widget_id &&
        !office_id &&
        !department_id
      )
        return;

      try {
        commit(SET_LOADING, { id: "markers", value: true });
        const { data } = await api.post("/admin/reports/map", {
          //...filters,
          startTime: filters.startTime,
          endTime: filters.endTime,
          service,
          widget_id,
          office_id,
          department_id,
          zoomFactor: filters.zoomFactor,
          positions,
        });
        commit(SET_MARKERS, data);
        commit(SET_LOADING, { value: false });
      } catch (e) {
        commit(SET_LOADING, { value: false });
        throw e;
      }
    },
  },
};
