<template>
  <div
    class="form-group text-xl my-3 font-inter"
    :class="$attrs.class"
    :style="$attrs.style"
  >
    <label :for="name" class="font-bold" v-show="!!headerLabel">
      {{ headerLabel }}
      <span class="text-red-700 font-bold" v-show="requiredField">*</span>
    </label>
    <div class="form-control">
      <slot>
        <select
          :name="name"
          class="block border-1 border-b-half w-full focus:outline-none dark:bg-gray-800 dark:text-off-white py-1 dark:placeholder-gray-100"
          :class="[
            { active: activeClass },
            errors
              ? 'border-red-900 placeholder-red-900'
              : 'border-light-green placeholder-gray-500 ',
          ]"
          @change="$emit('update:modelValue', $event.target.value)"
        >
          <template v-if="Object.entries(options).length">
            <option
              :value="option.value"
              v-for="option in options"
              :key="option.value"
              :selected="option.value == modelValue"
            >
              {{ option.name }}
            </option>
          </template>
        </select>
      </slot>
    </div>
    <label
      v-show="!!footerLabel"
      for="name"
      class="text-xs"
      :class="!!errors ? 'text-red-900' : 'text-gray-500'"
    >
      {{ !!errors ? errors[0] : footerLabel }}
    </label>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import { computed } from "vue";

defineProps({
  modelValue: {
    required: false,
    type: [String, Boolean, Number],
    default: "",
  },
  errors: {
    required: false,
    type: Object,
    default: () => {},
  },
  headerLabel: {
    required: false,
    type: String,
    default: "",
  },
  footerLabel: {
    required: false,
    type: String,
    default: "",
  },
  options: {
    required: false,
    type: Object,
    default: () => {},
  },
  requiredField: {
    required: false,
    type: Boolean,
    default: true,
  },
  activeClass: {
    activeClass: Boolean,
    required: false,
  },
  name: {
    required: true,
    type: String,
  },
});
</script>

<style lang="scss" scoped>
.active {
  border: 1px solid #94a3b8;
  border-radius: 6px;
  min-height: 42px;
  padding: 0.5rem 0.75rem;

  &:focus {
    border: 1px solid #10b981;
  }
}
</style>