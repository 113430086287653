/* eslint-disable */
const routes = [
  {
    path: "/xpbx",
    name: "xpbx",
    component: () => import("@/modules/xpbx/pages/dashboard"),
    meta: {
      requiresAuth: true,
      verified: true,
    },
    children: [],
  },
  {
    path: "/xpbx/reports",
    name: "xpbx-reports",
    component: () => import("@/modules/xpbx/pages/reports"),
    meta: {
      requiresAuth: true,
      verified: true,
    },
  },
  {
    path: "/xpbx/settings",
    name: "xpbx-settings",
    component: () => import("@/modules/xpbx/pages/settings"),
    children: [
      {
        path: "domains",
        name: "Domains",
        component: () => import("@/modules/xpbx/pages/settings/domains"),
      },
      {
        path: "users",
        name: "Users",
        component: () => import("@/modules/xpbx/pages/settings/users"),
      },
      {
        path: "timezones",
        name: "Timezones",
        component: () => import("@/modules/xpbx/pages/settings/timezones"),
      },
      {
        path: "sound-files",
        name: "SoundFiles",
        component: () => import("@/modules/xpbx/pages/settings/sound-files"),
      },
      {
        path: "did-numbers",
        name: "DidNumbers",
        component: () => import("@/modules/xpbx/pages/settings/did-numbers"),
      },
      {
        path: "extensions",
        name: "Extensions",
        component: () => import("@/modules/xpbx/pages/settings/extensions"),
      },
      {
        path: "ivrs",
        name: "IVRS",
        component: () => import("@/modules/xpbx/pages/settings/ivrs"),
      },
      {
        path: "ring-groups",
        name: "RingGroups",
        component: () => import("@/modules/xpbx/pages/settings/ring-groups"),
      },
      {
        path: "queues",
        name: "Queue",
        component: () => import("@/modules/xpbx/pages/settings/queue"),
      },
      {
        path: "queue/:id",
        name: "QueueDetail",
        component: () => import("@/modules/xpbx/pages/settings/queue-detail"),
      },
      {
        path: "voicemail",
        name: "VoiceMail",
        component: () => import("@/modules/xpbx/pages/settings/voicemail"),
      },
      {
        path: "settings",
        name: "SettingsPage",
        component: () => import("@/modules/xpbx/pages/settings/settings"),
      },
      {
        path: "blacklist",
        name: "Blacklist",
        component: () => import("@/modules/xpbx/pages/settings/blacklist"),
      },
      // {
      //   path: "queues",
      //   name: "Queues",
      //   component: () => import("@/modules/xpbx/pages/settings/queues"),
      // },
      {
        path: "create-domain",
        name: "CreateDomain",
        component: () => import("@/modules/xpbx/pages/settings/create-domain"),
      },
      {
        path: "templates",
        name: "Templates",
        component: () => import("@/modules/xpbx/pages/settings/templates"),
      },
      {
        path: "contacts",
        name: "SettingsContact",
        component: () => import("@/modules/xpbx/pages/settings/contacts"),
      },
      {
        path: "senders",
        name: "ViberSenders",
        component: () => import("@/modules/xpbx/pages/settings/viber-senders"),
      },
    ],
    meta: {
      requiresAuth: true,
      verified: true,
    },
  },
];
export default routes;
