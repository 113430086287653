<template>
  <div class="bg-white w-full">
    <section class="flex flex-row flex-wrap w-full">
      <div class="flex flex-col justify-center items-center mx-auto">
        <div class="mb-1 font-bold text-3xl">
          <p>
            {{ $t('label.profile.profileinfo-pwd.top.profile-info.title') }}
          </p>
        </div>
        <div class="mb-1 text-lg">
          <p>{{ $t('label.profile.profileinfo-pwd.top.profile-info.desc') }}</p>
        </div>
      </div>

      <div class="flex flex-col 2xl:flex-row flex-wrap w-full">
        <div class="flex-1" v-if="user_info && user_info.personal_info">
          <div
            class="m-5 pb-6 rounded-sm border-b-1 border-r-3 border-gray-500 bg-white shadow-md"
          >
            <div class="flex flex-row flex-wrap p-5 items-center">
              <div class="">
                <img
                  v-if="photoPreview"
                  class="w-14 h-14 object-cover rounded-full"
                  :src="photoPreview"
                  alt="profile-image"
                />

                <img
                  v-else-if="
                    !photoPreview &&
                    user_info.personal_info &&
                    user_info.personal_info.user_photo
                  "
                  class="w-14 h-14 object-cover rounded-full"
                  :src="user_info.personal_info.user_photo"
                  alt="profile-image"
                />

                <img
                  v-else
                  class="w-14 h-14 object-cover rounded-full"
                  src="@/assets/images/profile_section/user.jpg"
                  alt=""
                />

                <a
                  @click="selectNewImage('photo')"
                  class="inline-flex w-8 h-8 rounded-full relative z-1 -mt-12 -mr-14 bg-light-green cursor-pointer"
                >
                  <img
                    class="ml-1 w-6 h-auto"
                    src="@/assets/images/profile_section/edit.svg"
                    alt=""
                  />
                </a>
              </div>

              <input
                type="file"
                class="hidden"
                ref="photo"
                @change="updateImagePreview('photo')"
              />
              <div class="pl-5">
                <p>
                  {{
                    $t(
                      'label.profile.profileinfo-pwd.top.personal-info.change-photo'
                    )
                  }}
                </p>
              </div>
              <div class="flex justify-end w-3/5 xl:ml-5 xl:pb-10">
                <div>
                  <p class="font-bold text-md">
                    {{
                      $t(
                        'label.profile.profileinfo-pwd.top.personal-info.top-right-label'
                      )
                    }}
                  </p>
                </div>
              </div>
            </div>

            <div class="font-sans px-8 py-3 text-left">
              <form
                @input="removeError($event.target.name)"
                @change="removeError($event.target.name)"
              >
                <FormInput
                  class="form-group pb-3"
                  name="name"
                  v-model="user_info.personal_info.name"
                  placeholder=""
                  :footerLabel="
                    $t(
                      'label.profile.profileinfo-pwd.body.personal-info.username'
                    )
                  "
                  :errors="errors.name"
                />

                <FormInput
                  class="form-group pb-3"
                  name="email"
                  v-model="user_info.personal_info.email"
                  placeholder="email@gmail.com"
                  :footerLabel="
                    $t('label.profile.profileinfo-pwd.body.personal-info.email')
                  "
                  :errors="errors.email"
                />

                <FormInput
                  class="form-group pb-3"
                  name="user_phonenumber"
                  v-model="user_info.personal_info.user_phonenumber"
                  :placeholder="
                    $t(
                      'label.profile.profileinfo-pwd.body.personal-info.phonenum-placeholder'
                    )
                  "
                  :footerLabel="
                    $t(
                      'label.profile.profileinfo-pwd.body.personal-info.phonenum'
                    )
                  "
                  :errors="errors.user_phonenumber"
                  number
                  maxlength="20"
                />

                <Timezones
                  v-model="user_info.personal_info.timezone"
                  :showTitle="false"
                  label="label.app.common.timezone_select"
                />

                <SelectWithSearch
                  v-model="user_info.personal_info.user_lang"
                  :options="userFormattedLangs"
                  name="language"
                  id="language"
                  :errors="errors.language"
                  :footerLabel="
                    $t(
                      'label.profile.profileinfo-pwd.body.personal-info.language'
                    )
                  "
                />

                <div class="flex flex-row-reverse items-end flew-wrap mt-6">
                  <button
                    :disabled="savingProfile"
                    class="bg-dark-green text-gray-200 p-1 px-10 text-center transition ease-in duration-100 hover:opacity-90 h-10 rounded-md"
                    @click.prevent="saveProfileInfo('personal')"
                    :class="{ 'cursor-not-allowed': savingProfile }"
                  >
                    {{ $t('button.label.save-changes') }}
                    <font-awesome-icon
                      icon="circle-notch"
                      class="fa-spin"
                      v-show="savingProfile"
                    />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div class="flex-1">
          <div
            class="m-5 pb-6 rounded-sm border-b-1 border-r-3 border-gray-500 bg-white shadow-md"
            v-if="user_info.company_info"
          >
            <div class="flex flex-row flex-wrap p-5 items-center">
              <div class="">
                <img
                  v-if="logoPreview"
                  class="w-14 h-14 object-cover rounded-full"
                  :src="logoPreview"
                  alt=""
                />
                <img
                  v-if="!logoPreview && user_info.company_info.logo_path"
                  class="w-14 h-14 object-cover rounded-full"
                  :src="user_info.company_info.logo_path"
                  alt=""
                />
                <img
                  v-if="!logoPreview && !user_info.company_info.logo_path"
                  class="w-14 h-14 object-cover rounded-full"
                  src="@/assets/images/profile_section/profile-support.svg"
                  alt=""
                />
                <a
                  @click="selectNewImage('logo')"
                  class="inline-flex w-8 h-8 rounded-full relative z-1 -mt-12 -mr-14 bg-light-green cursor-pointer"
                >
                  <img
                    class="ml-1 w-6 h-auto"
                    src="@/assets/images/profile_section/edit.svg"
                    alt=""
                  />
                </a>
              </div>

              <input
                type="file"
                class="hidden"
                ref="logo"
                @change="updateImagePreview('logo')"
              />
              <div class="pl-5">
                <p>
                  {{
                    $t(
                      'label.profile.profileinfo-pwd.top.company-info.change-logo'
                    )
                  }}
                </p>
              </div>
              <div
                class="flex justify-end ml-5 w-2/5 xl:w-1/2 xl:ml-5 xl:pb-10"
              >
                <div>
                  <p class="font-bold text-md">
                    {{
                      $t(
                        'label.profile.profileinfo-pwd.top.company-info.top-right-label'
                      )
                    }}
                  </p>
                </div>
              </div>
            </div>

            <div class="font-sans xl:px-8 xl:py-3 text-left">
              <form
                @change="removeError($event.target.name)"
                @input="removeError($event.target.name)"
              >
                <div class="p-3 xl:p-0" v-if="user_info">
                  <FormInput
                    class="form-group pb-3"
                    name="company_name"
                    v-model="user_info.company_info.company_name"
                    :placeholder="
                      $t(
                        'label.profile.profileinfo-pwd.body.company-info.companyname-placeholder'
                      )
                    "
                    :footerLabel="
                      $t(
                        'label.profile.profileinfo-pwd.body.company-info.companyname'
                      )
                    "
                    :errors="errors.company_name"
                  />

                  <FormInput
                    class="form-group pb-3"
                    name="vat_number"
                    type="text"
                    v-model="user_info.company_info.vat_number"
                    :placeholder="
                      $t(
                        'label.profile.profileinfo-pwd.body.company-info.vatnum-placeholder'
                      )
                    "
                    :footerLabel="
                      $t(
                        'label.profile.profileinfo-pwd.body.company-info.vatnum'
                      )
                    "
                    :errors="errors.vat_number"
                  />

                  <FormInput
                    class="form-group pb-3"
                    name="company_address"
                    type="text"
                    v-model="user_info.company_info.company_address"
                    :placeholder="
                      $t(
                        'label.profile.profileinfo-pwd.body.company-info.companyaddress-placeholder'
                      )
                    "
                    :footerLabel="
                      $t(
                        'label.profile.profileinfo-pwd.body.company-info.companyaddress'
                      )
                    "
                    :errors="errors.company_address"
                  />

                  <FormInput
                    class="form-group pb-3"
                    name="company_email"
                    type="text"
                    v-model="user_info.company_info.company_email"
                    :placeholder="
                      $t(
                        'label.profile.profileinfo-pwd.body.company-info.companyemail-placeholder'
                      )
                    "
                    :footerLabel="
                      $t(
                        'label.profile.profileinfo-pwd.body.company-info.companyemail'
                      )
                    "
                    :errors="errors.company_email"
                  />

                  <SelectWithSearch
                    v-model="user_info.company_info.company_country"
                    :options="companyCountries"
                    name="company_country"
                    id="company_country"
                    :errors="errors.company_country"
                    :footerLabel="
                      $t(
                        'label.profile.profileinfo-pwd.body.company-info.country'
                      )
                    "
                  />

                  <FormInput
                    class="form-group pb-3"
                    name="company_manager"
                    type="text"
                    v-model="user_info.company_info.company_manager"
                    :placeholder="
                      $t(
                        'label.profile.profileinfo-pwd.body.company-info.manager-placeholder'
                      )
                    "
                    :footerLabel="
                      $t(
                        'label.profile.profileinfo-pwd.body.company-info.manager'
                      )
                    "
                    :errors="errors.company_manager"
                  />

                  <div class="flex flex-row-reverse items-end flew-wrap">
                    <button
                      :disabled="savingCompany"
                      @click.prevent="saveProfileInfo('company')"
                      class="bg-dark-green text-gray-200 p-1 px-10 text-center transition ease-in duration-100 hover:opacity-90 h-10 rounded-md"
                      :class="{ 'cursor-not-allowed': savingCompany }"
                    >
                      {{ $t('button.label.save-changes') }}
                      <font-awesome-icon
                        icon="circle-notch"
                        class="fa-spin"
                        v-show="savingCompany"
                      />
                    </button>

                    <button
                      class="bg-white text-dark-green border-2 border-dark-green mr-3 p-1 px-10 text-center transition ease-in duration-100 hover:opacity-90 h-10 rounded-md"
                    >
                      {{ $t('button.label.cancel') }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-row flex-wrap w-full">
        <form
          @input="removeError($event.target.name)"
          @change="removeError($event.target.name)"
        >
          <div
            class="m-5 pb-8 rounded-sm border-b-1 border-r-3 border-gray-500 bg-white shadow-md w-full"
          >
            <div class="flex flex-row flex-wrap justify-between pb-3">
              <div>
                <FormInput
                  type="password"
                  classes="form-group px-5 flex flex-col items-start"
                  name="user_old_pwd"
                  v-model="user_old_pwd"
                  :placeholder="
                    $t(
                      'label.profile.profileinfo-pwd.bottom.oldpwd-placeholder'
                    )
                  "
                  :footerLabel="
                    $t('label.profile.profileinfo-pwd.bottom.oldpwd')
                  "
                  :errors="errors.user_old_pwd"
                />
              </div>
            </div>

            <div class="flex flex-row flex-wrap justify-between">
              <div class="flex flex-row">
                <FormInput
                  type="password"
                  classes="px-1 2xl:px-5 flex flex-col items-start padding"
                  name="user_new_pwd"
                  v-model="user_new_pwd"
                  :placeholder="
                    $t(
                      'label.profile.profileinfo-pwd.bottom.newpwd-placeholder'
                    )
                  "
                  :footerLabel="
                    $t('label.profile.profileinfo-pwd.bottom.newpwd')
                  "
                  :errors="errors.user_new_pwd"
                />

                <FormInput
                  type="password"
                  classes="px-1 2xl:px-5 flex flex-col items-start"
                  name="user_retype_pwd"
                  v-model="user_retype_pwd"
                  :placeholder="
                    $t(
                      'label.profile.profileinfo-pwd.bottom.retypepwd-placeholder'
                    )
                  "
                  :footerLabel="
                    $t('label.profile.profileinfo-pwd.bottom.retypepwd')
                  "
                  :errors="errors.user_retype_pwd"
                />
              </div>
            </div>

            <div class="flex pl-4 gap-2 mb-1 mt-5">
              <button
                class="bg-dark-green text-gray-200 p-1 px-10 text-center transition ease-in duration-100 hover:opacity-90 h-10 rounded-md"
                @click.prevent="saveProfileInfo('password')"
                :disabled="!user_retype_pwd || !user_new_pwd || loading"
                :class="{
                  'cursor-not-allowed':
                    loading || !user_retype_pwd || !user_new_pwd
                }"
              >
                {{ $t('button.label.save-changes') }}
                <font-awesome-icon
                  icon="circle-notch"
                  class="fa-spin"
                  v-show="loading"
                />
              </button>
              <button
                class="bg-white text-dark-green border-2 border-dark-green mr-3 p-1 px-10 text-center transition ease-in duration-100 hover:opacity-90 h-10 rounded-md"
              >
                {{ $t('button.label.cancel') }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { ref, computed, inject, watch, onBeforeMount } from 'vue'

import Timezones from '@/shared/Timezones'

import useCountries from '@/modules/widget/composables/widget/useCountries'
import validator from '@/composables/auth/validator'

import { isPossiblePhoneNumber } from 'libphonenumber-js'

export default {
  name: 'ProfileInformation',

  components: {
    Timezones
  },

  setup () {
    const store = useStore()
    const notify = inject('notify')
    const t = inject('t')
    const savingProfile = ref(false)
    const savingCompany = ref(false)
    const loading = ref(false)

    const { locale } = {
      useScope: 'global'
    }
    const { countries } = useCountries()
    const { errors, removeError, clearErrors, setErrors } = validator()

    const assetPath = computed(() => {
      if (process.env.ASSET_URL != undefined + '/') {
        return process.env.ASSET_URL
      } else {
        return '/'
      }
    })

    const bgImage = computed(() => {
      return {
        backgroundImage: `url(${require('@/assets/images/profile_section/bg-profile.svg')})`,
        backgroundSize: 'cover'
      }
    })

    const user_info = computed(() => store.state.profilestore.personal_info)
    const globalNotify = computed(() => store.state.profilestore.globalNotify)

    const storeSavePersonalInfo = params => {
      if (savingProfile.value) return

      //TODO BUUUUUUG GOLQM TRQBVA DA RABOTI ZA WSICHKI DYRJAVI
      if (!isPossiblePhoneNumber(params.user_phonenumber)) {
        notify({
          message: t('valiation.pages.common.phone_number_incorrect'),
          type: 'danger'
        })
        setErrors({
          key: 'user_phonenumber',
          message: t('valiation.pages.common.phone_number_incorrect')
        })
        return
      }

      savingProfile.value = true

      store
        .dispatch('profilestore/saveAccountProfile', { params })
        .then(() => {
          savingProfile.value = false

          notify({
            message: t('notify.profile_information.update_company'),
            type: 'success'
          })
        })
        .catch(err => {
          savingProfile.value = false

          notify({
            message: t('valiation.pages.auth.resolve_errors'),
            type: 'danger'
          })
        })
    }

    const storeSaveCompanyInfo = params => {
      if (savingCompany.value) return

      savingCompany.value = true

      store
        .dispatch('profilestore/storeSaveCompanyInfo', params)
        .then(() => {
          savingCompany.value = false

          notify({
            message: t('notify.profile_information.update_company'),
            type: 'success'
          })
        })
        .catch(err => {
          savingCompany.value = false

          notify({
            message: t('notify.profile_information.fix_errors'),
            type: 'danger'
          })
        })
    }

    const storeSavePasswordInfo = async params => {
      if (params.user_new_pwd !== params.user_retype_pwd) {
        notify({
          message: t('notify.profile_information.password_error'),
          type: 'danger'
        })
        return
      }

      try {
        loading.value = true
        await store.dispatch('profilestore/storeSavePasswordInfo', params)

        notify({
          message: t('notify.pages.resetPassword.password_saved'),
          type: 'success'
        })

        return Promise.resolve()
      } catch (e) {
        notify({
          message: t('notify.profile_information.fix_errors'),
          type: 'danger'
        })
        throw e
      } finally {
        loading.value = false
      }
    }

    watch(globalNotify, () => {
      notify({
        message: globalNotify.value.message,
        type: globalNotify.value.type
      })
    })

    watch(
      user_info,
      // user => {
      //   locale.value = user.personal_info.user_lang
      // },
      {
        deep: true
      }
    )

    onBeforeMount(() => {
      clearErrors()
    })

    return {
      errors,
      loading,
      assetPath,
      bgImage,
      user_info,
      globalNotify,
      storeSavePersonalInfo,
      storeSaveCompanyInfo,
      storeSavePasswordInfo,
      savingProfile,
      savingCompany,
      countries,
      removeError
    }
  },

  data () {
    return {
      personal_user_timezone: '',
      personal_user_lang: '',
      company_user_manager: '',
      company_user_country: '',
      user_old_pwd: '',
      user_new_pwd: '',
      user_retype_pwd: '',
      // photoPreview: this.user_info.personal_info.user_photo,
      photoPreview: null,
      logoPreview: null,
      officeTimezone: ['UTC +2(Sofia Talin)', 'UTC +3(Wood Holly)'],
      userLang: {
        en: 'English'
      },
      userLangDropdown: [
        {
          code: 'en',
          name: 'English'
        }
      ]
    }
  },

  computed: {
    companyCountries () {
      return this.countries.map(country => {
        return {
          name: country.name,
          value: country.name
        }
      })
    },

    userFormattedLangs () {
      const langs = []

      for (const [key, name] of Object.entries(this.userLang)) {
        langs.push({
          name,
          value: key
        })
      }

      return langs
    }
  },

  methods: {
    selectNewImage (type) {
      if (type == 'photo') {
        this.$refs.photo.click()
      } else {
        this.$refs.logo.click()
      }
    },

    updateImagePreview (type) {
      const reader = new FileReader()
      reader.onload = e => {
        if (type == 'photo') {
          this.photoPreview = e.target.result
        } else {
          this.logoPreview = e.target.result
        }
      }
      if (type == 'photo') {
        if (this.$refs.photo.files[0]) {
          reader.readAsDataURL(this.$refs.photo.files[0])
          this.updatePhoto(type)
        }
      } else {
        if (this.$refs.logo.files[0]) {
          reader.readAsDataURL(this.$refs.logo.files[0])
          this.updatePhoto(type)
        }
      }
    },

    updatePhoto (value) {
      if (this.$refs.photo && value == 'photo') {
        this.photoPreview = this.$refs.photo.files[0]
      } else if (this.$refs.logo && value == 'logo') {
        this.logoPreview = this.$refs.logo.files[0]
      }
    },

    async saveProfileInfo (type) {
      if (type == 'personal') {
        // let paramUserLang = _.invert(this.userLang)

        const params = {
          name: this.user_info.personal_info.name,
          email: this.user_info.personal_info.email,
          user_phonenumber: this.user_info.personal_info.user_phonenumber,
          balance: this.user_info?.personal_info?.balance || 0,
          timezone: this.user_info.personal_info.timezone,
          user_lang: this.user_info.personal_info.user_lang || null,
          user_photo: this.photoPreview
            ? this.photoPreview
            : this.user_info.personal_info.user_photo
        }

        this.$i18n.locale = this.user_info.personal_info.user_lang || null
        localStorage.setItem(
          'user_lang',
          this.user_info.personal_info.user_lang || null
        )
        this.storeSavePersonalInfo(params)
      } else if (type == 'company') {
        const params = {
          company_name: this.user_info.company_info.company_name,
          company_email: this.user_info.company_info.company_email,
          vat_number: this.user_info.company_info.vat_number,
          company_address: this.user_info.company_info.company_address,
          company_country: this.user_info.company_info.company_country,
          company_manager: this.user_info.company_info.company_manager,
          logo_path: this.logoPreview
            ? this.logoPreview
            : this.user_info.company_info.logo_path
        }

        this.storeSaveCompanyInfo(params)
      } else {
        const params = {
          user_old_pwd: this.user_old_pwd,
          user_new_pwd: this.user_new_pwd,
          user_retype_pwd: this.user_retype_pwd
        }

        try {
          await this.storeSavePasswordInfo(params)
          this.user_old_pwd = ''
          this.user_new_pwd = ''
          this.user_retype_pwd = ''
        } catch (e) {
          throw e
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.padding {
  padding-left: 20px;
}
</style>
