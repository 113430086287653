import i18nSetup from "@/services/i18nSetup";

import {
  SET_WIDGET,
  SET_WIDGETS,
  GET_WIDGETS,
  GET_TARIFFS,
  SET_TARIFFS,
  SAVE_CONTACT,
  RESET_PHONE,
  SAVE_PHONE,
  INSTALL_PHONE,
  EDIT_PHONE,
  EDIT_WIDGET,
  SET_ACTIVE_WIDGET,
  EDIT_CONTACT,
  SET_ACTIVE_RESOURCE,
  DELETE_CONTACT,
  SAVE_CONTACTS,
  SAVE_DEPARTMENT,
  DELETE_DEPARTMENT,
  EDIT_DEPARTMENT,
  RESET_RESOURCE,
  SET_LOADING,
  DELETE_PHONE,
  SET_LANG,
  SAVE_USER_LANGUAGE,
  SAVE_USER_EDITED_LANGS,
  DELETE_LANG,
  SAVE_MULTILANG,
  SAVE_DEFAULT_LANG,
  SAVE_SIDEBAR,
  SAVE_APPEARANCE,
  TOGGLE_WIDGET,
  RESET_COLOR_SCHEME,
  FETCH_SUBSCRIPTION_PLANS,
  SET_APPEARANCE_RESOURCE,
  RESET_APPEARANCE_COLOR_SCHEME,
  SAVE_COUNTRIES,
  SAVE_BLACKLIST_IPS,
} from "./types";

import lodash from "lodash";
import store from "@/store/index";
//import axios from "@/services/axios";

import api from "@/services/api";
import useWidgetConfig from "../composables/useWidgetConfig";

const { triggerAlert, updateContacts, triggerSuccess } = useWidgetConfig();
const { i18n } = i18nSetup();
const translate = i18n.global.t;

const saveContact = async (contacts) => {
  try {
    store.commit(`widget/${SET_LOADING}`);
    const { data } = await updateContacts({
      contacts,
      widgetId: store.getters["widget/activeWidgetId"],
    });
    store.commit(`widget/${SAVE_CONTACTS}`, {
      contacts: data,
      widgetIndex: store.getters["widget/activeWidgetIndex"],
    });
    store.commit(`widget/${SET_LOADING}`, { value: false });
    triggerSuccess(translate("notify.modules.widget.common.contact_saved"));
  } catch (e) {
    triggerAlert(e, translate("validation.main.pages.home.save_contact"));
    store.commit(`widget/${SET_LOADING}`, { value: false });
  }
};

export const widget = {
  namespaced: true,
  state: {
    widget: {
      id: null,
      name: "",
      timezone: "",
      is_active: true,
      plan: 0,
      plan_category: 0,
      contacts: [],
      sidebar: {
        colorScheme: "",
        position: "right",
        amimation: "ripple",
      },
      appearance: {
        bodyBackground: "",
        bodyColor: "",
        headerBackground: "",
        headerColor: "",
        headerIconBackground: "",
        headerIconColor: "",
        contactListBackground: "",
        contactListColor: "",
        onlineStatus: "",
        departmentBackground: "",
        departmentListBackground: "",
        departmentListColor: "",
        departmentListIcon: "",
        departmentListIconColor: "",
        departmentListActiveIcon: "",
        departmentListActiveIconColor: "",
        sidebarBackground: "",
        sidebarIconBackground: "",
        sidebarIconColor: "",
        dialerButtonBg: "",
        dialerButtonColor: "",
        callHangBg: "",
        callHangColor: "",
        submitButtonBg: "",
        submitButtonColor: "",
        widgetPosition: "right",
        chatVisibility: "on-desktop-and-mobile",
        displayOffline: false,
        offlineStatus: "",
      },
      multilanguage: {
        lang: "en",
        userLanguages: ["en"],
        userEditedLangs: {},
      },
      my_country: [],
      ip_blacklist: [],
    },
    widgets: [],
    activeWidgetId: -1,
    activeContactId: -1,
    activeDepartmentId: -1,
    loading: {
      value: false,
      id: "widget",
    },
    widgetStatus: false,
    appearanceColor: "",
    appearanceBackground: "",
  },

  getters: {
    loading: (state) => state.loading,
    widget: (state) => state.widget,
    widgets: (state) => state.widgets,
    activeWidgetId: (state) => state.activeWidgetId,
    activeWidgetIndex: (_, getters) => {
      return getters.widgets.length
        ? getters.widgets.findIndex(
            (widget) => widget.id == getters.activeWidgetId
          )
        : -1;
    },
    activeWidget: (_, getters) => {
      return getters.activeWidgetIndex != -1
        ? getters.widgets[getters.activeWidgetIndex]
        : {};
    },
    activeContactIndex: (state, getters) => {
      return state.activeContactId != -1
        ? getters.activeWidget.contacts.findIndex(
            (contact) => contact.id == state.activeContactId
          )
        : -1;
    },
    activeContact: (state, getters) => {
      return getters.activeContactIndex != -1
        ? getters.activeWidget.contacts[getters.activeContactIndex]
        : {};
    },
    departmentIndex: (state, getters) => {
      return getters.activeContactIndex != -1
        ? getters.activeContact.departments.findIndex(
            (department) => department.id == state.activeDepartmentId
          )
        : -1;
    },
    department: (_, getters) => {
      return getters.departmentIndex != -1
        ? getters.activeContact.departments[getters.departmentIndex]
        : {};
    },
    multiLang: (_, getters) => getters.activeWidget.multilanguage,
    multilanguage: (state) => state.widget.multilanguage,
    sidebar: (_, getters) => getters.activeWidget.sidebar,
    appearance: (_, getters) => getters.activeWidget.appearance,
    widgetStatus: (state) => state.widgetStatus,
    appearanceColor: (state) => state.appearanceColor,
    appearanceBackground: (state) => state.appearanceBackground,
  },

  mutations: {
    [SET_LOADING](state, loading = {}) {
      const id = "id" in loading ? loading.id : "widget";
      const value = "value" in loading ? loading.value : true;
      state.loading.value = value;
      state.loading.id = id;
    },
    [SET_WIDGETS](state, data) {
      state.widgets = data;
    },
    [SET_WIDGET](state, data) {
      state.widgets.push(data);
    },
    [SAVE_CONTACT](state, { contact, widgetIndex }) {
      state.widgets[widgetIndex].contacts.push(contact);
    },
    [RESET_PHONE](state) {
      state.widget.name = "";
      state.widget.timezone = "";
      state.widget.active = "";
      state.widget.plan = 0;
      state.widget.plan_category = 0;
    },
    [SAVE_PHONE](state, phone) {
      state.widget.name = phone.name;
      state.widget.timezone = phone.timezone;
      state.widget.is_active = phone.is_active;
      state.widget.plan = phone.plan;
      state.widget.billing_period = phone.billing_period;
      state.widget.plan_category = phone.plan_category;
    },
    [DELETE_PHONE](state, phoneIndex) {
      state.widgets.splice(phoneIndex, 1);
      state.activeWidgetId = -1;
    },
    [EDIT_WIDGET](state, { widget, widgetIndex }) {
      state.widgets[widgetIndex] = widget;
    },
    [SET_ACTIVE_WIDGET](state, widgetId) {
      state.activeWidgetId = widgetId;
    },
    [EDIT_CONTACT](state, { contactIndex, widgetIndex, editedContact }) {
      state.widgets[widgetIndex].contacts[contactIndex] = {
        ...editedContact,
      };
    },
    [SET_ACTIVE_RESOURCE](state, resource) {
      if ("contactId" in resource) state.activeContactId = resource.contactId;
      if ("departmentId" in resource)
        state.activeDepartmentId = resource.departmentId;
    },
    [SAVE_CONTACTS](state, { widgetIndex, contacts }) {
      state.widgets[widgetIndex].contacts = contacts;
    },
    [SAVE_DEPARTMENT](state, { widgetIndex, contactIndex, department }) {
      state.widgets[widgetIndex].contacts[contactIndex].departments.push(
        department
      );
      state.activeDepartmentId = department.id;
    },
    [DELETE_DEPARTMENT](state, { widgetIndex, contactIndex, deparmentIndex }) {
      state.widgets[widgetIndex].contacts[contactIndex].departments.splice(
        deparmentIndex,
        1
      );
    },
    [RESET_RESOURCE](state) {
      state.activeContactId = -1;
      state.activeDepartmentId = -1;
    },
    [SAVE_USER_LANGUAGE](state, { widgetIndex, lang }) {
      const langs = state.widgets[widgetIndex].multilanguage.userLanguages;
      if (!langs.includes(lang)) langs.push(lang);
    },
    [SAVE_USER_EDITED_LANGS](state, { key, translations, widgetIndex }) {
      state.widgets[widgetIndex].multilanguage.userEditedLangs = {
        ...state.widgets[widgetIndex].multilanguage.userEditedLangs,
        [key]: translations,
      };
    },
    [SET_LANG](state, { newLang, widgetIndex }) {
      state.widgets[widgetIndex].multilanguage.lang = newLang;
    },
    [DELETE_LANG](state, { index, widgetIndex }) {
      state.widgets[widgetIndex].multilanguage.userLanguages.splice(index, 1);
    },
    [SAVE_MULTILANG](state, { data, widgetIndex }) {
      state.widgets[widgetIndex].multilanguage = data;
    },
    [TOGGLE_WIDGET](state, value) {
      state.widgetStatus = value;
    },
    [RESET_COLOR_SCHEME](
      state,
      { value, resource = "sidebar", widgetIndex, prop = null }
    ) {
      if (resource == "appearance") {
        state.widgets[widgetIndex][resource][prop] = value;
      } else {
        state.widgets[widgetIndex][resource].colorScheme = value;
      }
    },
    [RESET_APPEARANCE_COLOR_SCHEME](state, { data, widgetIndex }) {
      for (let { name, default: defaultValue, value } of data) {
        state.widgets[widgetIndex].appearance[value] = defaultValue;
      }
    },
    [SET_APPEARANCE_RESOURCE](state, { resource, value }) {
      state[resource] = value;
    },
  },
  actions: {
    async [GET_WIDGETS]({ commit, dispatch }) {
      try {
        commit(SET_LOADING);
        const { data } = await api.get("/admin/widgets");
        if (data.length) await commit(SET_WIDGETS, data);
        const activeWidget = data.find((widget) => widget.default == true);
        if (!lodash.isEmpty(activeWidget)) {
          commit(SET_ACTIVE_WIDGET, activeWidget.id);
        } else if (data.length && lodash.isEmpty(activeWidget)) {
          dispatch(SET_ACTIVE_WIDGET, data[0].id);
        }
        commit(SET_LOADING, { value: false });
      } catch (e) {
        triggerAlert(e, translate("validation.main.pages.home.get_widgets"));
        commit(SET_LOADING, { value: false });
      }
    },

    async [INSTALL_PHONE]({ commit }, data) {
      try {
        const response = await baseAxios.post(
          `http://football.gpb/wp-json/phone-iframe/v1/install`,
          data.data
        );
        if (response.status === 200)
          triggerSuccess(translate("notify.modules.widget.common.instalation"));
      } catch (e) {
        triggerAlert(e, translate("validation.main.pages.home.save_phone"));
        commit(SET_LOADING, { value: false });
      }
    },

    async [SAVE_PHONE]({ commit, state }, { phone, config }) {
      const phoneData = {
        id: null,
        name: phone.name,
        is_active: phone.is_active,
        contacts: phone.contacts,
        plan: phone.plan,
        config,
        appearance: { ...state.widget.appearance },
        sidebar: { ...state.widget.sidebar },
        multilanguage: { ...state.widget.multilanguage },
        my_country: state.widget.my_country,
        ip_blacklist: state.widget.ip_blacklist,
      };

      try {
        await commit(SAVE_PHONE, phone);
        commit(SET_LOADING);

        // Da se mahne zaqwkata sled syzdaden widget da se puska nova zaqvka
        const { data } = await api.post("/admin/widget", phoneData);
        if (!lodash.isEmpty(data)) {
          commit(SET_WIDGET, data);
          triggerSuccess("Widget data saved!");
        }
        commit(SET_LOADING, { value: false });
      } catch (e) {
        triggerAlert(e, translate("validation.main.pages.home.save_phone"));
        commit(SET_LOADING, { value: false });
      }
    },

    async [EDIT_PHONE]({ getters, commit }, { phone, config }) {
      let widget = lodash.cloneDeep(getters.activeWidget);
      widget = {
        ...widget,
        ...phone,
        config,
      };
     
      try {
        commit(SET_LOADING);
        const { data } = await api.put(`/admin/widget/${widget.id}`, widget);
        commit(EDIT_WIDGET, {
          widget: data,
          widgetIndex: getters.activeWidgetIndex,
        });
        commit(SET_LOADING, { value: false });
        triggerSuccess("Widget data saved!");
      } catch (e) {
        triggerAlert(e, translate("validation.main.pages.home.save_phone"));
        commit(SET_LOADING, { value: false });
      }
    },

    async [DELETE_PHONE]({ commit, state }, phone) {
      const widgetIndex = state.widgets.findIndex(
        (widget) => widget.id == phone.id
      );

      if (widgetIndex == -1)
        triggerAlert(
          {},
          translate("validation.main.pages.home.no_found_found")
        );

      try {
        commit(SET_LOADING);
        const { data } = await api.delete(`/admin/widget/${phone.id}`);
        commit(DELETE_PHONE, widgetIndex);
        if(data?.length) {
          const activeWidget = data.find((widget) => widget.default == true);
          // dispatch(SET_ACTIVE_WIDGET, activeWidget ? activeWidget.id : data[0].id);
          commit(SET_ACTIVE_WIDGET, activeWidget ? activeWidget.id : data[0].id);
        }
        
        commit(SET_LOADING, { value: false });
        triggerSuccess("Phone deleted!");
      } catch (e) {
        triggerAlert(e, translate("validation.main.pages.home.save_phone"));
        commit(SET_LOADING, { value: false });
      }
    },

    async [SAVE_CONTACT]({ commit, state, getters }, contact) {
      if (!state.widgets.length) {
        triggerAlert(
          {},
          translate("validation.main.pages.home.no_found_found")
        );
        return;
      }

      if (getters.activeWidgetId == -1) {
        triggerAlert(
          {},
          translate("validation.main.pages.home.no_found_selected")
        );
        return;
      }

      const contacts = lodash.cloneDeep(getters.activeWidget.contacts);
      contacts.push(contact);

      saveContact(contacts);
    },

    async [EDIT_CONTACT]({ commit, state, getters }, editedContact) {
      const contacts = lodash.cloneDeep(getters.activeWidget.contacts);
      contacts[getters.activeContactIndex] = editedContact;

      saveContact(contacts);
    },

    async [DELETE_CONTACT]({ commit, state, getters }, id) {
      const contacts = lodash.cloneDeep(getters.activeWidget.contacts);
      const contactIndex = contacts.findIndex((contact) => contact.id == id);
      contacts.splice(contactIndex, 1);

      saveContact(contacts);
    },

    async [SAVE_DEPARTMENT]({ state, getters, commit }, department) {
      const contacts = lodash.cloneDeep(getters.activeWidget.contacts);
      contacts[getters.activeContactIndex].departments.push(department);

      saveContact(contacts);
    },

    async [EDIT_DEPARTMENT]({ state, getters, commit }, department) {
      const contacts = lodash.cloneDeep(getters.activeWidget.contacts);
      contacts[getters.activeContactIndex].departments[
        getters.departmentIndex
      ] = department;

      saveContact(contacts);
    },

    async [DELETE_DEPARTMENT]({ state, getters, commit }) {
      const contacts = lodash.cloneDeep(getters.activeWidget.contacts);
      contacts[getters.activeContactIndex].departments.splice(
        [getters.departmentIndex],
        1
      );

      saveContact(contacts);
    },

    [SET_LANG]({ getters, commit }, lang) {
      commit(SET_LANG, {
        newLang: lang,
        widgetIndex: getters.activeWidgetIndex,
      });
    },

    async [SAVE_USER_LANGUAGE]({ getters, commit }, lang) {
      await commit(SAVE_USER_LANGUAGE, {
        widgetIndex: getters.activeWidgetIndex,
        lang,
      });

      commit(SET_LOADING, { id: "addLang" });

      try {
        const { data } = await api.post(
          "/admin/widget/languages/" + getters.activeWidgetId,
          {
            multilanguage: getters.multiLang,
          }
        );
        commit(SET_LOADING, { value: false });
      } catch (e) {
        const index = getters.multiLang.userLanguages.findIndex(
          (lang) => lang == getters.multiLang.lang
        );
        commit(DELETE_LANG, { index, widgetIndex: getters.activeWidgetIndex });
        commit(SET_LOADING, { value: false });
        triggerAlert(e, translate("validation.main.pages.lang.save_lang"));
      }
    },

    async [SAVE_USER_EDITED_LANGS]({ getters, commit }, { key, translations }) {
      const multiLang = lodash.cloneDeep(getters.multiLang);
      await commit(SAVE_USER_EDITED_LANGS, {
        key,
        translations,
        widgetIndex: getters.activeWidgetIndex,
      });
      try {
        commit(SET_LOADING, { id: "multiLang" });
        const { data } = await api.put(
          "/admin/widget/languages/" + getters.activeWidgetId,
          {
            multilanguage: getters.multiLang,
          }
        );
        commit(SAVE_MULTILANG, {
          data,
          widgetIndex: getters.activeWidgetIndex,
        });
        commit(SET_LOADING, { value: false });
      } catch (e) {
        commit(SAVE_MULTILANG, {
          data: multiLang,
          widgetIndex: getters.activeWidgetIndex,
        });
        commit(SET_LOADING, { value: false });
        triggerAlert(e, translate("validation.main.pages.lang.save_lang"));
      }
    },

    async [DELETE_LANG]({ commit, getters }, index) {
      const multiLang = lodash.cloneDeep(getters.multiLang);
      const userLang = multiLang.userLanguages[index];
      multiLang.userLanguages.splice(index, 1);

      multiLang.userLanguages.length
        ? (multiLang.lang = multiLang.userLanguages[0])
        : (multiLang.lang = "en");

      if (multiLang.userEditedLangs[userLang])
        delete multiLang.userEditedLangs[userLang];

      try {
        commit(SET_LOADING, { id: "multiLang" });
        const { data } = await api.post(
          "/admin/widget/languages/" + getters.activeWidgetId,
          {
            multilanguage: multiLang,
          }
        );
        commit(SAVE_MULTILANG, {
          data,
          widgetIndex: getters.activeWidgetIndex,
        });
        commit(SET_LOADING, { value: false });
      } catch (e) {
        commit(SET_LOADING, { value: false });
        triggerAlert(e, translate("validation.main.pages.lang.save_lang"));
      }
    },

    async [SAVE_DEFAULT_LANG]({ commit, getters, dispatch }, lang) {
      const multiLang = lodash.cloneDeep(getters.multiLang);
      await dispatch(SET_LANG, lang);
      try {
        commit(SET_LOADING, { id: "multiLang" });
        const { data } = await api.patch(
          "/admin/widget/languages/" + getters.activeWidgetId,
          {
            multilanguage: getters.multiLang,
          }
        );
        commit(SET_LOADING, { value: false });
        commit(SAVE_MULTILANG, {
          data,
          widgetIndex: getters.activeWidgetIndex,
        });
      } catch (e) {
        dispatch(SET_LANG, multiLang.lang);
        commit(SET_LOADING, { value: false });
        triggerAlert(e, translate("validation.main.pages.lang.save_lang"));
      }
    },

    async [SAVE_SIDEBAR]({ commit, getters }) {
      try {
        commit(SET_LOADING, { id: "sidebar" });
        await api.put("/admin/widget/sidebar/" + getters.activeWidgetId, {
          sidebar: getters.activeWidget.sidebar,
        });
        commit(SET_LOADING, { value: false });
      } catch (e) {
        commit(SET_LOADING, { value: false });
        triggerAlert(
          e,
          translate("notify.modules.widget.components.sidebar.save_error")
        );
      }
    },

    async [SAVE_APPEARANCE]({ commit, getters }) {
      try {
        commit(SET_LOADING, { id: "appearance" });
        await api.put("/admin/widget/appearance/" + getters.activeWidgetId, {
          appearance: getters.appearance,
        });
        commit(SET_LOADING, { value: false });
      } catch (e) {
        commit(SET_LOADING, { value: false });
        triggerAlert(
          e,
          translate("notify.modules.widget.components.appearance.save_error")
        );
      }
    },

    async [SET_ACTIVE_WIDGET]({ commit, getters }, id) {
      commit(SET_LOADING, { id: "set-active", value: true });
      commit(SET_ACTIVE_WIDGET, id);
      try {
        if (id == null) return;
        await api.post(`/admin/widget/active/${id}`);
        commit(SET_LOADING, { id: "set-active", value: false });
      } catch (e) {
        commit(SET_LOADING, { id: "set-active", value: false });
        throw e;
      }
    },

    async [SAVE_COUNTRIES]({ getters, commit }, countries) {
      commit(SET_LOADING, { id: "save-countries", value: true });
      try {
        await api.put("/admin/widget/countries/" + getters.activeWidgetId, {
          countries,
        });
        commit(SET_LOADING, { id: "save-countries", value: false });
      } catch (e) {
        commit(SET_LOADING, { id: "save-countries", value: false });
        throw e;
      }
    },

    async [SAVE_BLACKLIST_IPS]({ getters, commit }, ips) {
      commit(SET_LOADING, { id: "save-ips", value: true });
      try {
        await api.put("/admin/widget/blacklist/" + getters.activeWidgetId, {
          ips,
        });
        commit(SET_LOADING, { id: "save-ips", value: false });
      } catch (e) {
        commit(SET_LOADING, { id: "save-ips", value: false });
        throw e;
      }
    },
  },
};
