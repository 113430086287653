import { isLoggedIn } from "@/composables/auth/authenticate";

const authRoutes = ["home"];
const guestRoutes = ["login", "register", "forgot-password", ""];
const guestPages = [
  "/",
  "/home",
  "/about",
  "/configuration",
  "/settings",
  "/reset-password",
];

export function canAuthAccess(to) {
  to = validateTo(to);
  return authRoutes.includes(to) && isLoggedIn();
}

export function canGuestAccess(to) {
  to = validateTo(to);
  return guestRoutes.includes(to) && !isLoggedIn();
}

export function checkGuestPages(href) {
  let isGuest = false;

  for (const path of guestPages) {
    if (href.includes(path)) {
      isGuest = true;
      break;
    }
  }

  return isGuest;
}

function validateTo(to) {
  return to.toString().toLowerCase().trim();
}
