import api from "@/services/api";

import {
  GET_TARIFFS,
  SET_TARIFFS,
  GET_TARIFF_TYPES,
  SAVE_TARIFF_TYPES,
  SET_LOADING,
} from "./types";

export const tariffs = {
  namespaced: true,

  state: {
    types: [],
    tariffs: [],
    loading: {
      value: false,
      id: "tariffs",
    },
  },

  getters: {
    loading: (state) => state.loading,
    tariffs: (state) => state.tariffs,
    types: (state) => state.types,
  },

  mutations: {
    [SET_TARIFFS](state, data) {
      state.tariffs = data;
    },

    [SAVE_TARIFF_TYPES](state, types) {
      state.types = types;
    },

    [SET_LOADING](state, loading) {
      const { id = "tariffs", value = true } = loading;
      state.loading.value = value;
      state.loading.id = id;
    },
  },

  actions: {
    async [GET_TARIFFS]({ commit }, id) {
      commit(SET_LOADING, { id: "tariffs", value: true });

      try {
        const { data } = await api.get("/admin/tariff/" + id);
        commit(SET_TARIFFS, data);
        commit(SET_LOADING, { id: "tariffs", value: false });
      } catch (e) {
        commit(SET_LOADING, { id: "tariffs", value: false });
        throw e;
      }
    },

    async [GET_TARIFF_TYPES]({ state, commit }) {
      try {
        if (state.types.length) return;
        const { data } = await api.get("/admin/tariff-types");
        commit(SAVE_TARIFF_TYPES, data);
      } catch (e) {}
    },
  },
};
