/* eslint-disable */
const routes =[
    {
        path:'/virtual-number',
        name:'virtual-num',
        redirect:'/virtual-number/dashboard'
    },
    {
        path:'/virtual-number/dashboard',
        name:'virtual-dashboard',
        component:() => import('@/modules/virtualnum/pages/Home'),
        meta:{
            requiresAuth : true,
            verified:  true
        },
        children:[
        ]
    },
    {
        path:'/virtual-number/coverage',
        name:'virtual-coverage',
        component:() => import('@/modules/virtualnum/pages/Home'),
        meta:{
            requiresAuth : true,
            verified:  true
        },
    },
    {
        path:'/virtual-number/user_dids',
        name:'virtual-user-dids',
        component:() => import('@/modules/virtualnum/pages/Home'),
        meta:{
            requiresAuth : true,
            verified:  true
        },
    },
    {
        path:'/virtual-number/trunks',
        name:'virtual-trunks',
        component:() => import('@/modules/virtualnum/pages/Home'),
        meta:{
            requiresAuth : true,
            verified:  true
        },
        children:[]
    },
    {
        path:'/virtual-number/capacity_pools',
        name:'virtual-capacity-pools',
        component:() => import('@/modules/virtualnum/pages/Home'),
        meta:{
            requiresAuth : true,
            verified:  true
        },
    },
    {
        path:'/virtual-number/call_logs',
        name:'virtual-call-logs',
        component:() => import('@/modules/virtualnum/pages/Home'),
        meta:{
            requiresAuth : true,
            verified:  true
        },
    },
    {
        path:'/virtual-number/sms_logs',
        name:'virtual-sms-logs',
        component:() => import('@/modules/virtualnum/pages/Home'),
        meta:{
            requiresAuth : true,
            verified:  true
        },
    }
]
export default routes
