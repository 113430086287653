
<template>
  <section class="w-full h-full">
    <PageHeader :heading="$t('xpbx.pages.heading.users')" :showButton="false" />
    <div class="card relative table-wrapper contact-wrapper">
      <DataTable
        ref="dt"
        :value="mappedUsers"
        selectionMode="single"
        dataKey="id"
        :paginator="true"
        :rows="10"
        scrollable
        removableSort
        sortMode="multiple"
        :scrollHeight="`${scrollHeight}px`"
        v-model:selection="selectedRecords"
        v-model:filters="filters"
        filterDisplay="menu"
        :rowClass="rowClass"
        paginatorPosition="top"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[5, 10, 25]"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
        v-if="mappedUsers && mappedUsers.length"
        @rowSelect="onRowSelect"
        @rowUnselect="onRowUnselect"
        :globalFilterFields="['username', 'description']"
      >
        <template #header>
          <div class="flex gap-2 align-items-center justify-between">
            <div>
              <Button
                :label="$t('xpbx.button.new')"
                icon="pi pi-plus"
                severity="success"
                class="mr-2 add-record-button"
                @click="openNew"
              />
              <Button
                :label="$t('xpbx.button.delete')"
                class="delete-button"
                icon="pi pi-trash"
                severity="danger"
                @click="deleteSelectedRecords"
                :disabled="!selectedUser?.id"
              />
            </div>
            <div class="flex gap-2 items-center">
              <IconField iconPosition="left" class="table-search">
                <InputIcon>
                  <i class="pi pi-search" />
                </InputIcon>
                <InputText
                  v-model="filters['global'].value"
                  :placeholder="$t('xpbx.placeholders.search')"
                />
              </IconField>
              <Export
                :dt="dt"
                :tableData="mappedExportableUsers"
                :columns="userHeaders"
              />
            </div>
          </div>
        </template>
        <Column
          field="username"
          :header="$t('xpbx.table-field.user')"
          sortable
          style="width: 20%"
        >
          <template #body="{ data }">
            {{ data?.username }}
          </template>
        </Column>
        <Column
          field="description"
          :header="$t('xpbx.table-field.description')"
          sortable
          style="width: 15%"
        >
          <template #body="{ data }">
            {{ data?.description }}
          </template>
        </Column>
        <Column
          field="extension.extension"
          sortable
          :header="$t('xpbx.table-field.extension')"
          style="width: 15%"
        >
          <template #body="slotProps">
            {{ slotProps?.data?.extension?.extension }}
          </template>
        </Column>
        <Column
          field="timezone"
          sortable
          :header="$t('xpbx.table-field.timezone')"
          style="width: 15%"
        >
          <template #body="{ data }">
            {{ data?.timezone }}
          </template>
        </Column>
        <Column
          field="created_at"
          filterField="created_at"
          dataType="date"
          sortable
          :header="$t('xpbx.table-field.created_at')"
          style="width: 10%"
        >
          <template #body="{ data }">
            <DateItem :value="data?.created_at" />
          </template>
        </Column>
        <Column
          field="updated_at"
          :header="$t('xpbx.table-field.updated_at')"
          sortable
          style="width: 10%"
        >
          <template #body="slotProps">
            <DateItem :value="slotProps?.data?.updated_at" />
          </template>
        </Column>
        <Column
          field="id"
          v-if="showLogin"
          :header="$t('xpbx.table-field.settings')"
          style="width: 10%"
        >
          <template #body="slotProps">
            <div class="flex items-center justify-between">
              <div
                @click.prevent="loginUser(slotProps?.data?.id)"
                class="w-12 h-11 flex items-center flex-col justify-center shadow-md hover:opacity-90 color-base-green rounded-md p-1"
              >
                <i class="fa-solid fa-right-to-bracket"></i>
                <span class="text-sm">{{ $t("xpbx.button.login") }}</span>
              </div>
              <div
                @click.prevent="logoutUser"
                v-if="
                  impersonateUser && impersonateUser === slotProps?.data?.id
                "
                class="w-12 h-11 flex-col flex items-center justify-center shadow-md hover:opacity-90 color-base-red rounded-md"
              >
                <i class="fa-solid fa-arrow-up-left-from-circle"></i>
                <span class="text-sm">{{ $t("xpbx.button.logout") }}</span>
              </div>
            </div>
            <!-- <Button
              :label="$t('xpbx.button.login')"
              icon="pi pi-plus"
              severity="success"
              class="mr-2 add-record-button"
            /> -->
          </template>
        </Column>
      </DataTable>
      <Loader v-else />
    </div>

    <Dialog
      v-model:visible="deleteUsersDialog"
      :style="{ width: '450px' }"
      header="Confirm"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="selectedUser?.username">{{
          $t("notify.xpbx.message.confirm_delete", {
            delete: `user ${selectedUser?.username}`,
          })
        }}</span>
      </div>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          text
          @click="deleteUsersDialog = false"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          text
          @click="deleteSelecteUsers"
        />
      </template>
    </Dialog>

    <!-- Create user -->
    <Dialog
      v-model:visible="userDialog"
      :style="{ width: '450px' }"
      :header="
        isEdit
          ? $t('xpbx.pages.heading.edit_user')
          : $t('xpbx.pages.heading.create_user')
      "
      :modal="true"
      :dismissableMask="true"
      class="p-fluid relative custom-dialog-heading"
    >
      <!-- Username -->
      <div class="field mb-1">
        <label for="name">{{ $t("xpbx.labels.username") }}</label>
        <InputText
          id="name"
          v-model.trim="user.username"
          required="true"
          autofocus
          :class="{ 'p-invalid': submitted && !user.username }"
        />
        <small class="p-error" v-if="submitted && !!errors?.username?.[0]">{{
          errors?.username?.[0]
        }}</small>
      </div>
      <QueueHint :title="$t('xpbx.hints.users.username')" />

      <!-- Description -->
      <div class="field mb-1">
        <label for="description">{{ $t("xpbx.labels.description") }}</label>
        <Textarea
          id="description"
          v-model="user.description"
          required="true"
          rows="3"
          cols="20"
        />
        <small class="p-error" v-if="submitted && !!errors?.description?.[0]">{{
          errors?.description?.[0]
        }}</small>
      </div>
      <QueueHint :title="$t('xpbx.hints.users.description')" />

      <!-- Password -->
      <div class="field mb-1">
        <label for="password">{{ $t("xpbx.labels.password") }}</label>
        <InputText
          id="password"
          type="password"
          v-model.trim="user.password"
          required="true"
          autofocus
          :class="{ 'p-invalid': submitted && !user.password }"
        />
        <small class="p-error" v-if="submitted && !!errors?.password?.[0]">{{
          errors?.password?.[0]
        }}</small>
      </div>
      <QueueHint :title="$t('xpbx.hints.users.password')" />

      <!-- Extension -->
      <div class="field mb-1">
        <label for="password">{{ $t("xpbx.labels.extension") }}</label>
        <form-select
          v-model="user.extension"
          :options="extensionOptions"
          name="channel"
          id="channel"
          :activeClass="true"
        />
      </div>
      <QueueHint :title="$t('xpbx.hints.users.extension')" />

      <!-- Timezones -->
      <div class="field mb-1">
        <label for="channel">{{ $t("xpbx.labels.timezones") }}</label>
        <form-select
          v-model="user.timezone"
          :options="timezones"
          name="channel"
          id="channel"
          :activeClass="true"
        />
      </div>
      <QueueHint :title="$t('xpbx.hints.users.timezones')" />

      <!-- Is active -->
      <div class="field mb-1" v-if="isEdit">
        <label for="is_active">{{ $t("xpbx.labels.is_active") }}</label>
        <form-select
          v-model="user.is_active"
          :options="activeOptions"
          name="is_active"
          id="is_active"
          :activeClass="true"
        />
        <QueueHint :title="$t('xpbx.hints.users.is_active')" />

      </div>
      <template #footer>
        <Button label="Cancel" icon="pi pi-times" text @click="hideDialog" />
        <Button label="Save" icon="pi pi-check" text @click="create" />
      </template>
    </Dialog>
  </section>
</template>

<script>
import { useStore } from "vuex";
import { ref, onMounted, computed, watch } from "vue";
import PageHeader from "@/modules/xpbx/components/home/UI/PageHeader.vue";
import TableSettings from "@/modules/xpbx/components/UI/table-settings";
import DeleteModal from "@/modules/xpbx/components/UI/delete-modal";
import useUsers from "@/modules/xpbx/composables/useUsers";
import Loader from "@/modules/xpbx/components/UI/loader/index.vue";
import useExtensions from "@/modules/xpbx/composables/useExtensions";
import DateItem from "@/modules/xpbx/components/UI/date-item/index.vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import validator from "@/composables/auth/validator";
import { validateUser } from "@/composables/auth/userValidations";
// import useTableFilters from "@/modules/xpbx/composables/useTableFilters";
// DataTable
import { FilterMatchMode } from "primevue/api";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import Textarea from "primevue/textarea";
import Checkbox from "primevue/checkbox";
import Calendar from "primevue/calendar";
import Export from "@/modules/xpbx/pages/settings/components/export/index.vue";
import { userHeaders } from "@/modules/xpbx/pages/settings/components/table-headers/data.js";
import QueueHint from "@/modules/xpbx/pages/settings/queue-detail/components/queue-hint/index.vue";

export default {
  name: "UsersTable",
  components: {
    PageHeader,
    TableSettings,
    DeleteModal,
    Loader,
    DateItem,
    DataTable,
    Column,
    Button,
    Textarea,
    InputText,
    Dialog,
    IconField,
    InputIcon,
    Export,
    Checkbox,
    Calendar,
    QueueHint,
  },

  props: {
    showLogin: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const dt = ref();
    const totalRecords = ref(0);
    const store = useStore();
    const isEdit = ref(false);
    const submitted = ref(false);
    const scrollHeight = ref(window.innerHeight - 290);
    const showDeleteModal = ref(false);
    const userDialog = ref(false);
    const deleteProductDialog = ref(false);
    const selectAll = ref(false);
    const showExports = ref(false);
    const deleteUsersDialog = ref(false);
    const selectedRecords = ref([]);
    const selectedUser = ref(null);
    const { errors } = validator();
    const timezones = computed(() => store.getters.timezoneOptions);
    const impersonateUser = computed(
      () => store.state.profilestore.impersonateUser
    );
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const { findFreeExtension, freeExtensions } = useExtensions();
    const {
      findUsers,
      deleteUser,
      createUser,
      updateUser,
      userImpersonate,
      logout,
      users,
      user,
    } = useUsers();
    const { findExtensions, extensions } = useExtensions();

    const extensionOptions = computed(() => {
      const defaultVal = [
        {
          id: "0",
          extension: "Select extension",
        },
      ];
      const extenstionArray = freeExtensions.value
        ? [...defaultVal, ...freeExtensions.value]
        : [];

      return extenstionArray.map((item) => {
        return {
          value: item.extension,
          name: item.extension,
        };
      });
    });

    const mappedUsers = computed(() => {
      return users.value.map((item) => {
        return {
          ...item,
          extension: findExtension(item.extension_id),
        };
      });
    });

    const mappedExportableUsers = computed(() => {
      return mappedUsers.value.map((item) => {
        return {
          ...item,
          extension: item?.extension?.extension || "",
        };
      });
    });

    const findExtension = (value) => {
      const extensionData = extensions.value.find((item) => item.id === value);
      return extensionData ? extensionData : null;
    };

    const rowClass = (data) => {
      return [
        {
          "table-active": data.is_active === 1,
          "table-inactive": data.is_active === 0,
          "table-selected": isEdit.value && user?.value?.id === data?.id,
        },
      ];
    };

    const fectchUsers = async () => {
      await findExtensions();
      await findUsers();
    };

    const resetUser = () => {
      user.value = {
        username: "",
        description: "",
        password: "",
        extension: "Select extension",
        timezone: "Europe/Sofia",
      };
      selectedUser.value = null;
      isEdit.value = false;
    };

    // Data Table Methods
    const openNew = () => {
      resetUser();
      submitted.value = false;
      userDialog.value = true;
    };

    const hideDialog = () => {
      submitted.value = false;
      userDialog.value = false;
      resetUser();
    };

    const onRowSelect = (event) => {
      isEdit.value = false;
      selectedUser.value = event.data;
    };

    const onRowUnselect = (event) => {
      if (selectedUser.value?.id === event.data.id) {
        const existedExtension = extensions.value.find(
          (item) => item.id === selectedUser.value.extension_id
        );

        const userData = {
          ...selectedUser.value,
        };

        if (existedExtension) {
          freeExtensions.value.push({
            id: existedExtension.id,
            extension: existedExtension.extension,
            description: existedExtension.description,
          });

          userData.extension = existedExtension.extension;
        }

        user.value = userData;

        isEdit.value = true;
        userDialog.value = true;
      }
    };

    const closeDialog = (value) => {
      submitted.value = false;
      userDialog.value = value;
      resetUser();
    };

    const deleteSelectedRecords = () => {
      deleteUsersDialog.value = true;
    };

    const create = async () => {
      if (isEdit.value) {
        await update();
      } else {
        await createCustomer();
      }
    };

    const createCustomer = async () => {
      submitted.value = true;
      const isValid = validateUser(user.value);
      const extensionId = freeExtensions.value.find(
        (item) => item.extension === user.value.extension
      )?.id;

      try {
        if (isValid) {
          await createUser(user.value, extensionId);
          isEdit.value = false;
          submitted.value = false;
          userDialog.value = false;
        }
      } catch (error) {
        console.log("error", error);
      }
    };

    const update = async () => {
      const extensionId = freeExtensions.value.find(
        (item) => item.extension === user.value.extension
      )?.id;

      try {
        await updateUser(user.value, user.value.id, extensionId);
        isEdit.value = false;
        submitted.value = false;
        userDialog.value = false;
      } catch (error) {
        console.log("error", error);
      }
    };

    const deleteSelecteUsers = async () => {
      await deleteUser(selectedUser.value.id);
      deleteUsersDialog.value = false;
    };

    watch(userDialog, (value) => {
      if (!value) resetUser();
    });

    // End Filters sort page methods

    const loginUser = async (id) => {
      const response = await userImpersonate(id);
      store.commit("profilestore/setImpersonate", response);
      if (response === true) {
        store.commit("profilestore/setImpersonateUser", id);
        localStorage.setItem("impersonateUser", id);
      } else {
        store.commit("profilestore/setImpersonateUser", null);
        localStorage.removeItem("impersonateUser");
      }
    };

    const logoutUser = async () => {
      await logout();
      store.commit("profilestore/setImpersonate", false);
      localStorage.removeItem("impersonateUser");
      store.commit("profilestore/setImpersonateUser", null);
    };

    const checkImpersonateUser = () => {
      const userId = localStorage.getItem("impersonateUser");

      if (userId) store.commit("profilestore/setImpersonateUser", userId);
    };

    const checkTableClick = () => {
      const appContainer = document.querySelector(".app-container");

      appContainer.addEventListener("click", (e) => {
        const classLength = e?.target?.classList?.length;
        const includeButton = e?.target?.classList?.contains("p-button-label");

        if (classLength > 0 && selectedUser?.value?.id && !includeButton) {
          selectedUser.value = null;
        }
      });
    };

    const activeOptions = [
      { name: "active", value: 1 },
      { name: "inactive", value: 0 },
    ];

    onMounted(async () => {
      checkTableClick();
      checkImpersonateUser();
      await fectchUsers();
      await findFreeExtension();
    });

    return {
      dt,
      user,
      users,
      totalRecords,
      mappedUsers,
      mappedExportableUsers,
      activeOptions,
      isEdit,
      errors,
      rowClass,
      submitted,
      timezones,
      selectAll,
      extensions,
      scrollHeight,
      findExtension,
      showDeleteModal,
      selectedRecords,
      createUser,
      userDialog,
      impersonateUser,
      userImpersonate,
      extensionOptions,
      deleteProductDialog,
      showExports,
      deleteUsersDialog,
      selectedUser,
      filters,
      userHeaders,
      findUsers,
      hideDialog,
      deleteUser,
      fectchUsers,
      create,
      openNew,
      logout,
      loginUser,
      logoutUser,
      onRowSelect,
      onRowUnselect,
      closeDialog,
      deleteSelectedRecords,
      deleteSelecteUsers,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/data-table.scss";
.color-base-green {
  background-color: #32bcad;
}

.color-base-red {
  background-color: #f05a94;
}
</style>