import { computed, unref } from 'vue'
import validator from '@/composables/auth/validator'
import i18nSetup from '@/services/i18nSetup'

const { i18n } = i18nSetup()
const translate = i18n.global.t

const hasErrors = computed(() => Object.entries(errors.value).length)
const { 
    checkRequired, 
    checkValueNotIn, 
    checkEmail, 
    checkIsNumber, 
    errors, 
    clearErrors,
    setErrors 
} = validator()

export function paymentValidations(params) {

    checkRequired('card_number', params)
    checkRequired('card_month', params)
    checkIsNumber('card_month', params)
    checkRequired('card_year', params)
    checkIsNumber('card_year', params)
    checkRequired('card_cvc_code', params)
    checkRequired('card_owner', params)

    if(params.card_month > 12 || params.card_month < 1) {
        setErrors({ key: 'card_month', message: translate('validation.modules.widget.validate.enter_valid_key', { key: 'Card month' }) })
    }

    if(params.card_number?.toString()?.length < 16) {
        setErrors({ key: 'card_number', message: translate('validation.modules.widget.validate.enter_valid_key', { key: 'Card number' }) })
    }

    if(params.card_year?.toString()?.length < 4 || params.card_year?.toString()?.length > 4) {
        setErrors({ key: 'card_year', message: translate('validation.modules.widget.validate.enter_valid_key', { key: 'Card year' }) })
    }

    if(params.card_year < 2022) {
        setErrors({ key: 'card_year', message: translate('validation.modules.widget.validate.enter_valid_key', { key: 'Card year' }) })
    }

    if(params.card_year > +new Date().getFullYear() + 15) {
        setErrors({ key: 'card_year', message: translate('validation.modules.widget.validate.enter_valid_key', { key: 'Card year' }) })
    }

    return hasErrors.value ? false : true

}

export function paymentUpdateValidations(params) {
    checkRequired('card_month', params)
    checkIsNumber('card_month', params)
    checkRequired('card_year', params)
    checkIsNumber('card_year', params)

    if(params.card_month > 12 || params.card_month < 1) {
        setErrors({ key: 'card_month', message: translate('validation.modules.widget.validate.enter_valid_key', { key: 'Card month' }) })
    }

    if(params.card_number.length < 16) {
        setErrors({ key: 'card_number', message: translate('validation.modules.widget.validate.enter_valid_key', { key: 'Card number' }) })
    }

    if(params.card_year.length < 4 || params.card_year.length > 4) {
        setErrors({ key: 'card_year', message: translate('validation.modules.widget.validate.enter_valid_key', { key: 'Card year' }) })
    }

    if(params.card_year < 2022) {
        setErrors({ key: 'card_year', message: translate('validation.modules.widget.validate.enter_valid_key', { key: 'Card year' }) })
    }

    if(params.card_year > +new Date().getFullYear() + 15) {
        setErrors({ key: 'card_year', message: translate('validation.modules.widget.validate.enter_valid_key', { key: 'Card year' }) })
    }

    return hasErrors.value ? false : true
}