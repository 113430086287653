<template>
  <div class="text-left mb-4 text-sm opacity-70">{{ title }}</div>
</template>

<script>
export default {
  name: "QueueHint",

  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>