import { reactive } from "vue";

const activeDropdown = reactive({
  id: "",
  status: false,
});

const activeConfig = reactive({
  id: "",
  status: false,
});

export default function useActiveToggler() {
  const toggleConfig = (configId, resource = "config") => {
    resource = resource === "config" ? activeConfig : activeDropdown;
    configId = configId.toString();
    if (configId.toLowerCase() == resource.id.toLowerCase()) {
      resource.status = !resource.status;
      return;
    }
    resource.id = configId;
    resource.status = true;
  };

  const toggleDropdown = (id) => {
    id = id.toString();
    if (id.toLowerCase() == activeDropdown.id.toLowerCase()) {
      activeDropdown.status = !activeDropdown.status;
      return;
    }
    activeDropdown.id = id;
    activeDropdown.status = true;
  };

  const isActiveConfig = (id) => {
    id = id.toString();
    return (
      id.toLowerCase().trim() === activeConfig.id.toLowerCase().trim() &&
      activeConfig.status === true
    );
  };

  const isActiveDropdown = (id) => {
    id = id.toString();
    return (
      id.toLowerCase() === activeDropdown.id.toLowerCase() &&
      activeDropdown.status === true
    );
  };

  return {
    toggleConfig,
    toggleDropdown,
    isActiveConfig,
    activeConfig,
    activeDropdown,
    isActiveDropdown,
  };
}
