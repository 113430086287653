<template>
  <transition-group tag="div" class="toast-wrap" name="list" appear>
    <div class="toaster" :class="toaster.type" v-if="toaster.open">
      <font-awesome-icon :icon="icon" class="fa-fw" />
      <span class="mx-3">{{ toaster.message }}</span>
      <font-awesome-icon
        icon="times"
        class="absolute right-1 top-1/2 hover:opacity-70 cursor-pointer"
        style="transform: translate(-50%, -50%)"
        @click="close"
      />
    </div>
  </transition-group>
</template>

<script>
/* eslint-disable */

import { computed, watch } from "vue";
import useToaster from "@/composables/useToaster";

export default {
  name: "Toaster",

  setup() {
    const { toaster, close } = useToaster();

    const icon = computed(() => {
      switch (toaster.type) {
        case "success":
          return "check";
        case "danger":
          return "exclamation-triangle";
        case "light":
          return "exclamation-circle";
        default:
          return "exclamation-circle";
      }
    });

    watch(
      () => toaster.open,
      (value) => {
        if (value == true) {
          setTimeout(() => {
            close();
          }, 5000);
        }
      }
    );

    return {
      toaster,
      icon,
      close,
    };
  },
};
</script>

<style scoped lang="scss">
.toast-wrap {
  position: fixed;
  bottom: 4rem;
  right: 1rem;
  height: auto;
  max-height: 2.75rem;
  width: auto;
  min-width: 22rem;
  z-index: 10003;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.toaster {
  width: 100%;
  padding: 0.825rem 1.25rem;
  color: white;
  position: relative;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
  text-align: left;
}
.toaster.danger {
  @apply bg-red-800;
}
.toaster.success {
  @apply bg-dark-green;
}
.toaster.light {
  background: #fff;
  @apply text-light-gray;
}

.list-enter-from {
  opacity: 0;
  right: -70%;
}
.list-enter-to {
  opacity: 1;
  right: 1rem;
}
.list-enter-active {
  transition: all 0.2s ease-in-out;
}

.list-leave-from {
  opacity: 1;
  right: 1rem;
}
.list-leave-to {
  opacity: 0;
  right: -100%;
}
.list-leave-active {
  // transition: position 0.2s ease-in-out;
  position: absolute;
  width: 100%;
}
.list-move {
  transition: all 0.2s ease-in-out;
}
</style>
