<template>
    <div>
        <template v-if="flow1">
            <span class="flex items-center" :title="$t('link.app.common.settings')">
                <img src="@/assets/images/config-d.svg"  :alt="$t('link.app.header.alt-text.website-dialer')" class="w-14">
                <span class="ml-4">{{ $t('link.app.common.settings') }}</span>
            </span>
        </template>
        <template v-else-if="reportRoutes">
            <span class="flex items-center" :title="$t('link.app.common.analysis')">
                <img src="@/assets/images/config-d.svg"  :alt="$t('link.app.header.alt-text.website-dialer')" class="w-14">
                <span class="ml-4">{{ $t('link.app.common.analysis') }}</span>
            </span>
        </template>
        <template v-else-if="tariffRoutes">
            <span class="flex items-center" :title="$t('link.app.common.tariffs')">
                <img src="@/assets/images/config-d.svg"  :alt="$t('link.app.header.alt-text.website-dialer')" class="w-14">
                <span class="ml-4">{{ $t('link.app.common.tariffs') }}</span>
            </span>
        </template>
        <template v-else-if="profileRoutes">
            <span class="flex items-center" :title="$t('link.app.common.profile')">
                <span class="ml-4">{{ $t('link.app.common.profile') }}</span>
            </span>
        </template>
        <template v-else-if="vNumberRoutes">
            <span class="flex items-center" :title="$t('label.virtual-number.top-bar.title.virtual-number')">
                <img src="@/assets/images/config-n.svg" :alt="$t('link.app.header.alt-text.virtual-number')" class="w-14">
                {{ $t('label.virtual-number.top-bar.title.virtual-number') }}
            </span>
        </template>
        <template v-else-if="widgetHome">
            <span class="flex items-center" :title="$t('label.virtual-number.top-bar.title.virtual-number')">
                <img src="@/assets/images/config-d.svg" :alt="$t('link.app.header.alt-text.virtual-number')" class="w-14">
            </span>
        </template>
    </div>
</template>

<script setup>
    
    import { computed } from 'vue'
    import { useRoute } from 'vue-router'
    
    const route = useRoute()
    const flow1 = computed(() => {
        return [
            'contacts', 
            'contact-departments', 
            'sidebar', 
            'appearance', 
            'lang', 
            'installation',
            'security',
            'security-country',
            'security-blacklist'
        ].includes(route.name)
    })

    const profileRoutes = computed(() => {
        return [
            'BaseProfile',
            'ProfileInformation',
            'ProfilePayment',
            'ProfileServices',
            'ProfileSupport',
            'ProfileDashboard',
            'RechargeAccount'
        ].includes(route.name)
    })

    const reportRoutes = computed(() => {
        return ['report-stats', 'report-location', 'reports', 'calls', "videoCalls", "emails", "messages"].includes(route.name)
    })

    const tariffRoutes = computed(() => {
        return ['tariffs', "tariffs-type"].includes(route.name)
    })

    const vNumberRoutes = computed(() => {
        return [
            'virtual-num',
            'virtual-dashboard',
            'virtual-coverage',
            'virtual-user-dids',
            'virtual-trunks',
            'virtual-capacity-pools',
            'virtual-call-logs',
            'virtual-sms-logs'
        ].includes(route.name)
    })

    const widgetHome = computed(() => {
        return ['Home'].includes(route.name)
    })
</script>
