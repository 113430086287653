import { computed, ref } from "vue";
import api from "@/services/api";
import userApi from "@/services/userApi";
import router from "@/router";
import store from "@/store";
import jwt_decode from "jwt-decode";
import { CLIENT_RENEG_LIMIT } from "tls";

const auth = ref(null);
//const token = ref(null);

export default function authenticate() {
  const loading = ref(false);

  const isLoggedIn = () => {
    return !!auth.value; //&& !!token.value;
  };

  const checkAccessToken = (token) => {
    const jwt = jwt_decode(token);
    const claims = JSON.parse(jwt.claims);

    if (claims && claims.is_admin) {
      localStorage.setItem("adminUser", true);
    } else {
      localStorage.setItem("adminUser", false);
    }
  };

  const login = async function (user) {
    const response = await api.post("/admin/root-login", {
      ...user,
    });

    if (response.status === 200) {
      checkAccessToken(response.data.access_token);

      saveTokens(response.data);
      //TODO redirect to widget or profile
      getProfile(response.data.redirect);
    }
  };

  const userLogin = async function (user) {
    const response = await api.post("/admin/user-login", {
      ...user,
    });

    if (response.status === 200) {
      checkAccessToken(response.data.access_token);
      // saveTokens(response.data);
      saveUserTokens(response.data);
      store.commit("profilestore/setUserLoginType", "user");
      //TODO redirect to widget or profile
      getUserProfile(response.data.redirect);
    }
  };

  const getProfile = async function (redirect = null) {
    if (!localStorage.getItem("token") && !localStorage.getItem("userToken")) {
      resetAuth();
      return;
    }

    try {
      const response = await api.get("/admin/me");
      if (response) auth.value = response.data;

      if (redirect) router.push({ path: redirect });
      // if (redirect) router.push({ path: redirect, replace: true });
    } catch (e) {
      resetAuth();
      console.log(e);
    }
  };

  const getUserProfile = async function (redirect = null) {
    if (!localStorage.getItem("userToken")) {
      // if (sessionStorage.getItem("token") == null) {
      resetAuth();
      return;
    }

    try {
      const response = await userApi.get("/admin/me");
      console.log(response);
      if (response) auth.value = response.data;

      if (redirect) router.push({ path: redirect });
      // if (redirect) router.push({ path: redirect, replace: true });
    } catch (e) {
      resetAuth();
      console.log(e);
    }
  };

  // const attempt = async (apiToken) => {

  //   try {
  //     const { data } = await api.post("/admin/me");
  //     if (!data) resetAuth();
  //     else {
  //       auth.value = data;
  //       token.value = apiToken;
  //     }
  //   } catch (e) {
  //     resetAuth();
  //     throw e;
  //   }
  // };

  // const setToken = (metaToken) => {
  //   localStorage.setItem("token", metaToken);
  //   token.value = metaToken.access_token;
  //   // axios.defaults.headers.common["Authorization"] = `Bearer ${metaToken}`;
  //   return Promise.resolve();
  // };

  const resetAuth = () => {
    console.log("resetAuth");
    localStorage.removeItem("token");
    localStorage.removeItem("userToken");
    auth.value = null;
    return Promise.resolve();
  };

  const getAuth = computed(() => {
    return auth.value == null ? {} : auth.value;
  });

  const logout = async function () {
    // new Logout
    const response = await api.get("/admin/logout");
    if (response.status === 200) delTokens();
  };

  const registerUser = async function (user) {
    // New register route
    try {
      return await api.register("/admin/register", { ...user });
    } catch (error) {
      return { status: 500, error };
    }
  };

  const saveTokens = function (response) {
    if (response && response.access_token && response.refresh_token) {
      localStorage.setItem("token", response.refresh_token);
      sessionStorage.setItem("token", response.access_token);
      return Promise.resolve();
    }
  };

  const saveUserTokens = function (response) {
    if (response && response.access_token && response.refresh_token) {
      console.log(response.access_token, response.refresh_token);
      localStorage.setItem("userToken", response.refresh_token);
      sessionStorage.setItem("userToken", response.access_token);
      return Promise.resolve();
    }
  };

  const delTokens = function () {
    sessionStorage.removeItem("token");
    localStorage.removeItem("token");
    sessionStorage.removeItem("userToken");
    localStorage.removeItem("userToken");
    store.commit("profilestore/setImpersonateUser", null);
    localStorage.removeItem("impersonateUser");
  };

  return {
    isLoggedIn,
    login,
    userLogin,
    getAuth,
    loading,
    logout,
    getProfile,
    registerUser,
    resetAuth,
  };
}
