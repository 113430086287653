<template>
  <div
    class="fixed z-10 inset-0 overflow-y-auto transition-all ease-in duration-200"
    :class="[
      !isOpenModal('serviceInstruction')
        ? ['invisible', 'opacity-0', 'h-0']
        : '',
    ]"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
        @click.prevent="toggleModal"
      ></div>
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <div
        class="inline-block align-bottom bg-white dark:bg-gray-900 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full border-2 dark:border-gray-700"
      >
        <div class="flex flex-row flew-wrap w-full">
          <div class="flex-1 flex-col flex-wrap mt-5">
            <div
              class="logo-images flex flex-row justify-center items-center p-5 mt-6"
            >
              <img
                src="@/assets/images/online-icon.png"
                alt="Xlinx Logo"
                class="mr-2 align-top"
              />
              <img
                src="@/assets/images/prolope-icon.svg"
                alt="Xlinx Logo"
                class="mr-2 image2 mt-2 w-44 h-auto"
              />
              <img
                src="@/assets/images/group.svg"
                alt="Xlinx Logo group"
                class="mr-2 image3 align-baseline mt-2 w-20 h-auto"
              />
            </div>
            <div class="w-full">
              <img
                class="py-2 pl-2 mx-auto mt-8 mb-8"
                src="@/assets/images/profile_section/profile-services-vn.svg"
                alt=""
              />
            </div>
            <div class="w-full">
              <p
                class="py-3 mb-3 font-bold text-4xl text-center text-purple-300"
              >
                Virtual Number
              </p>
            </div>
          </div>
          <div class="flex-1 flex-col flex-wrap p-5 mt-5">
            <div class="flex flex-row-reverse -mt-4">
              <font-awesome-icon
                icon="times"
                class="cursor-pointer"
                @click.prevent="toggleModal"
              />
            </div>
            <div class="w-full p-1 pb-3">
              <p class="text-left font-bold text-2xl">Our Web App</p>
            </div>
            <div class="w-full p-1 pb-5">
              <p class="text-left text-sm">
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum."
              </p>
            </div>
            <div class="w-full p-1 pb-3">
              <p class="text-left font-bold text-2xl">Haw To Use</p>
            </div>
            <div class="w-full p-1 pb-2">
              <p class="text-left text-sm">
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum."
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useModal from "@/composables/useModal";
//import { watch } from "vue";
/* eslint-disable */
export default {
  name: "ServiceInstruction",

  setup() {
    const { toggleModal, isOpenModal } = useModal();
    const open = isOpenModal("serviceInstruction");
    // watch(isOpenModal('serviceInstruction'), () => {
    //     open = isOpenModal('serviceInstruction');
    // });
    return {
      toggleModal,
      isOpenModal,
      open,
    };
  },
};
</script>
