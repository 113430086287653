<template>
  <div
    class="fixed z-10 inset-0 overflow-y-auto transition-all ease-in duration-200"
    :class="[!open ? ['invisible', 'opacity-0', 'h-0'] : '']"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 bg-gray-800 bg-opacity-60 transition-opacity"
        aria-hidden="true"
        @click.prevent="toggleModal"
      ></div>

      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
      >
        &#8203;
      </span>

      <div
        class="inline-block align-bottom bg-white dark:bg-gray-900 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
        :style="{ width: '700px !important' }"
      >
        <header
          class="bg-dark-green text-white dark:text-off-white dark:bg-gray-900 py-3 px-8 tracking-wider flex justify-between items-center border-0 dark:border-b-2 dark:border-gray-700 font-inter font-light text-3xl"
        >
          <slot name="header">
          </slot>
          <span :title="$t('text.app.common.close')">
            <Icon
              name="times"
              class="cursor-pointer"
              @click.prevent="toggleModal"
              color="#fff"
            />
          </span>
        </header>

        <div
          class="modal-content bg-white dark:bg-gray-900 dark:text-off-white px-8 pt-5 pb-4"
          :style="{
            height: $attrs['content-height'] || 'auto',
            overflowY: 'auto'
          }"
        >
          <slot name="content">
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3
                class="text-lg leading-6 font-medium text-gray-900 dark:text-off-white"
                id="modal-title"
              ></h3>
              <div class="mt-2">
                <p class="text-sm text-gray-500 dark:text-gray-400"></p>
              </div>
            </div>
          </slot>
        </div>

        <div
          class="modal-footer bg-gray-50 dark:bg-gray-900 dark:text-off-white border-0 border-t-2 dark:border-gray-700 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse"
        >
          <slot name="footer">
            <button
              type="button"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-800 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm dark:bg-gray-800 dark:text-off-white dark:hover:bg-gray-900"
              @click.prevent="toggleModal"
            >
              Cancel
            </button>

            <button
              type="button"
              class="w-full inline-flex justify-center border border-transparent shadow-sm px-4 py-2 bg-dark-green text-base font-medium text-white dark:bg-gray-800 dark:text-off-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm dark:hover:bg-gray-900 dark:border-gray-800"
            >
              Save Changes
            </button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useKeydown from '@/composables/useKeydown'
export default {
  inheritAttrs: false,

  props: {
    open: {
      type: Boolean,
      default: false
    }
  },

  emits: ['toggle'],

  setup (_, { emit }) {
    const toggleModal = (close = false) => {
      emit('toggle', close)
    }

    useKeydown([{ key: 'Escape', fn: () => toggleModal() }])

    return {
      toggleModal
    }
  }
}
</script>

<style scoped>
.modal-content::-webkit-scrollbar {
  width: 5px;
}

.modal-content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid rgb(76, 87, 97);
}
</style>
