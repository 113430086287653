


import useToaster from '@/composables/useToaster'

export default function useVirtualConfig() {
    const { trigger } = useToaster()

    const triggerAlert = (e, message) => {
        if(e?.response?.status == 403) {
            if(!message) {
                message = e.response.data.message || translate('virtual_num.main.coverage.countries.errmsg');
            }
        }
        trigger({ message, type: 'danger' })
    }
    return{
        triggerAlert
    }
}
